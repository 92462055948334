<div class="card" *ngIf="card">
    <p>{{getCardType(card)}}</p>
    <div class="card-body">
        <ng-container *ngIf="card">
            <img [src]="card?.img_url">
            <div class="context">
              <h6>{{card.title}}</h6>
              <div class="text">
                {{card.text}}
              </div>
              <div class="button m-b-10" *ngIf="card.btn_type_1">
                <button type="button" class="btn btn-maintheme" style="width : 100%" (click)="click()">{{getBtnType(card, "btn_type_1", "label_1")}}</button>
              </div>
              <div class="button m-b-10" *ngIf="card.btn_type_2">
                <button type="button" class="btn btn-maintheme" style="width : 100%" (click)="click()">{{getBtnType(card, "btn_type_2", "label_2")}}</button>
              </div>
              <div class="button" *ngIf="card.btn_type_3">
                <button type="button" class="btn btn-maintheme" style="width : 100%" (click)="click()">{{getBtnType(card, "btn_type_3", "label_3")}}</button>
              </div>
            </div>
        </ng-container>
    </div>
</div>
