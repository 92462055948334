import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {
  
  @Output() onClick: EventEmitter<string> = new EventEmitter();
  
  @Input()
  title : string;

  constructor() { }

  ngOnInit(): void {
  }

}
