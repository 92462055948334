<div class="card">
    <div class="card-header">
        <h5>画像</h5>
    </div>
    <div class="card-body">
        <ng-container *ngIf="image_preview">
            <img [src]="image_preview">
            <p class="preview">プレビュー</p>
            <button type="button" class="btn btn-warning clear" (click)="clear()">クリア</button>
        </ng-container>
        <ng-container *ngIf="!image_preview && current_image">
            <img [src]="current_image?.url">
            <button type="button" class="btn btn-primary main" [hidden]="!editing || current_image.id == toy.images[0].id" (click)="change_main(current_image)">この画像をメインに変更</button>
            <button type="button" class="btn btn-danger delete" [hidden]="!editing || current_image.id == toy.images[0].id" (click)="delete()">削除</button>
        </ng-container>
        <ng-container *ngIf="!image_preview && !current_image">
            <img [src]="'/assets/images/noimage.png'">
        </ng-container>
    </div>
    <div class="card-footer">
        <div class="images">
            <div class="image" *ngFor="let image of toy?.images">
                <img [src]="image.url" (click)="current_image = image">
            </div>
        </div>
        <div class="input-group input-group-button" [hidden]="!editing">
            <input type="file" class="form-control" accept="image/png, image/jpeg" name="image_file" (change)="image_changed($event)" #image_file>
            <button class="btn btn-primary" (click)="upload(image_file)">アップロード</button>
            <p class="caption">※jpg/png画像（推奨サイズ960x640）、5MB以下、画像は自動で切り取りリサイズされます</p>
        </div>
    </div>
</div>
