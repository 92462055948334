import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'elapsed_days'
})
export class ElapsedDays implements PipeTransform {
  
  transform(date_value : any): any {
    let date = moment(date_value);
    if (!date.isValid()) {
      return "";
    }
    let days = moment().diff(date, "days");
    return days;
  }
}
