import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { UiModalComponent } from '../../theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppDataTableComponent } from '../../data-table/data-table.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-purchase-histories.list',
  templateUrl: './purchase-histories.list.component.html',
  styleUrls: ['./purchase-histories.list.component.scss']
})
export class PurchaseHistoriesListComponent implements OnInit {
  
  @ViewChild(AppDataTableComponent, {static: false}) historiesDataTable: AppDataTableComponent;

  enum_data = enum_data;
  shown = true;
  
  public rows = [];
  public changes = [];

  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
  }

  search(search_conditions) {
    Swal.fire("読込中");
    Swal.showLoading();
    let conditions = {};
    for (let key of Object.keys(search_conditions)) {
      console.log({[key]:search_conditions[key]});
      let param_name = key.replace(".", "_");
      conditions[param_name] = search_conditions[key];
    }
    this.api.purchase.histories(conditions).toPromise().then((histories) => {
      this.rows = histories;
      this.historiesDataTable.reload();
      this.shown = true;
      Swal.close();
    });
  }
  

}
