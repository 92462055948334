<ng-container *ngIf="shown">
  
  <data-table 
    id="refunds.list.refunds"
    title="返金一覧"
    [button]="'csvダウンロード'"
    (onClickButton)="download_modal.show()"
    [rows]="rows"
    [columns]="{
      'id' : { order : 'desc', hidden : true },
      'customer.name' : { name : 'ユーザー名' },
      'amount' : { name : '返金額', type : 'currency' },
      'leavedAt' : { name : '退会日', type : 'datetime', search : { type : 'date' } }
    }"
  >
  </data-table>
  
</ng-container>

<app-ui-modal #download_modal>
  <div class="app-modal-header">
    <h5 class="modal-title">CSVをダウンロード</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="download_modal.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">対象退会日（開始）</label>
      <div class="col-sm-8">
        <input class="form-control" type="date" name="leavedAt_min" [(ngModel)]="leavedAt_min">
      </div>
      <label class="col-sm-4 col-form-label">対象退会日（終了）</label>
      <div class="col-sm-8">
        <input class="form-control" type="date" name="leavedAt_max" [(ngModel)]="leavedAt_max">
      </div>
      <label class="col-sm-4 col-form-label">振り込み日</label>
      <div class="col-sm-8">
        <input class="form-control" type="date" name="sendAt" [(ngModel)]="sendAt">
      </div>
    </div>    
    <div class="row">
      <div class="col-sm-3"></div>
      <button class="col-sm-6 btn btn-primary btn-lg" type="button" (click)="downloadCsv()">ダウンロード</button>
    </div>
  </div>
</app-ui-modal>
