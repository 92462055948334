import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';

@Component({
  selector: 'app-backs.list',
  templateUrl: './backs.list.component.html',
  styleUrls: ['./backs.list.component.scss']
})
export class BacksListComponent implements OnInit {

  enum_data = enum_data;
  
  public rows = [];
  public staffs = [];

  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
    this.api.plan.list({ state : 'backing,lost', }).toPromise().then((data) => {
      this.rows = data;
      console.log({aaa:this.rows});
    });
    this.staffs = this.api.staffs;
  }

}
