import { Injectable, SimpleChange } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieStoreService {


  form_values = {};
  get_property(scope_name, name, default_value) {
    if (this.form_values[scope_name + name] === undefined) {
      try {
        this.form_values[scope_name + name] = JSON.parse(this.cookie.get(scope_name + name)) || default_value;
      } catch(e) {
        this.form_values[scope_name + name] = default_value
      }
    }
    return this.form_values[scope_name + name];
  }
  set_property(scope_name, name, value) {
    this.cookie.set(scope_name + name, JSON.stringify(value));
    let changes = { [name] : new SimpleChange(this.form_values[scope_name + name], value, false)};
    this.form_values[scope_name + name] = value;
    return changes;
  }
  
  constructor(public cookie : CookieService) { }
}
