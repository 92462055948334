import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { CustomerService } from './customer.service';
import { MessagingService } from './messaging.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
  ],
  providers: [
    ApiService,
    AuthService,
    CustomerService,
    MessagingService,
  ],
})
export class ServicesModule { }
