import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Customer } from '../types/customer.type';
import { Plan } from '../types/plan.type';
import { Toy } from '../types/toy.type';
import { Talk } from '../types/talk.type';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  list(conditions = {}) : Observable<Customer[]> {
    return this.http.get<Customer[]>('/api/customers/list', { params : conditions, });
  }

  public chats() : Observable<Customer[]> {
    return this.http.get<Customer[]>('/api/customers/chats');
  }

  public get(customer_id) : Observable<Customer> {
    return this.http.get<Customer>('/api/customers/' + customer_id);
  }

  public update(customer) : Observable<Customer> {
    return this.http.put<Customer>('/api/customers/' + customer.id, customer)
  }

  public plans(customer_id, status = []) : Observable<Plan[]> {
    return this.http.get<Plan[]>('/api/customers/' + customer_id + '/plans/list?status=' + status.join(","));
  }
  
  public toys(customer_id, status = [], conditions = {}) : Observable<Toy[]> {
    return this.http.get<Toy[]>('/api/customers/' + customer_id + '/toys/list?status=' + status.join(","), { params : conditions, });
  }
  
  public talks(customer_id) : Observable<Talk[]> {
    return this.http.get<Talk[]>('/api/customers/' + customer_id + '/talks/list');
  }

  public sendText(customer_id, text, sendAt) : Observable<Talk> {
    return this.http.post<Talk>('/api/customers/' + customer_id + '/talks/text', { text, sendAt, });
  }

  public sendImage(customer_id, image, sendAt) : Observable<Talk> {
    return this.http.post<Talk>('/api/customers/' + customer_id + '/talks/image', { image, sendAt, });
  }

  public sendPlan(customer_id, plan_id, type, sendAt) : Observable<Talk> {
    return this.http.post<Talk>('/api/customers/' + customer_id + '/talks/plans/' + plan_id + '/' + type, { sendAt, });
  }
  
  public sendInstantPurchase(customer_id, amount, message, sendAt) : Observable<Talk> {
    return this.http.post<Talk>('/api/customers/' + customer_id + '/talks/purchases', { amount, message, sendAt, });
  }
  
  public checkUnreadChat(customer_id) : Observable<string> {
    return this.http.put<string>('/api/customers/' + customer_id + '/check_unread', {});
  }

  public checkUnreplyChat(customer_id) : Observable<string> {
    return this.http.put<string>('/api/customers/' + customer_id + '/check_unreply', {});
  }

  public cancelTalk(customer_id, talk_id) : Observable<string> {
    return this.http.delete<string>('/api/customers/' + customer_id + '/talks/' + talk_id, {});
  }

}
