import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import {IvyCarouselModule} from 'angular-responsive-carousel';

import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
registerLocaleData(localeJa);

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { SelectModule } from 'ng-select';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlJa } from './shared/mat-paginator-intl-ja';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';

import { ServicesModule } from './services/services.module';
import { AdminGuard } from './admin.guard';
import { QuickSearchFilter } from './shared/quick-search-filter.pipe';
import { CustomSearchFilter } from './shared/custom-search-filter.pipe';
import { PropertySort } from './shared/property-sort.pipe';
import { ElapsedDays } from './shared/elapsed-days.pipe';
import { WideChar } from './shared/wide-char.pipe';

import { ClipboardModule } from 'ngx-clipboard';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppDataTableComponent } from './data-table/data-table.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ToyCardComponent } from './toy-card/toy-card.component';
import { PropertyRowComponent } from './property-row/property-row.component';
import { ToysListComponent } from './toys/toys.list/toys.list.component';
import { ToysAddComponent } from './toys/toys.add/toys.add.component';
import { ToysViewComponent } from './toys/toys.view/toys.view.component';
import { CustomersListComponent } from './customers/customers.list/customers.list.component';
import { CustomersViewComponent } from './customers/customers.view/customers.view.component';
import { TasksListComponent } from './tasks/tasks.list/tasks.list.component';
import { PurchasesListComponent } from './purchases/purchases.list/purchases.list.component';
import { CouponsListComponent } from './coupons/coupons.list/coupons.list.component';
import { ChatsListComponent } from './chats/chats.list/chats.list.component';
import { StaffsListComponent } from './staffs/staffs.list/staffs.list.component';
import { StaffsAddComponent } from './staffs/staffs.add/staffs.add.component';
import { StaffsViewComponent } from './staffs/staffs.view/staffs.view.component';
import { SettingsListComponent } from './settings/settings.list/settings.list.component';
import { SettingsSelfComponent } from './settings/settings.self/settings.self.component';
import { ToyImageComponent } from './toy-image/toy-image.component';
import { ChatComponent } from './chat/chat.component';
import { PlansListComponent } from './plans/plans.list/plans.list.component';
import { PlansViewComponent } from './plans/plans.view/plans.view.component';
import { BacksListComponent } from './backs/backs.list/backs.list.component';
import { BacksViewComponent } from './backs/backs.view/backs.view.component';
import { ShipsListComponent } from './ships/ships.list/ships.list.component';
import { ShipsSheetComponent } from './ships/ships.sheet/ships.sheet.component';
import { ShipsInvoiceComponent } from './ships/ships.invoice/ships.invoice.component';
import { PurchaseHistoriesListComponent } from './purchase-histories/purchase-histories.list/purchase-histories.list.component';
import { RefundsListComponent } from './refunds/refunds.list/refunds.list.component';
import { ChargesListComponent } from './charges/charges.list/charges.list.component';
import { CardsListComponent } from './cards/cards.list/cards.list.component';
import { CardsViewComponent } from './cards/cards.view/cards.view.component';
import {CardTypeComponent} from './card-type/card-type.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    DashboardComponent,
    AppDataTableComponent,
    SearchBoxComponent,
    ToyCardComponent,
    PropertyRowComponent,
    ToysListComponent,
    ToysAddComponent,
    ToysViewComponent,
    CustomersListComponent,
    CustomersViewComponent,
    TasksListComponent,
    PurchasesListComponent,
    CouponsListComponent,
    ChatsListComponent,
    StaffsListComponent,
    StaffsAddComponent,
    StaffsViewComponent,
    SettingsListComponent,
    SettingsSelfComponent,
    QuickSearchFilter,
    CustomSearchFilter,
    PropertySort,
    ElapsedDays,
    WideChar,
    ToyImageComponent,
    ChatComponent,
    PlansViewComponent,
    PlansListComponent,
    BacksListComponent,
    BacksViewComponent,
    ShipsListComponent,
    ShipsSheetComponent,
    ShipsInvoiceComponent,
    PurchaseHistoriesListComponent,
    RefundsListComponent,
    ChargesListComponent,
    CardsListComponent,
    CardsViewComponent,
    CardTypeComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SelectModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    HttpClientModule,
    ServicesModule,
    MatPaginatorModule,
    IvyCarouselModule,
    DataTablesModule.forRoot(),
    ClipboardModule,
  ],
  providers: [
    NavigationItem,
    AdminGuard,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlJa, },
    CookieService,
    { provide: LOCALE_ID, useValue: 'ja-JP'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
