<ng-container *ngIf="toy || true">
  <div class="row">
    <div class="col-sm-5">
      <toy-image [toy]="toy" [editing]="true"></toy-image>
    </div>
    <div class="col-sm-7">
      <div class="edit-buttons">
        <button type="button" class="btn btn-primary" [hidden]="editing" (click)="edit_start()">編集</button>
        <button type="button" class="btn btn-secondary" [hidden]="!editing" (click)="edit_cancel()">キャンセル</button>
        <button type="button" class="btn btn-primary" [hidden]="!editing" (click)="edit_save()">保存</button>
      </div>
      <div class="table-responsive">
        <table class="table">
          <tr property-row *ngFor="let row of property_rows"
            [id]="row.id"
            [name]="row.name"
            [(ngModel)]="properties[row.id]"
            [type]="row.type"
            [editing]="editing"
            [options]="row.options"
            [placeholder]="row.placeholder"
            [readonly]="row.readonly"
            [suffix]="row.suffix"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  
  <data-table
    id="toys.view.plans"
    title="レンタル先"
    [rows]="plans"
    [columns]="{
      'state' : { name : '状態', type : 'select', data : enum_data.plan_toy_status },
      'customer.name' : { name : '名前' },
      'customer.detail.*' : { name : '子供', customCell : childCell },
      'customer.detail.shipping_address1' : { name : '住所' },
      'customer.detail.shipping_address2' : { name : '住所（番地以降）' },
      'start_date' : { name : 'レンタル日', type : 'date' },
      'customer.next_order_at' : { name : '次回交換申請可能日' , type : 'date' },
      'action' : { name : 'アクション', customCell : actionCell }
    }">
  
  </data-table>

  <data-table 
    id="toys.view.reviews"
    title="レンタル履歴"
    [rows]="reviews"
    [columns]="{
      'customer.detail.*' : { name : '子供', customCell : noNameChildCell },
      'plan.state' : { name : '最終ステータス', type : 'select', data : enum_data.plan_toy_status },
      'star' : { name : '評価' },
      'comment' : { name : 'レビュー内容' },
      'plan.start_date' : { name : 'レンタル日', type : 'date' },
      'action' : { name : 'アクション', customCell : reviewActionCell }
    }">
  </data-table>

  <data-table 
    id="toys.view.changes"
    title="変更履歴"
    [rows]="changes"
    [columns]="{
      'staff.name' : { name : '担当者' },
      'field' : { name : '項目', type : 'select', data : enum_data.toy_change_fields },
      'before_value' : { name : '変更前', customCell : changeValueCell },
      'after_value' : { name : '変更後', customCell : changeValueCell },
      'createdAt' : { name : '変更日時', type : 'datetime' }
    }">
  </data-table>
</ng-container>

<ng-template #childCell let-row="row" let-value="value">
  <div class="child" *ngIf="value.child_1">
    <span class="name">{{value.child_1.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_1.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_1.birth)}}</span>
    ）
  </div>
  <div class="child" *ngIf="value.child_2">
    <span class="name">{{value.child_2.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_2.gender">{{enum_data.genderByValue(value.child_2.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_2.birth)}}</span>
    ）
  </div>
  <div class="child" *ngIf="value.child_3">
    <span class="name">{{value.child_3.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_3.gender">{{enum_data.genderByValue(value.child_3.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_3.birth)}}</span>
    ）
  </div>
  <div class="child" *ngIf="value.child_4">
    <span class="name">{{value.child_4.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_4.gender">{{enum_data.genderByValue(value.child_4.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_4.birth)}}</span>
    ）
  </div>
  <div class="child" *ngIf="value.child_5">
    <span class="name">{{value.child_5.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_5.gender">{{enum_data.genderByValue(value.child_5.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_5.birth)}}</span>
    ）
  </div>
</ng-template>
<ng-template #noNameChildCell let-row="row" let-value="value">
  <div class="child" *ngIf="value.child_1">
    <span class="gender" [ngClass]="'gender_' + value.child_1.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_1.birth)}}</span>
  </div>
  <div class="child" *ngIf="value.child_2">
    <span class="gender" [ngClass]="'gender_' + value.child_2.gender">{{enum_data.genderByValue(value.child_2.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_2.birth)}}</span>
  </div>
  <div class="child" *ngIf="value.child_3">
    <span class="gender" [ngClass]="'gender_' + value.child_3.gender">{{enum_data.genderByValue(value.child_3.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_3.birth)}}</span>
  </div>
  <div class="child" *ngIf="value.child_4">
    <span class="gender" [ngClass]="'gender_' + value.child_4.gender">{{enum_data.genderByValue(value.child_4.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_4.birth)}}</span>
  </div>
  <div class="child" *ngIf="value.child_5">
    <span class="gender" [ngClass]="'gender_' + value.child_5.gender">{{enum_data.genderByValue(value.child_5.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_5.birth)}}</span>
  </div>
</ng-template>
<ng-template #actionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/customers/'+row.customer.id">開く</a>
</ng-template>
<ng-template #reviewActionCell let-row="row" let-value="value">
  <div class="btn btn-primary review-toggle" (click)="toggleReviewVisible(row, false)" *ngIf="row.visible">表示中</div>
  <div class="btn btn-primary review-toggle" (click)="toggleReviewVisible(row, true)" *ngIf="!row.visible">非表示中</div>
  <a class="btn btn-primary" [routerLink]="'/customers/'+row.customer.id">開く</a>
</ng-template>
<ng-template #changeValueCell let-row="row" let-value="value">
  <span class="changed-value">{{getChangedValue(row, value)}}</span>
</ng-template>
