<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:">
        <i class="icon fa fa-tasks"></i>
        <span class="badge badge-pill badge-danger"[hidden]="!new_tasks_count">{{new_tasks_count}}</span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">タスク</h6>
        </div>
        <ul class="noti-body">
          <li class="n-title" [hidden]="!new_tasks?.length">
            <h6 class="m-b-0 new-task">新規（未確認）</h6>
          </li>
          <li class="notification" *ngFor="let task of new_tasks" [routerLink]="task.link">
            <div class="media">
              <div class="media-body">
                <p><strong>{{task.customer.name}}</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>{{moment(task.createdAt).fromNow()}}</span></p>
                <p>{{enum_data.taskTypeByValue(task.type)?.name}}</p>
              </div>
            </div>
          </li>
          <li class="n-title" [hidden]="!checked_tasks?.length">
            <h6 class="m-b-0 checked">要対応</h6>
          </li>
          <li class="notification" *ngFor="let task of checked_tasks" [routerLink]="task.link">
            <div class="media">
              <div class="media-body">
                <p><strong>{{task.customer.name}}</strong><span class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>{{moment(task.createdAt).fromNow()}}</span></p>
                <p>{{enum_data.taskTypeByValue(task.type)?.name}}</p>
              </div>
            </div>
          </li>
          <li class="n-title" [hidden]="new_tasks?.length || checked_tasks?.length">
            <p class="m-b-0">対応が必要なタスクはありません</p>
          </li>
        </ul>
        <div class="noti-footer">
          <a [routerLink]="['/tasks']">すべて見る</a>
        </div>
      </div>
    </div>
  </li>
  <li>
    <div class="drp-user dropdown" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle>
        <i class="feather icon-user"></i>
      </a>
      <div class=" dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <!--<img src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image">-->
          <span>{{me?.name}}</span>
          <a (click)="logout()" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item" (click)="openUserPage()"><i class="feather icon-user"></i> ユーザー設定</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>
