<ng-container *ngIf="settings || true">
  <div class="row setting-top">
    <div class="col-sm-12">
      <div class="edit-buttons">
        <button type="button" class="btn btn-primary" [hidden]="editing" (click)="edit_start()">編集</button>
        <button type="button" class="btn btn-secondary" [hidden]="!editing" (click)="edit_cancel()">キャンセル</button>
        <button type="button" class="btn btn-primary" [hidden]="!editing" (click)="edit_save()">保存</button>
      </div>
      <div class="table-responsive">
        <table class="table">
          <tr property-row *ngFor="let row of property_rows"
            [id]="row.id"
            [name]="row.name"
            [(ngModel)]="properties[row.id]"
            [type]="row.type"
            [editing]="editing"
            [options]="row.options"
            [placeholder]="row.placeholder"
            [suffix]="row.suffix"
            [prefix]="row.prefix"
          ></tr>
        </table>
      </div>
    </div>
  </div>

  <data-table 
    id="settings.list.settings"
    title="変更履歴"
    [rows]="changes"
    [columns]="{
      'staff.name' : { name : '担当者' },
      'setting_name' : { name : '項目', type : 'select', data : property_rows },
      'before_value' : { name : '変更前', customCell : changeValueCell },
      'after_value' : { name : '変更後', customCell : changeValueCell },
      'createdAt' : { name : '変更日時', type : 'datetime' }
    }">
  </data-table>
  
</ng-container>

<ng-template #changeValueCell let-row="row" let-value="value">
  <span class="changed-value">{{getChangedValue(row, value)}}</span>
</ng-template>
