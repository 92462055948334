import { Injectable, Output, EventEmitter, } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { forkJoin, of, from, } from 'rxjs';
import { AuthService } from './auth.service';
import { CustomerService } from './customer.service';
import { ToyService } from './toy.service';
import { PlanService } from './plan.service';
import { StaffService } from './staff.service';
import { TaskService } from './task.service';
import { CouponService } from './coupon.service';
import { SettingService } from './setting.service';
import { PurchaseService } from './purchase.service';
import { ImageService } from './image.service';
import { CardService } from './card.service';
import { User } from '../types/user.type';
import { Staff } from '../types/staff.type';
import * as enum_data from '../../../../api/lib/enum_data';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public staffs : Staff[];
  public setting_hash : any;
  public me : User;

  @Output() tasksChanged : EventEmitter<string> = new EventEmitter();

  constructor(
    public auth : AuthService,
    public customer : CustomerService,
    public toy : ToyService,
    public plan : PlanService,
    public staff : StaffService,
    public task : TaskService,
    public coupon : CouponService,
    public purchase : PurchaseService,
    public setting : SettingService,
    public image : ImageService,
    public card : CardService
  ) { }

  prepare() : Observable<any> {
    let obs = forkJoin([
      this.staff.list(),
      this.setting.list(),
      this.auth.getMe(),
      this.coupon.list(),
    ]).toPromise().then((results) => {
      this.staffs = results[0];

      this.setting_hash = results[1].reduce((hash, setting) => { hash[setting.name] = setting.value; return hash; }, {});
      enum_data.appendData("toy_genres", this.setting_hash.toy_genres);
      enum_data.appendData("toy_tags", this.setting_hash.toy_tags);

      let courses = [];
      for (let coupon of results[3]) {
        if (coupon.code) {
          continue;
        }
        courses.push({
          id : coupon.id,
          value : coupon.id,
          name : coupon.course_name,
        });
      }
      enum_data.appendData("course_products", courses);

      let coupons = [];
      for (let coupon of results[3]) {
        coupons.push({
          id : coupon.id,
          value : coupon.id,
          name : coupon.name,
        });
      }
      enum_data.appendData("coupons", coupons);

      this.me = results[2];
    });

    return from(obs);
  }
}
