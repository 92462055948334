import {Component, OnInit, OnDestroy,} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../../../services/api.service';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import * as enum_data from '../../../../../../../../api/lib/enum_data';


@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit, OnDestroy {
  
  public me;
  public new_tasks : any[];
  public checked_tasks : any[];
  public new_tasks_count : number;
  moment = moment;
  enum_data = enum_data;
  tasksChangedSubscription;

  constructor(private api : ApiService, private router : Router) { }

  ngOnInit() {
    this.api.auth.getMe().toPromise().then((me) => {
      this.me = me;
    });
    this.checkTasks();
    setInterval(() => {
      this.checkTasks();
    }, 30000);
    this.tasksChangedSubscription = this.api.tasksChanged.subscribe(() => {
      this.checkTasks();
    });
  }
  
  ngOnDestroy() {
    this.tasksChangedSubscription.unsubscribe();
  }
  
  checkTasks() {
    this.api.task.mine().toPromise().then((tasks) => {
      tasks.sort((a, b) => {
        return b.id - a.id;
      })
      let new_tasks = tasks.filter(task => task.state == "new");
      let checked_tasks = tasks.filter(task => task.state == "checked");
      this.new_tasks_count = new_tasks.length;
      
      let append_link = (task : any) => {
        switch (task.type) {
          case "shipping":
            task.link = ["/ships",];
            break;

          case "backing":
            task.link = ["/backs", task.plan_id];
            break;

          case "planning":
          case "partless":
          case "toy_buy":
            task.link = ["/plans", task.plan_id];
            break;
            
          case "new_user":
          case "talk":
          case "change_address":
          case "change_course":
          case "charge_billed":
          case "purchase_failed":
          case "leave":
            task.link = ["/customers", task.customer_id];
            break;
        }

        return task;
      };
      this.new_tasks = new_tasks.slice(0, 4).map(append_link);
      this.checked_tasks = checked_tasks.slice(0, 4).map(append_link);
    });
  }
  
  openUserPage() {
    this.router.navigate(["/staffs/edit", this.me.id,]);
  }

  logout() {
    this.api.auth.logout().toPromise().then(() => {
      document.location.href = "/auth/signin";
    });
  }
}
