import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ships.list',
  templateUrl: './ships.list.component.html',
  styleUrls: ['./ships.list.component.scss']
})
export class ShipsListComponent implements OnInit {

  enum_data = enum_data;

  public rows = [];
  public staffs = [];
  hide_csv_button

  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
    this.api.plan.list({ state : "shipping", }).toPromise().then((data) => {
      this.rows = data;
      console.log({aaa:this.rows});

      return this.api.task.check_type("shipping").toPromise().then((result) => {
        this.api.tasksChanged.emit();
        return result;
      });
    });
    this.staffs = this.api.staffs;
    this.hide_csv_button = ["ship", "plan", "admin"].indexOf(this.api.me.grant) == -1;
  }

  getSubmitElapsed(plan) {
    let days = moment().diff(moment(plan.submitedAt).startOf('day'), 'days');
    return `${days}日経過`;
  }

  getRequestElapsed(plan) {
    let days = moment().diff(moment(plan.request_date).startOf('day'), 'days');
    return `${days}日経過`;
  }

  print_shipping_seal(plan) {
    window.open(`/ships/${plan.id}/invoice`, '_blank');
  }

  open_plan_sheet(plan) {
    window.open(`/ships/${plan.id}/sheet`, '_blank');
  }

  submit(plan) {
    let today = moment().format("YYYY-MM-DD");
    Swal.fire({
      icon : "warning",
      title : "発送完了しますか",
      text : `発送完了にすると発送伝票印刷やプランシートの閲覧ができなくなります。必ず無事に発送が完了してから操作してください。お問合せ伝票番号を入力して発送完了するを押してください。`,
      html : '<div id="swal2-content" class="swal2-html-container" style="display: block;">発送完了にすると発送伝票印刷やプランシートの閲覧ができなくなります。必ず無事に発送が完了してから操作してください。お問合せ伝票番号を入力して発送完了するを押してください。</div>' +
        '<input id="ship_number" type="text" class="swal2-input">' +
        `<div class="form-group row"><label class="col-sm-4 col-form-label" style="margin: auto;">発送日</label>
        <div class="col-sm-8">
          <input id="start_date" type="date" class="swal2-input" value="${today}">
        </div></div>` +
        '<label><input id="line" type="checkbox" checked>LINEメッセージを送信</label><br />',
      confirmButtonText : "発送完了する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      let ship_number = $("#ship_number").val();
      let start_date = $("#start_date").val();
      let line = $("#line").is(":checked");

      Swal.fire("発送完了中");
      Swal.showLoading();

      this.api.plan.ship(plan.id, ship_number, start_date, line).subscribe((result) => {
        Swal.fire("発送完了が完了しました", "", "success");
        this.ngOnInit();
      }, (error) => {
        Swal.fire("エラー", "発送完了に失敗しました", "error")
      })
    });

  }

  search(search_conditions) {
    Swal.fire("読込中");
    Swal.showLoading();
    let conditions = {
      state : "shipping"
    };
    for (let key of Object.keys(search_conditions)) {
      console.log({[key]:search_conditions[key]});
      let param_name = key.replace(".", "_");
      conditions[param_name] = search_conditions[key];
    }

    this.api.plan.list(conditions).toPromise().then((data) => {
      this.rows = data;
      console.log({aaa:this.rows});

      return this.api.task.check_type("shipping").toPromise().then((result) => {
        this.api.tasksChanged.emit();
        return result;
      });
    });
    Swal.close();
  }

  downloadCsv() {
    window.open(`/api/customers/csv`, '_blank');
  }

}
