<data-table 
  id="staffs.list.staffs"
  title="スタッフ一覧"
  [rows]="rows"
  [button]="'追加'"
  (onClickButton)="add_staff_modal.show()"
  [columns]="{
    'name' : { name : '名前' },
    'grant' : { name : '権限', type : 'select', data : enum_data.staff_grants, search : { type : 'select', options : enum_data.staff_grants } },
    'email' : { name : 'メールアドレス', type : 'email' },
    'action' : { name : 'アクション', customCell : actionCell }
  }"
>
</data-table>

<ng-template #actionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/staffs/'+row.id">開く</a>
</ng-template>

<app-ui-modal #add_staff_modal>
  <div class="app-modal-header">
    <h5 class="modal-title">スタッフを追加する</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="add_staff_modal.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="table-responsive">
      <table class="table">
        <tr property-row *ngFor="let row of property_rows"
          [id]="row.id"
          [name]="row.name"
          [(ngModel)]="new_staff[row.id]"
          [type]="row.type"
          [editing]="true"
          [options]="row.options"
          [placeholder]="row.placeholder"
          [suffix]="row.suffix"
          [prefix]="row.prefix"
        ></tr>
      </table>
    </div>
    <div class="row">
      <div class="col-sm-3"></div>
      <button class="col-sm-6 btn btn-primary btn-lg" type="button" (click)="add()">追加</button>
    </div>
  </div>
</app-ui-modal>
