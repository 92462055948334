import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  need_grants? : string[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'メニュー',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'dashboard',
        title: 'ホーム',
        type: 'item',
        url: '/dashboard',
        classes: 'nav-item',
        icon: 'fa fa-home'
      },
      {
        id: 'toys',
        title: 'おもちゃ',
        type: 'item',
        url: '/toys',
        classes: 'nav-item',
        icon: 'fa fa-rocket',
        need_grants : [ "admin", "plan", ],
      },
      {
        id: 'customers',
        title: 'ユーザー',
        type: 'item',
        url: '/customers',
        classes: 'nav-item',
        icon: 'fa fa-users',
        need_grants : [ "admin", "plan", "office", ],
      },
      {
        id: 'plans',
        title: 'プラン',
        type: 'item',
        url: '/plans',
        classes: 'nav-item',
        icon: 'fa fa-archive',
        need_grants : [ "admin", "plan", ],
      },
      {
        id: 'backs',
        title: '返却',
        type: 'item',
        url: '/backs',
        classes: 'nav-item',
        icon: 'fa fa-archive',
        need_grants : [ "admin", "back", ],
      },
      {
        id: 'ships',
        title: '発送',
        type: 'item',
        url: '/ships',
        classes: 'nav-item',
        icon: 'fa fa-archive',
        need_grants : [ "admin", "ship", ],
      },
      {
        id: 'tasks',
        title: 'タスク',
        type: 'item',
        url: '/tasks',
        classes: 'nav-item',
        icon: 'fa fa-tasks',
        need_grants : [ "admin", ],
      },
      {
        id: 'purchase-histories',
        title: '支払履歴',
        type: 'item',
        url: '/purchase-histories',
        classes: 'nav-item',
        icon: 'fa fa-credit-card',
        need_grants : [ "admin", "office", ],
      },
      {
        id: 'refunds',
        title: '返金',
        type: 'item',
        url: '/refunds',
        classes: 'nav-item',
        icon: 'fa fa-credit-card',
        need_grants : [ "admin", "office", ],
      },
      {
        id: 'charges',
        title: 'スポット課金',
        type: 'item',
        url: '/charges',
        classes: 'nav-item',
        icon: 'fa fa-credit-card',
        need_grants : [ "admin", "office", ],
      },
      {
        id: 'coupons',
        title: 'コース',
        type: 'item',
        url: '/coupons',
        classes: 'nav-item',
        icon: 'fa fa-percent',
        need_grants : [ "admin", "office", ],
      },
      {
        id: 'chats',
        title: 'チャット',
        type: 'item',
        url: '/chats',
        classes: 'nav-item',
        icon: 'fa fa-comments',
        need_grants : [ "admin", "office", "plan", ],
      },
      {
        id: 'staffs',
        title: 'スタッフ',
        type: 'item',
        url: '/staffs',
        classes: 'nav-item',
        icon: 'fa fa-suitcase',
        need_grants : [ "admin", ],
      },
      {
        id: 'settings_list',
        title: '設定',
        type: 'item',
        url: '/settings/list',
        classes: 'nav-item',
        icon: 'fa fa-cog',
        need_grants : [ "admin", ],
      },
      {
        id: 'settings_self',
        title: 'セルフコース設定',
        type: 'item',
        url: '/settings/self',
        classes: 'nav-item',
        icon: 'fa fa-cog',
        need_grants : [ "admin", ],
      },
      {
        id: 'cards',
        title: 'メルマガ',
        type: 'item',
        url: '/cards',
        classes: 'nav-item',
        icon: 'fa fa-address-card',
        need_grants : [ "admin", ],
      },
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
