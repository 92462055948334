import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'quick_search'
})
export class QuickSearchFilter implements PipeTransform {

  transform(array: any[], query: string): any {
    if (query) {
      return array.filter((item) => {
        return JSON.stringify(item).indexOf(query) != -1;
      });
    }
    return array;
  }
}
