<ng-container>

</ng-container>
<ng-container *ngFor="let card of enum_data.cards">
  <button class="btn btn-primary mb-4 mr-4" (click)="addCard(card.id)" *ngIf="card_status[card.id - 1]">{{card.name}}のカード作成</button>
</ng-container>

<data-table
  id="cards.list"
  title="メルマガ一覧"
  [rows]="rows"
  [columns]="{
    'id' : { name : 'タイプ', customCell : idCell, class: 'card_type' },
    'img_url' : { name : '画像', customCell : imageCell, class: 'card_img'},
    'text' : { name : '文章', class: 'card_text'},
    'action' : { name : 'アクション', customCell : actionCell, class: 'card_action' }
  }"
>
</data-table>

<ng-template #idCell let-row="row" let-value="value">
  <span>{{enum_data.cardByValue(row.card_type).name}}</span>
</ng-template>

<ng-template #imageCell let-row="row" let-value="value">
  <img [src]="value" class="main_image">
</ng-template>

<ng-template #actionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/cards/'+row.id">開く</a>
</ng-template>


