<ng-container *ngIf="shown">

  <data-table
    id="tasks.list.tasks"
    title="タスク一覧"
    [rows]="rows"
    [custom_search]="true"
    (onSearch)="search($event)"
    [columns]="{
      'id' : { order : 'desc', hidden : true },
      'staff.id' : { name : 'スタッフ', type : 'select', search : { type : 'select', options : staffs }, data : staffs, customCell : staffCell },
      'customer' : { name : '対象ユーザー', customCell : customerCell },
      '*' : { name : '関連データ', customCell : mainCell },
      'type' : { name : '種類', type : 'select', data : enum_data.task_types, search : { type : 'select', options : enum_data.task_types } },
      'state' : { name : '状態', type : 'select', data : enum_data.task_status, search : { type : 'select', options : enum_data.task_status } },
      'createdAt' : { name : '発生日時', type : 'datetime', search : { type : 'date' } },
      'action' : { name : 'アクション', customCell : actionCell },
      'delete' : { name : '削除', customCell : deleteCell }
    }"
  >
  </data-table>

</ng-container>

<ng-template #mainCell let-row="row" let-value="value">
  <div class="main">
    <span class="talk" [hidden]="!row.talk">
      <div class="last-talk chat-card">
        <ng-container *ngIf="row.talk" [ngSwitch]="row.talk.sender">
          <!-- ユーザーのメッセージ -->
          <div *ngSwitchCase="'customer'" class="row received-chat">
            <div class="col" [ngSwitch]="row.talk.message.type">
              <!-- 通常のテキストメッセージ -->
              <div class="msg" *ngSwitchCase="'text'">
                <p class="m-b-0">{{row.talk.message.text}}</p>
              </div>
              <!-- セルフコースの備考 -->
              <div class="msg" *ngSwitchCase="'self_other'">
                <p class="m-b-0">{{row.talk.message.text}}</p>
              </div>
              <div class="msg" *ngSwitchCase="'image'">
                画像を送信
              </div>
              <div class="mg" *ngSwitchDefault>
                <p class="m-b-0">{{row.talk.message | json}}</p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </span>
    <a class="plan link" [hidden]="!row.plan_id" [routerLink]="['/plans', row.plan_id]">申請</a>
  </div>
</ng-template>
<ng-template #staffCell let-row="row" let-value="value">
  <a class="link" [routerLink]="['/staffs', row.staff.id]">{{row.staff.name}}</a>
</ng-template>
<ng-template #customerCell let-row="row" let-value="value">
  <a class="link" [routerLink]="['/customers', value.id]">{{value.name || "名前未登録"}}</a>
</ng-template>
<ng-template #actionCell let-row="row" let-value="value">
    <button class="btn btn-primary" (click)="finishTask(row)">完了する</button>
</ng-template>
<ng-template #deleteCell let-row="row" let-value="value">
  <button class="btn btn-danger" (click)="deleteTask(row)">削除する</button>
</ng-template>
<ng-template #courseCell let-row="row" let-value="value">
  <span class="course-name">{{row.product_name || row.coupon?.name}}</span>
</ng-template>
