<ng-container *ngIf="plan">
  <div class="row">
    <div class="col-sm-5 chat">
      <chat [customer]="plan.customer" [plan]="plan"></chat>
    </div>
    <div class="col-sm-7">
      <div class="card">
        <div class="card-body">
          <ngb-tabset>
            <ngb-tab title="お客様情報・申請情報">
              <ng-template ngbTabContent>
                <div class="table-responsive">
                  <h5>お客様情報</h5>
                  <table class="table">
                    <tr property-row *ngFor="let row of customer_property_rows"
                      [id]="row.id"
                      [name]="row.name"
                      [(ngModel)]="row.detail ? plan.customer.detail[row.id] : plan.customer[row.id]"
                      [type]="row.type"
                      [editing]="editing"
                      [options]="row.options"
                      [placeholder]="row.placeholder"
                      [suffix]="row.suffix"
                      [prefix]="row.prefix"
                      [hidden]="!editing && !(row.detail ? plan.customer.detail[row.id] : plan.customer[row.id])"
                    ></tr>
                  </table>
                </div>
                <div class="table-responsive">
                  <h5>申請情報</h5>
                  <table class="table">
                    <tr property-row *ngFor="let row of plan_property_rows"
                      [id]="row.id"
                      [name]="row.name"
                      [(ngModel)]="row.detail ? plan.detail[row.id] : plan[row.id]"
                      [type]="row.type"
                      [editing]="row.edit"
                      [options]="row.options"
                      [placeholder]="row.placeholder"
                      [suffix]="row.suffix"
                      [prefix]="row.prefix"
                      [hidden]="!row.edit && !plan[row.id]"
                      (ngModelChange)="valueChanged(row, $event)"
                    ></tr>
                  </table>
                </div>
                <div class="edit-buttons">
                  <button type="button" class="btn btn-primary" [hidden]="memo_editing" (click)="edit_start()">編集</button>
                  <button type="button" class="btn btn-secondary" [hidden]="!memo_editing" (click)="edit_cancel()">キャンセル</button>
                  <button type="button" class="btn btn-primary" [hidden]="!memo_editing" (click)="edit_save()">保存</button>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <tr property-row *ngFor="let row of memo_property_rows"
                      [id]="row.id"
                      [name]="row.name"
                      [(ngModel)]="memo_properties[row.id]"
                      [type]="row.type"
                      [editing]="memo_editing"
                      [options]="row.options"
                      [placeholder]="row.placeholder"
                      [suffix]="row.suffix"
                    ></tr>
                  </table>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab title="レンタル履歴">
              <ng-template ngbTabContent>
                <ng-container *ngFor="let plan of history_plans">
                  <ng-container *ngIf="plan.show">
                    <h5 *ngIf="plan.start_date">{{plan.start_date | date : "y年M月d日"}}〜</h5>
                    <h5 *ngIf="!plan.start_date">申請待ち・提案中・発送中</h5>
                    <div class="row history_plan">
                      <ng-container *ngFor="let toy of plan.toys">
                        <toy-card class="col-sm-2 toy" [toy]="toy" [smart]="true"></toy-card>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <button class="col-sm-6 btn btn-outline-default" [hidden]="!is_more_history" (click)="add_more_history()">もっと表示する</button>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="plan?.state == 'shipping'">
    <div class="row title">
      <div class="col-sm-12">
        <h4>発送準備中</h4>
      </div>
    </div>
    
    <div class="row toys">
      <div class="col-lg-2 col-md-3 col-sm-4 col-6 toy" *ngFor="let toy of plan.toys">
        <toy-card [toy]="toy" [simple]="true" [buttons]="state_buttons_hash[toy.state] || []" [(ngModel)]="toy.selected" (click)="selectToy($event, toy)" (buttonClick)="toyButtonsClick($event, toy)"></toy-card>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="plan?.state != 'shipping'">
    <ng-container *ngIf="offer_toys?.length">
      <div class="row title">
        <div class="col-sm-12">
          <h4>提案中</h4>
        </div>
      </div>
      
      <div class="row plan_toys">
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 toy" *ngFor="let toy of offer_toys">
          <toy-card [toy]="toy" [simple]="true" [buttons]="state_buttons_hash[toy.state] || []" [(ngModel)]="toy.selected" (click)="selectToy($event, toy)" (buttonClick)="toyButtonsClick($event, toy)"></toy-card>
        </div>
      </div>
    </ng-container>
  
    <ng-container *ngIf="extending_toys?.length || unfix_toys?.length">
      <div class="row title">
        <div class="col-sm-12">
          <h4>提案候補</h4>
          <span class="prices">{{getPriceString(["fix", "extend", "unfix", "extending"])}}</span>
        </div>
      </div>
      
      <div class="row plan_toys">
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 toy" *ngFor="let toy of extending_toys">
          <toy-card [toy]="toy" [simple]="true" [buttons]="state_buttons_hash[toy.state] || []" [(ngModel)]="toy.selected" (click)="selectToy($event, toy)" (buttonClick)="toyButtonsClick($event, toy)"></toy-card>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 toy" *ngFor="let toy of unfix_toys">
          <toy-card [toy]="toy" [simple]="true" [buttons]="state_buttons_hash[toy.state] || []" [(ngModel)]="toy.selected" (click)="selectToy($event, toy)" (buttonClick)="toyButtonsClick($event, toy)"></toy-card>
        </div>
      </div>
    </ng-container>
  
    <ng-container *ngIf="extend_toys?.length || fix_toys?.length">
      <div class="row title">
        <div class="col-sm-12">
          <h4>確定済み</h4>
          <span class="prices">{{getPriceString(["fix", "extend"])}}</span>
        </div>
      </div>
      
      <div class="row plan_toys">
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 toy" *ngFor="let toy of extend_toys">
          <toy-card [toy]="toy" [simple]="true" [buttons]="state_buttons_hash[toy.state] || []" [(ngModel)]="toy.selected" (click)="selectToy($event, toy)" (buttonClick)="toyButtonsClick($event, toy)"></toy-card>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 toy" *ngFor="let toy of fix_toys">
          <toy-card [toy]="toy" [simple]="true" [buttons]="state_buttons_hash[toy.state] || []" [(ngModel)]="toy.selected" (click)="selectToy($event, toy)" (buttonClick)="toyButtonsClick($event, toy)"></toy-card>
        </div>
        <div class="card col-lg-2 col-md-3 col-sm-4 col-6 plan_action_buttons">
          <div class="card-body">
            <button class="btn btn-primary" type="button" (click)="submit_plan()">プラン確定する</button>
            <button class="btn btn-success" type="button" (click)="submit_priority_plan()">プラン確定する
            （優先）</button>
          </div>
        </div>
      </div>
    </ng-container>
  
    <ng-container *ngIf="back_toys?.length || rejected_toys?.length">
      <div class="row title">
        <div class="col-sm-12">
          <h4>却下</h4>
        </div>
      </div>
      
      <div class="row plan_toys">
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 toy" *ngFor="let toy of back_toys">
          <toy-card [toy]="toy" [simple]="true" [buttons]="state_buttons_hash[toy.state] || []" [(ngModel)]="toy.selected" (click)="selectToy($event, toy)" (buttonClick)="toyButtonsClick($event, toy)"></toy-card>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 toy" *ngFor="let toy of rejected_toys">
          <toy-card [toy]="toy" [simple]="true" [buttons]="state_buttons_hash[toy.state] || []" [(ngModel)]="toy.selected" (click)="selectToy($event, toy)" (buttonClick)="toyButtonsClick($event, toy)"></toy-card>
        </div>
      </div>
    </ng-container>
  
    <ng-container *ngIf="other_toys?.length">
      <div class="row title">
        <div class="col-sm-12">
          <h4>おもちゃ</h4>
        </div>
      </div>
      
      <div class="row plan_toys" *ngIf="plan.state == 'lost'">
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 toy" *ngFor="let toy of other_toys">
          <toy-card [toy]="toy" [simple]="true" [buttons]="state_buttons_hash[toy.state] || []" [(ngModel)]="toy.selected" (click)="selectToy($event, toy)" (buttonClick)="toyButtonsClick($event, toy)"></toy-card>
        </div>
        <div class="card col-lg-2 col-md-3 col-sm-4 col-6 plan_action_buttons">
          <div class="card-body">
            <button class="btn btn-success" type="button" (click)="change_to_backed()">返却済みにする</button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

</ng-container>

<ng-template #stateCell let-row="row" let-value="value">
  <p class="state">{{enum_data.planStateByValue(row.state).name}}</p>
  <p class="request-date">{{row.request_date | date : "y年M月d日" }}</p>
</ng-template>
<ng-template #toysCell let-row="row" let-value="value">
  <div class="toy-name" *ngFor="let toy of value">
    <span class="name">{{toy.name}}</span>
    <span class="name">{{enum_data.planToyStateByValue(toy.state).name}}</span>
  </div>
</ng-template>
<ng-template #nameCell let-row="row" let-value="value">
  <div class="name-cell">
    <p class="name" [hidden]="!value.name">{{value.name}}</p>
    <p class="name noname" [hidden]="value.name">名前無し</p>
    <div class="tags">
      <p class="genre"[hidden]="!value.detail.genre">{{value.detail.genre}}</p>
      <div *ngFor="let tag of value.detail.tags">
        <p class="tag">{{tag}}</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #actionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/plans/'+row.id">開く</a>
</ng-template>
<ng-template #reviewActionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/toys/'+row.toy.id">開く</a>
</ng-template>
<ng-template #changeValueCell let-row="row" let-value="value">
  <span class="changed-value">{{getChangedValue(row, value)}}</span>
</ng-template>
<ng-template #wrapCell let-row="row" let-value="value">
  <span class="wrap">{{value}}</span>
</ng-template>

<div class="row">
  <div class="col-sm-3"></div>
  <button class="col-sm-6 btn btn-primary btn-lg add-toy-button" type="button" (click)="addToy()" [disabled]="!get_is_selected_toys()">↑提案候補に移す↑</button>
</div>

<data-table
  id="plans.view.toys"
  class="toys"
  title="おもちゃ一覧"
  [rows]="toys"
  grid_view=1
  list_view=1
  grid_pagesize_default=30
  list_pagesize_default=25
  [default_age_min]="default_age_min"
  [default_age_max]="default_age_max"
  [tableExtendView]="extendView"
  [custom_search]="true"
  (onSearch)="searchToys($event)"
  [columns]="{
    'selected' : { name : '選択', customCell : selectCell },
    'identifier' : { name : 'ID' },
    'real_stock' : { name : '実在庫', datatype: 'html-num' },
    'virtual_stock' : { name : '仮想在庫', datatype: 'html-num' },
    'main_image' : { name : '画像', customCell : imageCell },
    'detail.genre' : { name : 'おもちゃ', customCell : toyNameCell, search : { type : 'select', options : enum_data.toy_genres } },
    'state' : { name : '履歴', type : 'select', data : enum_data.plan_toy_status },
    'maker' : { name : 'メーカー\n対象年齢', customCell : makerCell },
    'detail.price' : { name : '定価', customCell : priceCell },
    'review_average' : { name : '評価', customCell : reviewCell },
    'detail.target_age' : { name : '対象年齢', hidden : true, search : { type : 'age', options : enum_data.ages } },
    'action' : { name : 'アクション', customCell : toyActionCell }
  }"
  [sorts]="{'name' : '名前', 'detail.maker' : 'メーカー名', 'review_average' : '評価','detail.price' : '価格'}"
  [gridCell]="gridCell"
>
</data-table>
<ng-template #extendView>
  {{getSelectedPriceString()}}
</ng-template>
<ng-template #selectCell let-row="row" let-value="value">
  <div class="selection custom-checkbox custom-control">
    <input type="checkbox" [id]="'toy-check-'+row.id" [(ngModel)]="row.selected" class="custom-control-input" value="1">
    <label [for]="'toy-check-'+row.id" class="custom-control-label">
      <span class="click-dummy">dummy</span>
    </label>
  </div>
</ng-template>
<ng-template #stockCell let-row="row" let-value="value">
  {{row.real_stock}} / {{row.virtual_stock}}
</ng-template>
<ng-template #imageCell let-row="row" let-value="value">
  <img [src]="value.url" class="main_image">
</ng-template>
<ng-template #toyNameCell let-row="row" let-value="value">
  <p class="name" [hidden]="!row.name">{{row.name}}</p>
  <p class="name noname" [hidden]="row.name">名前無し</p>
  <div class="tags">
    <p class="genre"[hidden]="!row.detail.genre">{{row.detail.genre}}</p>
    <div *ngFor="let tag of row.detail.tags">
      <p class="tag">{{tag}}</p>
    </div>
  </div>
</ng-template>
<ng-template #makerCell let-row="row" let-value="value">
  <p class="maker">{{row.detail.maker}}</p>
  <span class="age" [hidden]="!row.detail.target_age">{{enum_data.getAgeString(row.detail.target_age?.min)}} 〜 {{enum_data.getAgeString(row.detail.target_age?.max)}}</span>
</ng-template>
<ng-template #priceCell let-row="row" let-value="value">
  <span class="price">
    {{value | currency:'JPY'}}
  </span>
</ng-template>
<ng-template #reviewCell let-row="row" let-value="value">
  <span class="review">{{value}}</span>
</ng-template>

<ng-template #toyActionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/toys/'+row.id">開く</a>
</ng-template>
<ng-template #gridCell let-row="row">
  <toy-card [toy]="row" [selectable]="true" [(ngModel)]="row.selected" (click)="selectToy($event, row)"></toy-card>
</ng-template>
