<!-- Set "A5", "A4" or "A3" for class name -->
<!-- Set also "landscape" if you need -->
<body class="A3">
  
  <span class="no-print caption">１ページ３枚表示されます。クリックすると消えて印刷されなくなります（※このテキストは印刷されません）</span>
  <button class="no-print print-button" onclick="window.print();">印刷する</button>

  <!-- Each invoice element should have the class "invoice" -->
  <!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
  <div class="invoice" *ngFor="let invoice of invoices" (click)="switchVisiblity(invoice)">
    <div [hidden]="!invoice.visible">
      <img class="back" src="/assets/images/ships/invoice/back.png" [hidden]="true">
      
      <div class="left">
        <p class="code">{{invoice.plan.trackingcode || "00-00-00"}}</p>
        <svg class="barcode-number"></svg>
        <p class="customer">
          <span class="zipcode">〒{{zipcode(invoice.customer.detail.shipping_zipcode)}}</span><br>
          {{invoice.customer.detail.shipping_address1}}<br>
          {{invoice.customer.detail.shipping_address2}}
          {{invoice.customer.detail.shipping_name}}様
        </p>
        <p class="company">
          <span class="zipcode">〒787-0021</span><br>
          高知県四万十市中村京町1-12-1　第一ビル5階東<br>
          And TOYBOX発送センター
        </p>
        <p class="number">{{invoice.plan.trackingnumber || "0000000000"}}</p>
      </div>

      <div class="right">
        <svg class="barcode-code"></svg>
        <svg class="barcode-number"></svg>
        <p class="customer">
          <span class="zipcode">〒{{zipcode(invoice.customer.detail.shipping_zipcode)}}</span><br>
          {{invoice.customer.detail.shipping_address1}}<br>
          {{invoice.customer.detail.shipping_address2}}
          {{invoice.customer.detail.shipping_name}}様
        </p>
        <p class="company">
          <span class="zipcode">〒787-0021</span><br>
          高知県四万十市中村京町1-12-1　第一ビル5階東<br>
          And TOYBOX発送センター
        </p>
        <p class="number">{{invoice.trackingnumber}}</p>
      </div>
    </div>
  </div>

</body>
