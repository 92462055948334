import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import * as enum_data from '../../../../api/lib/enum_data';
import { Toy } from '../types/toy.type';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  enum_data = enum_data;
  customers;
  
  rows = [];
  toy : Toy;
  toy2 : Toy;
  
  table_value = "test";
  editing = false;
  tag_value = ["1", "2",];
  date_value = "2021-01-22";
  child_value = {
    name : "竈門炭治郎",
    gender : "",
    birth : "2014-12-01",
  };
  child_value2 = {
    name : "竈門禰豆子",
    gender : "2",
    birth : "2020-12-01",
  };
  memo_value = [
    { date : "2021-02-01", text : "これは長いメモです\nかなり長めのメモです。自分で言うのもなんですがかなり中身のある長めのメモです", type : "normal", },
    { date : "2021-02-01", text : "これは長いメモです\nかなり長めのメモです。自分で言うのもなんですがかなり中身のある長めのメモです", type : "plan", },
    { date : "2021-02-01", text : "これは長いメモです\nかなり長めのメモです。自分で言うのもなんですがかなり中身のある長めのメモです", type : "ship", },
    { date : "2021-02-01", text : "これは長いメモです\nかなり長めのメモです。自分で言うのもなんですがかなり中身のある長めのメモです", type : "return", },
  ];
  text_value = "僕は歩く\nつれづれな日\n新しい夜\n僕は待っていた僕は歩く\nひとり見上げた月は悲しみです僕は歩く\nひとり淋しい人になりにけり僕は歩く\nひとり冷えた手の平を見たのです";
  target_age = { min : 6, max : 16};
  price_value = 1300;

  constructor(private api : ApiService) { }

  ngOnInit(): void {
    this.api.customer.list().toPromise().then((customers) => {
      this.customers = customers;
      this.rows = customers;
    })
    
    this.api.toy.get(1).toPromise().then((toy) => {
      this.toy = toy;
    })
    this.api.toy.get(2).toPromise().then((toy) => {
      this.toy2 = toy;
    })
  }
  
  public onClick() {
    console.log("click");
  }
  
  public onSelect() {
    console.log("select");
  }

}
