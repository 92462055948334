import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { UiModalComponent } from '../../theme/shared/components/modal/ui-modal/ui-modal.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-refunds.list',
  templateUrl: './refunds.list.component.html',
  styleUrls: ['./refunds.list.component.scss']
})
export class RefundsListComponent implements OnInit {
  
  enum_data = enum_data;
  shown = true;
  
  public rows = [];
  public changes = [];
  
  leavedAt_min;
  leavedAt_max;
  sendAt;

  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
    this.leavedAt_min = moment().add(-1, "month").startOf("month").format('YYYY-MM-DD');
    this.leavedAt_max = moment().add(-1, "month").endOf("month").format('YYYY-MM-DD');
    this.sendAt = moment().date(5).format('YYYY-MM-DD');
    forkJoin([
      this.api.purchase.refunds(),
    ]).subscribe((results) => {
      this.rows = results[0];
      this.shown = true;
    });
  }
  
  downloadCsv() {
    window.open(`/api/purchases/refund_csv?lmin=${this.leavedAt_min}&lmax=${this.leavedAt_max}&sa=${this.sendAt}`, '_blank');
  }
  
}
