<th>{{name}}</th>
<td>
  <div *ngIf="(!editing || readonly) && value !== undefined" [ngSwitch]="type" class="noediting">
    <span *ngSwitchCase="'date'">{{value | date : "y年M月d日" : "Asia/Tokyo" }}<span class="suffix">{{suffix}}</span></span>
    <span *ngSwitchCase="'select'">{{getSelectName(value)}}<span class="suffix">{{suffix}}</span></span>
    <span *ngSwitchCase="'multiselect'">
      <span class="tag" *ngFor="let item of value">{{getSelectName(item)}}</span>
    </span>
    <span *ngSwitchCase="'multistring'">
      <span class="tag" *ngFor="let item of value">{{item}}</span>
    </span>
    <span *ngSwitchCase="'tags'">
      <p class="tag_line" *ngFor="let item of value"><span class="fas">{{getTagIcon(item)}}</span>{{getTagName(item)}}</p>
    </span>
    <div *ngSwitchCase="'child'" class="child">
      <span class="name">{{value.name}}</span>
      （
      <span class="gender" [ngClass]="'gender_' + value.gender">{{enum_data.genderByValue(value.gender).name}}</span>
      ・
      <span class="age">{{getAge(value.birth)}}</span>
      ）
    </div>
    <div *ngSwitchCase="'age_range'">
      {{getAgeString(value.min)}} 〜 {{getAgeString(value.max)}}<span class="suffix">{{suffix}}</span>
    </div>
    <div *ngSwitchCase="'memos'" class="memos">
      <p *ngFor="let memo of value" class="memo" [ngClass]="memo.type">
        <span class="date">{{memo.date | date : "y年M月d日"}}</span>
        <span class="type">{{enum_data.memoTypeById(memo.type).name}}</span>
        <span class="text">{{memo.text}}</span>
      </p>
    </div>
    <span *ngSwitchCase="'object'">{{value | json }}<span class="suffix">{{suffix}}</span></span>
    <span *ngSwitchCase="'boolean'">{{value ? (true_value || "有効") : (false_value || "無効") }}<span class="suffix">{{suffix}}</span></span>
    <span *ngSwitchCase="'text'" class="text">{{value}}<span class="suffix">{{suffix}}</span></span>
    <span *ngSwitchCase="'zipcode'" class="text"><span class="prefix">{{prefix}}</span>{{getZipcodeString(value)}}</span>
    <span *ngSwitchCase="'tel'" class="text"><span class="prefix">{{prefix}}</span>{{getTelString(value)}}</span>
    <span *ngSwitchCase="'password'" class="text"><span class="prefix">{{prefix}}</span>********</span>
    <span *ngSwitchCase="'email'" class="text"><span class="prefix">{{prefix}}</span><a [href]="'mailto:' + value">{{value}}</a></span>
    <span *ngSwitchCase="'number'" class="number">{{(value || 0).toLocaleString()}}<span class="suffix">{{suffix}}</span></span>
    <span *ngSwitchCase="'datetime'" class="datetime">{{value | date : "y年M月d日 H時mm分"}}<span class="suffix">{{suffix}}</span></span>
    <span *ngSwitchCase="'link_url'" class="link-url">
      <span [hidden]="value">連携済み</span>
      <input class="form-control" type="text" readonly [hidden]="!value" [value]="value"/>
    </span>
    <span *ngSwitchCase="'btn_type'">{{getSelectName(value)}}<span class="suffix">{{suffix}}</span></span>
    <a *ngSwitchCase="'image'" [href]="value" target="_blank">
      <ng-container *ngIf="value">
          <img class="image" [src]="value">
      </ng-container>
      <ng-container *ngIf="!value">
          <img [src]="'/assets/images/noimage.png'">
      </ng-container>
    </a>
    <span *ngSwitchDefault>{{value}}<span class="suffix">{{suffix}}</span></span>
  </div>
  <div class="editing" *ngIf="editing && !readonly" [ngSwitch]="type">
    <input *ngSwitchCase="'string'" class="form-control" type="text" [(ngModel)]="value" [placeholder]="placeholder">
    <input *ngSwitchCase="'date'" class="form-control" type="date" [(ngModel)]="value" [placeholder]="placeholder">
    <input *ngSwitchCase="'tel'" class="form-control" type="tel" [(ngModel)]="value" [placeholder]="placeholder">
    <input *ngSwitchCase="'email'" class="form-control" type="email" [(ngModel)]="value" [placeholder]="placeholder">
    <textarea *ngSwitchCase="'text'" class="form-control" type="text" [(ngModel)]="value" [placeholder]="placeholder"></textarea>
    <ng-select *ngSwitchCase="'select'" [options]="select_options" [(ngModel)]="value" [placeholder]="placeholder" bindValue="value"></ng-select>
    <ng-container *ngSwitchCase="'btn_type'">
      <select class="form-control" [(ngModel)]="value" (change)="changeBtnType($event)">
        <option *ngFor="let item of select_options" [value]="item.value">{{item.label}}</option>
      </select>
    </ng-container>
    <ng-select *ngSwitchCase="'multiselect'" [options]="select_options" [(ngModel)]="value" [placeholder]="placeholder" [multiple]="1"></ng-select>
    <div *ngSwitchCase="'multistring'" class="multistring">
      <div class="string" *ngFor="let str of value; index as i;trackBy: trackBy">
        <div class="form-row">
          <input class="form-control col-11" type="text" [(ngModel)]="value[i]">
          <button type="button" class="form-control col-1 delete fa" (click)="delete_memo(i)"></button>
        </div>
      </div>
      <button class="btn btn-primary" (click)="add_string()">+ 追加</button>
    </div>
    <ng-container *ngSwitchCase="'password'">
      <input class="form-control" type="password" [(ngModel)]="value" (change)="change()" [placeholder]="placeholder">
    </ng-container>
    <ng-container *ngSwitchCase="'boolean'">
      <span *ngIf="value">有効</span>
      <span *ngIf="!value">無効</span>
      <button class="btn btn-primary btn-sm m-l-10" (click)="value = false" *ngIf="value">無効化</button>
      <button class="btn btn-primary btn-sm m-l-10" (click)="value = true" *ngIf="!value">有効化</button>
    </ng-container>
    <ng-container *ngSwitchCase="'tags'">
      <div class="selection custom-checkbox custom-control" *ngFor="let option of options">
        <input type="checkbox" id="customCheck_{{name}}_{{option.id}}" [(ngModel)]="checkbox_value[option.value]" class="custom-control-input" (change)="checkboxChange($event)">
        <label for="customCheck_{{name}}_{{option.id}}" class="custom-control-label"><span class="fas">{{option.icon}}</span>{{option.name}}</label>
      </div>
    </ng-container>
    <div *ngSwitchCase="'child'" class="child form-row">
      <ng-container *ngIf="value">
        <input class="form-control name col-sm-4" type="text" [(ngModel)]="value.name" [placeholder]="placeholder[0]">
        <select class="form-control col-sm-2 gender" [(ngModel)]="value.gender">
          <option value="" disabled>{{placeholder[1]}}</option>
          <option *ngFor="let item of enum_data.genders" [value]="item.value">{{item.name}}</option>
        </select>
        <input class="form-control birth col-sm-6" type="date" [(ngModel)]="value.birth">
      </ng-container>
      <ng-container *ngIf="!value">
        <button class="btn btn-primary btn-sm" (click)="add_child()">+ 追加</button>
      </ng-container>
    </div>
    <div *ngSwitchCase="'age_range'" class="age_range">
      <div class="min">
        <span>下限</span>
        <select class="form-control ages" [(ngModel)]="value.min">
          <option value="" disabled>年齢の下限</option>
          <option *ngFor="let item of enum_data.ages" [value]="item.value">{{item.name}}</option>
        </select>
      </div>
      <div class="max">
        <span>上限</span>
        <select class="form-control ages" [(ngModel)]="value.max">
          <option value="" disabled>年齢の上限</option>
          <option *ngFor="let item of enum_data.ages" [value]="item.value">{{item.name}}</option>
        </select>
      </div>
    </div>
    <span *ngSwitchCase="'object'">{{value | json }}</span>
    <div *ngSwitchCase="'number'" class="input-group number">
      <input class="form-control" type="number" [(ngModel)]="value" [placeholder]="placeholder">
      <div class="input-group-append" *ngIf="suffix">
        <span class="input-group-text">{{suffix}}</span>
      </div>
    </div>
    <div *ngSwitchCase="'zipcode'" class="input-group number">
      <div class="input-group-append" *ngIf="prefix">
        <span class="input-group-text">{{prefix}}</span>
      </div>
      <input class="form-control" type="number" [(ngModel)]="value" [placeholder]="placeholder" maxlength="7">
    </div>
    <div *ngSwitchCase="'memos'" class="memos">
      <div class="memo" *ngFor="let memo of value; index as i">
        <div class="form-row">
          <input class="form-control col-sm-5 date" type="date" [(ngModel)]="memo.date">
          <ng-select class="col-sm-6 type" [options]="enum_data.memo_types" [(ngModel)]="memo.type" [placeholder]="'メモ種別を選択'"></ng-select>
          <button type="button" class="form-control col-sm-1 delete fa" (click)="delete_memo(i)"></button>
        </div>
        <textarea class="form-control text" [(ngModel)]="memo.text">{{memo.text}}</textarea>
      </div>
      <button class="btn btn-primary" (click)="add_memo()">+ 追加</button>
    </div>
    <span *ngSwitchCase="'link_url'" class="link-url">
      <span [hidden]="value">連携済み</span>
      <div class="" [hidden]="!value">
        <input id="copy" class="form-control" type="text" readonly [value]="value"/>
        <input class="form-control btn btn-primary" type="button" value="コピー" ngxClipboard [cbContent]="value" (click)="copy()"/>
      </div>
    </span>
    <span *ngSwitchCase="'image'">
      <ng-container *ngIf="image_preview">
          <img class="image" [src]="image_preview">
          <p class="preview">プレビュー</p>
          <button type="button" class="btn btn-warning clear" (click)="clear()">クリア</button>
      </ng-container>
      <ng-container *ngIf="!image_preview && value">
          <img class="image" [src]="value">
      </ng-container>
      <input type="file" class="form-control" accept="image/png, image/jpeg" name="image_file" (change)="image_changed($event)" #image_file>
      <button class="btn btn-primary" (click)="upload(image_file)">アップロード</button>
      <p class="caption">※jpg/png画像、5MB以下、画像は自動で切り取りリサイズされます</p>
    </span>
  </div>
</td>
