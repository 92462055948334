import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Staff } from '../../types/staff.type';
import { StaffChange } from '../../types/staff-change.type';
import { Customer } from '../../types/customer.type';
import { Plan } from '../../types/plan.type';
import { Card } from "../../types/card.type";
import Swal from 'sweetalert2';
import _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-cards.view',
  templateUrl: './cards.view.component.html',
  styleUrls: ['./cards.view.component.scss']
})
export class CardsViewComponent implements OnInit {

  show : boolean = false;
  enum_data = enum_data;
  staff : Staff;
  card = null;
  preview_card = null;
  properties : any = {};
  editing : boolean = false;
  plans : Plan[] = [];
  customers : Customer[] = [];

  property_rows = [
    { id : 'img_url', name :"サムネイル画像", type : "image", placeholder : "サムネイル画像URLの入力", skip : true, },
    { id : 'title', name :"タイトル", type : "string", placeholder : "タイトルを入力", },
    { id : 'text', name :"説明文", type : "string", placeholder : "説明文を入力", },
    { id : 'btn_type_1', name :"ボタン１", type : "btn_type", placeholder : "選択", options : enum_data.card_btn_types, },
    { id : 'label_1', name :"ラベル１", type : "string", placeholder : "ラベルを入力", readonly: false},
    { id : 'action_url_1', name :"ボタンURL１", type : "string", placeholder : "ボタンURLのを入力", readonly: false},
    { id : 'btn_type_2', name :"ボタン２", type : "btn_type", placeholder : "選択", options : enum_data.card_btn_types, },
    { id : 'label_2', name :"ラベル２", type : "string", placeholder : "ラベルを入力", readonly: false},
    { id : 'action_url_2', name :"ボタンURL２", type : "string", placeholder : "ボタンURLのを入力", readonly: false},
    { id : 'btn_type_3', name :"ボタン３", type : "btn_type", placeholder : "選択", options : enum_data.card_btn_types, },
    { id : 'label_3', name :"ラベル３", type : "string", placeholder : "ラベルを入力", readonly: false},
    { id : 'action_url_3', name :"ボタンURL３", type : "string", placeholder : "ボタンURLのを入力", readonly: false},
  ];

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    forkJoin([
      this.api.card.get(id),
    ]).subscribe((result) => {
      console.log(result[0]);
      this.card = result[0];
      this.copy_properties();
      this.setReadOnly({ id : "btn_type_1", value : this.card.btn_type_1, });
      this.setReadOnly({ id : "btn_type_2", value : this.card.btn_type_2, });
      this.setReadOnly({ id : "btn_type_3", value : this.card.btn_type_3, });
    });
  }

  copy_properties() {
    this.properties = this.property_rows.reduce((data, row) => {
      data[row.id] = this.card[row.id];
      return data;
    }, {
      card_type : this.card.card_type,
    });
  }


  edit_start() {
    this.editing = true;
  }

  edit_cancel() {
    this.editing = false;
    this.preview_card = null;
    this.copy_properties();
  }

  edit_save() {
    let params = {...this.properties};
    Swal.fire("メルマガ情報保存中");
    Swal.showLoading();
    this.api.card.update(this.card.id, params).toPromise().then((data) => {
      this.card = data;
      this.editing = false;
      this.preview_card = null;
      Swal.fire("メルマガ情報を保存しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "メルマガ情報の保存に失敗しました", "error")
    });
  }

  preview() {
    this.preview_card = this.property_rows.reduce((data, row) => {
      data[row.id] = this.properties[row.id];
      return data;
    }, {
      card_type : this.properties.card_type,
    });
  }

  setReadOnly (params) {
    let type = params.value;
    let label_name, url_name;
    if (params.id == "btn_type_1") {
      label_name = "label_1";
      url_name = "action_url_1";
    }
    if (params.id == "btn_type_2") {
      label_name = "label_2";
      url_name = "action_url_2";
    }
    if (params.id == "btn_type_3") {
      label_name = "label_3";
      url_name = "action_url_3";
    }
    this.property_rows.map(item => {
      if (item.id === label_name || item.id === url_name) {
        if (Number(type) === 1) {
          item.readonly = false;
        } else {
          item.readonly = true;
          this.properties[label_name] = null;
          this.properties[url_name] = null;
        }
      }
    });
  }

}
