<data-table 
  id="toys.list.toys"
  title="おもちゃ一覧"
  [rows]="rows"
  grid_view=1
  list_view=1
  [button]="'追加'"
  (onClickButton)="clickAdd($event)"
  [custom_search]="true"
  (onSearch)="search($event)"
  [columns]="{
    'identifier' : { name : 'ID' },
    'real_stock' : { name : '実在庫', datatype: 'html-num' },
    'virtual_stock' : { name : '仮想在庫', datatype: 'html-num' },
    'self' : { name : 'セルフコース対象', type : 'select', data : enum_data.toy_selfs, search : { type : 'select', options : enum_data.toy_selfs } },
    'main_image' : { name : '画像', customCell : imageCell },
    'detail.genre' : { name : 'おもちゃ', customCell : nameCell, search : { type : 'select', options : enum_data.toy_genres } },
    'maker' : { name : 'メーカー', customCell : makerCell },
    'detail.target_age' : { name : '対象年齢', customCell : ageCell, search : { type : 'age', options : enum_data.ages } },
    'review_average' : { name : '評価', customCell : reviewCell },
    'action' : { name : 'アクション', customCell : actionCell }
  }"
  [sorts]="{'name' : '名前', 'detail.maker' : 'メーカー名', 'review_average' : '評価','detail.price' : '価格'}"
  [gridCell]="gridCell"
>
</data-table>
<ng-template #stockCell let-row="row" let-value="value">
  {{row.real_stock}} / {{row.virtual_stock}}
</ng-template>
<ng-template #imageCell let-row="row" let-value="value">
  <img [src]="value.url" class="main_image">
</ng-template>
<ng-template #nameCell let-row="row" let-value="value">
  <p class="name" [hidden]="!row.name">{{row.name}}</p>
  <p class="name noname" [hidden]="row.name">名前無し</p>
  <div class="tags">
    <p class="genre"[hidden]="!row.detail.genre">{{row.detail.genre}}</p>
    <div *ngFor="let tag of row.detail.tags">
      <p class="tag">{{tag}}</p>
    </div>
  </div>
</ng-template>
<ng-template #makerCell let-row="row" let-value="value">
  <p class="maker">{{row.detail.maker}}</p>
  <p class="country">{{row.detail.country}}</p>
</ng-template>
<ng-template #ageCell let-row="row" let-value="value">
  <span class="age" [hidden]="!row.detail.target_age">{{enum_data.getAgeString(row.detail.target_age?.min)}} 〜 {{enum_data.getAgeString(row.detail.target_age?.max)}}</span>
</ng-template>
<ng-template #reviewCell let-row="row" let-value="value">
  <span class="review">{{value}}</span>
</ng-template>

<ng-template #actionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/toys/'+row.id">開く</a>
</ng-template>
<ng-template #gridCell let-row="row">
  <toy-card [toy]="row" (click)="openToy($event, row)"></toy-card>
</ng-template>

