import {Pipe, PipeTransform} from '@angular/core';
import * as enum_data from '../../../../api/lib/enum_data';

@Pipe({
  name: 'custom_search'
})
export class CustomSearchFilter implements PipeTransform {
  
  getValueRaw(value, key, default_value = "") {
    let value_string = key.split(".").reduce((value, key) => {
      if (key == "*") {
        return value;
      }
      if (typeof value == "object") {
        return value[key] || default_value;
      } else {
        return default_value;
      }
    }, value);
    return value_string;
  }

  transform(array: any[], conditions: any): any {
    if (!array) {
      return array;
    }
    if (conditions) {
      return array.filter((item) => {
        for (let key of Object.keys(conditions)) {
          if (!conditions[key]) {
            continue;
          }
          if (key == 'child_name') {
            let json = item;
            let value;
            try {
              value = JSON.parse(json);
            } catch (e) {
              value = { detail : {} };
            }
            if ((value.detail.child_1.name||"").indexOf(conditions[key]) == -1
             && (value.detail.child_2.name||"").indexOf(conditions[key]) == -1
             && (value.detail.child_3.name||"").indexOf(conditions[key]) == -1
             && (value.detail.child_4.name||"").indexOf(conditions[key]) == -1
             && (value.detail.child_5.name||"").indexOf(conditions[key]) == -1
            ) {
               return false;
            }
          }
          else if (key == 'child_gender') {
            let json = item;
            let value;
            try {
              value = JSON.parse(json);
            } catch (e) {
              value = { detail : {} };
            }
            if (value.detail.child_1.gender != conditions[key]
             && value.detail.child_2.gender != conditions[key]
             && value.detail.child_3.gender != conditions[key]
             && value.detail.child_4.gender != conditions[key]
             && value.detail.child_5.gender != conditions[key]
            ) {
               return false;
            }
          }
          else if (key == 'child_age_min' || key == 'child_age_max') {
            let json = item;
            let value;
            try {
              value = JSON.parse(json);
            } catch (e) {
              value = { detail : {} };
            }
            let child_age_min = conditions["child_age_min"] || 0;
            let child_age_max = conditions["child_age_max"] || 2000;
            if ((!value.detail.child_1.birth || enum_data.getAgeRaw(value.detail.child_1.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_1.birth))
             && (!value.detail.child_2.birth || enum_data.getAgeRaw(value.detail.child_2.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_2.birth))
             && (!value.detail.child_3.birth || enum_data.getAgeRaw(value.detail.child_3.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_3.birth))
             && (!value.detail.child_4.birth || enum_data.getAgeRaw(value.detail.child_4.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_4.birth))
             && (!value.detail.child_5.birth || enum_data.getAgeRaw(value.detail.child_5.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_5.birth))
            ) {
               return false;
            }
          }
          else if (key.indexOf("_min") != -1) {
            let data_key = key.replace("_min", "");
            if (data_key == "target_age") {
              data_key = "detail.target_age";
            }
            let value = this.getValueRaw(item, data_key);
            value = (typeof value == "object") ? value.max : value;
            if (Number(conditions[key]) > Number(value)) {
              return false;
            }
          }
          else if (key.indexOf("_max") != -1) {
            let data_key = key.replace("_max", "");
            if (data_key == "target_age") {
              data_key = "detail.target_age";
            }
            let value = this.getValueRaw(item, data_key);
            value = (typeof value == "object") ? value.min : value;
            if (Number(conditions[key]) < Number(value)) {
              return false;
            }
          }
          else {
            let value = this.getValueRaw(item, key);
            if (String(value).indexOf(conditions[key]) == -1) {
              return false;
            }
          }
        }
        return true;
      });
    }
    return array;
  }
}
