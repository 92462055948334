import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chats.list',
  templateUrl: './chats.list.component.html',
  styleUrls: ['./chats.list.component.scss']
})
export class ChatsListComponent implements OnInit {

  enum_data = enum_data;
  properties : any = {};
  public rows = [];
  public customer;
  public staffs = [];
  is_planner;


  property_rows = [
    { id : 'id', name :"ID", type : "string", placeholder : "IDを入力", },
    { id : 'staff_id', name :"担当プランナー", type : "select", placeholder : "プランナーを選択", options : [], },
    { id : 'name', name :"名前", type : "string", placeholder : "名前を入力", },
    { id : 'last_start_date', name :"交換日", type : "date", },
    { id : 'next_purchase_date', name :"次回決済日", type : "date", },
    { id : 'email', name :"メールアドレス", type : "email", placeholder : "メールアドレスを入力", detail : true, },
    { id : 'tel', name :"電話番号", type : "tel", placeholder : "電話番号を入力", detail : true, },
    { id : 'child_1', name :"子供１", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : 'child_2', name :"子供２", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : 'child_3', name :"子供３", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : 'child_4', name :"子供４", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : 'child_5', name :"子供５", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : "genres", name : "今興味があるもの", type : "multiselect", placeholder : "選択", options : enum_data.toy_genres, detail : true, },
    { id : "shipping_name", name : "郵送先お名前", type : "string", placeholder : "例）山田花子", detail : true, },
    { id : "shipping_tel", name : "郵送先電話番号", type : "tel", placeholder : "例）031234-5678（ハイフン無し半角数字）", detail : true, },
    { id : 'shipping_zipcode', name :"郵送先郵便番号", type : "zipcode", placeholder : "名前を入力", detail : true, prefix : "〒" },
    { id : 'shipping_address1', name :"郵送先住所（番地まで）", type : "string", placeholder : "名前を入力", detail : true, },
    { id : 'shipping_address2', name :"郵送先住所（マンション名・部屋番号等）", type : "string", placeholder : "名前を入力", detail : true, },
    //{ id : 'owneds', name :"手持ちのおもちゃ", type : "owneds", detail : true, },
    { id : 'tags', name :"タグ", type : "tags", placeholder : "タグを選択", detail : true, options : enum_data.customer_tags, },
    { id : 'memos', name :"メモ", type : "memos", detail : true, },
  ];

  constructor(public api : ApiService) { }

  ngOnInit(): void {
    this.property_rows.find(row => row.id == "staff_id").options = this.api.staffs.map((staff) => { return { name : staff.name, value : String(staff.id), }; });
    this.is_planner = this.api.me.grant == "plan";
    this.api.customer.chats().toPromise().then((data) => {
      this.rows = data;
      this.staffs = this.api.staffs;
    });
  }

  selectCustomer(customer) {
    if (customer.staff_id == this.api.me.id) {
      customer.unchecks.unread = 0;
    }
    this.customer = customer;
    this.copy_property_by_customer();
  }

  refresh_customers() {
    Swal.fire("更新中");
    Swal.showLoading();
    this.rows = null;

    this.api.customer.chats().toPromise().then((data) => {
      this.rows = data;
      Swal.close();
    });
  }

  copy_property_by_customer() {
    this.properties = this.property_rows.reduce((data, row) => {
      if (row.detail && typeof this.customer.detail[row.id] == "object" && this.customer.detail[row.id].length === undefined) {
        data[row.id] = {};
        for (let key of Object.keys(this.customer.detail[row.id])) {
          data[row.id][key] = this.customer.detail[row.id][key];
        }
      } else {
        data[row.id] = row.detail ? this.customer.detail[row.id] : this.customer[row.id];
      }
      return data;
    }, {});
  }

}
