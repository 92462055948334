import { Component, OnInit, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AppDataTableComponent } from '../../data-table/data-table.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customers.list',
  templateUrl: './customers.list.component.html',
  styleUrls: ['./customers.list.component.scss']
})
export class CustomersListComponent implements OnInit {
  
  @ViewChild(AppDataTableComponent, {static: false}) customersDataTable: AppDataTableComponent;

  enum_data = enum_data;
  
  public rows = [];
  public staffs = [];
  staff_id;
  is_planner;
  hide_csv_button

  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
    this.staffs = this.api.staffs;
    this.staff_id = this.api.me.id;
    this.is_planner = this.api.me.grant == "plan";
    this.hide_csv_button = ["ship", "plan", "admin"].indexOf(this.api.me.grant) == -1;
  }
  
  search(search_conditions) {
    Swal.fire("読込中");
    Swal.showLoading();
    let conditions = {};
    for (let key of Object.keys(search_conditions)) {
      console.log({[key]:search_conditions[key]});
      let param_name = key.replace(".", "_");
      conditions[param_name] = search_conditions[key];
    }
    this.api.customer.list(conditions).toPromise().then((customers) => {
      this.rows = customers;
      this.customersDataTable.reload();
      Swal.close();
    });
  }

  downloadCsv() {
    window.open(`/api/customers/csv`, '_blank');
  }

}
