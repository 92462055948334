import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Setting } from '../../types/setting.type';
import Swal from 'sweetalert2';
import _ from 'lodash'
import * as moment from 'moment';

@Component({
  selector: 'app-settings.list',
  templateUrl: './settings.list.component.html',
  styleUrls: ['./settings.list.component.scss']
})
export class SettingsListComponent implements OnInit {

  show : boolean = false;
  enum_data = enum_data;
  settings : Setting[];
  properties : any = {};
  editing : boolean = false;
  plans = [];
  changes = [];
  staffs = [];

  property_rows = [
    { id : 'default_staff_id', name :"デフォルト担当プランナー", type : "select", placeholder : "プランナーを選択", options : [], },
    { id : 'toy_genres', name : "おもちゃのジャンル", type : "multistring", },
    { id : 'toy_tags', name : "おもちゃのタグ", type : "multistring", },
    { id : 'line_id', name : "公式LINEのID", type : "string", placeholder : "@xxxxxxxx" },
    { id : 'postal_sorting_code', name : "仕分けコード", type : "string", placeholder : "日本郵便から付与されたものを入力" },
    { id : 'rp_shop_id', name : "ロボットペイメント店舗ID", type : "string", placeholder : "ロボットペイメント店舗IDを入力" },
    { id : 'rp_mailauthckey', name : "ロボットペイメントメール認証キー", type : "string", placeholder : "ロボットペイメントメール認証キーを入力" },
    { id : 'payed_liff_id', name : "決済後ユーザー情報登録用LIFF_ID", type : "string", placeholder : "決済後ユーザー情報登録用LIFF_IDを入力" },
    { id : 'friend_liff_id', name : "友だち追加用LIFF_ID", type : "string", placeholder : "友だち追加用LIFF_IDを入力" },
    { id : 'register_liff_id', name : "登録画面用LIFF_ID", type : "string", placeholder : "登録画面用LIFF_IDを入力" },
    { id : 'link_liff_id', name : "旧アカウント連携用LIFF_ID", type : "string", placeholder : "旧アカウント連携用LIFF_IDを入力" },
    { id : 'moshimo_p_id', name : "もしもアフィリエイトプロモーションID", type : "string", placeholder : "もしもアフィリエイトプロモーションID（数字４桁ほど）" },
    { id : 'moshimo_pc_id', name : "もしもアフィリエイト成果地点ID", type : "string", placeholder : "もしもアフィリエイト成果地点ID（数字４桁ほど）" },
    { id : 'trackingcode', name : "日本郵便仕分けコード番号", type : "string", placeholder : "仕分けコード番号（例:78-01-88）" },
    { id : 'magazine_enable', name : "メルマガ送信", type : "boolean", placeholder : "メルマガ送信" },
  ];

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.property_rows.find(row => row.id == "default_staff_id").options = this.api.staffs.map((staff) => { return { name : staff.name, value : String(staff.id), }; });
    forkJoin([
      this.api.setting.list(),
      this.api.setting.changes(),
    ]).subscribe((result) => {
      
      let changes = result[1].filter(change => {
        return this.property_rows.find(row => row.id == change.setting_name);
      });
      this.changes = changes;
      
      let settings = result[0];
      this.settings = settings;
      this.copy_property_by_setting();
    });
  }
  
  copy_property_by_setting() {
    this.properties = this.property_rows.reduce((data, row) => {
      let setting = this.settings.find(setting => setting.name == row.id);
      if (setting) {
        if (typeof setting.value == "object" && setting.value.length) {
          data[row.id] = setting.value.concat([]);
        } else if (typeof setting.value == "object") {
          data[row.id] = Object.assign({}, setting.value);
        } else {
          data[row.id] = setting.value;
        }
      } else {
        switch (row.type) {
          case "multistring":
          case "multiselect":
            data[row.id] = [];
            break;
          default:
            data[row.id] = "";
            break;
        }
      }
      return data;
    }, {});
  }
  
  edit_start() {
    this.editing = true;
  }
  
  edit_cancel() {
    this.copy_property_by_setting();
    this.editing = false;
  }
  
  edit_save() {
    let update_settings = [];
    this.property_rows.forEach((row) => {
      let edited_property = this.properties[row.id];
      let prev_setting = this.settings.find(setting => setting.name == row.id);
      // 更新
      if (prev_setting) {
        if (JSON.stringify(edited_property) != JSON.stringify(prev_setting.value)) {
          update_settings.push({
            id : prev_setting.id,
            name : prev_setting.name,
            value : edited_property,
          });
        }
      }
      // 新規
      else {
        if (edited_property) {
          update_settings.push({
            name : row.id,
            value : edited_property,
          })
        }
      }
    });

    Swal.fire("設定情報保存中");
    Swal.showLoading();
    this.api.setting.updates(update_settings).toPromise().then((settings) => {
      this.settings = settings;
      this.copy_property_by_setting();
      this.editing = false;
      this.api.prepare().subscribe(() => {
        Swal.fire("設定情報を保存しました", "", "success")
      });
    }).catch((error) => {
      Swal.fire("エラー", "設定情報の保存に失敗しました", "error")
    });
  }
  
  getChangedValue(change, value) {
    if (!value || value == "null") {
      return "";
    }
    let field = this.property_rows.find(row => row.id == change.setting_name);
    if (!field) {
      return "";
    }
    if (field.id == "default_staff_id") {
      return (this.api.staffs.find(staff => staff.id == value) || { name : "", }).name;
    }
    if (!field.type || field.type == "text") {
      return value;
    }
    if (field.type == "select") {
      let data = enum_data[field["data_name"]];
      let item = data.find(item => item.id == value);
      return item ? item.name || "" : "";
    }
    if (field.type == "multiselect") {
      try {
        if (typeof value == "string") {
          value = JSON.parse(value);
        }
      } catch (e) {}
      let data = enum_data[field["data_name"]];
      return value.map((val) => {
        let item = data.find(item => item.id == val);
        return item ? item.name || "" : "";
      }).join("、");
    }
    if (field.type == "multistring") {
      try {
        if (typeof value == "string") {
          value = JSON.parse(value);
        }
        return value.join("、");
      } catch (e) {}
      return value;
    }
    return value;
  }

}
