import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Toy } from '../../types/toy.type';
import { ToyReview } from '../../types/toy-review.type';
import Swal from 'sweetalert2';
import _ from 'lodash'
import * as moment from 'moment';

@Component({
  selector: 'app-toys.view',
  templateUrl: './toys.view.component.html',
  styleUrls: ['./toys.view.component.scss']
})
export class ToysViewComponent implements OnInit {

  show : boolean = false;
  enum_data = enum_data;
  toy : Toy;
  properties : any = {};
  editing : boolean = false;
  plans = [];
  changes = [];
  reviews : ToyReview[] = [];
  
  property_rows = [
    { id : 'identifier', name :"ID", type : "string", placeholder : "IDを入力", },
    { id : 'name', name :"名前", type : "string", placeholder : "名前を入力", },
    { id : 'maker', name :"メーカー", type : "string", placeholder : "メーカー名を入力", detail : true, },
    { id : 'country', name :"国", type : "string", placeholder : "メーカーの国を入力", detail : true, },
    { id : 'genre', name :"カテゴリー", type : "select", placeholder : "カテゴリーを選択", detail : true, options : enum_data.toy_genres, },
    { id : 'tags', name :"タグ", type : "multiselect", placeholder : "タグを選択", detail : true, options : enum_data.toy_tags, },
    { id : 'target_age', name :"対象年齢", type : "age_range", detail : true, },
    { id : 'price', name :"定価", type : "number", placeholder : "おもちゃの定価を入力", detail : true, suffix : "円", },
    { id : 'discount_rate', name :"買取割引率", type : "number", placeholder : "割引率の百分率を入力", detail : true, suffix : "％", },
    { id : 'real_stock', name :"実在庫", type : "number", placeholder : "実際の在庫を入力", suffix : "個", },
    { id : 'virtual_stock', name :"仮想在庫", type : "number", placeholder : "返却待ちを含めた在庫を入力", suffix : "個", readonly : true, },
    { id : 'self', name :"セルフコース対象", type : "select", options : enum_data.toy_selfs, },
    { id : 'skill', name :"巧緻性パラメータ", type : "number", placeholder : "パラメータの数値を入力", suffix : "点", },
    { id : 'logic', name :"論理性パラメータ", type : "number", placeholder : "パラメータの数値を入力", suffix : "点", },
    { id : 'creativity', name :"創造性パラメータ", type : "number", placeholder : "パラメータの数値を入力", suffix : "点", },
    { id : 'sociality', name :"社交性パラメータ", type : "number", placeholder : "パラメータの数値を入力", suffix : "点", },
    { id : 'music', name :"音感性パラメータ", type : "number", placeholder : "パラメータの数値を入力", suffix : "点", },
    { id : 'offer_count', name :"提案中の個数", type : "number", placeholder : "", suffix : "個", readonly : true, },
    { id : 'prepare_count', name :"発送準備中の個数", type : "number", placeholder : "", suffix : "個", readonly : true, },
    { id : 'storage_stock', name :"倉庫の実個数", type : "number", placeholder : "", suffix : "個", readonly : true, },
    { id : 'description', name :"説明文", type : "text", placeholder : "説明文を入力（プランシートに記載されます）", detail : true, },
    { id : 'parts', name :"パーツの詳細", type : "text", placeholder : "パーツの詳細文を入力（プランシートに記載されます）", detail : true, },
    { id : 'memos', name :"メモ", type : "memos", detail : true, },

  ];

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    this.api.toy.get(id).toPromise().then((toy) => {
      this.toy = toy;
      console.log({toy});
      this.reviews = this.toy.reviews;
      this.changes = this.toy.changes;
      this.copy_property_by_toy();
      if (!this.toy.name) {
        this.editing = true;
      }
    })
    this.api.toy.plans(id, ['unfix','fix','prepare','rental','returning','buy_request']).toPromise().then((plans) => {
      this.plans = plans;
    })
  }
  
  copy_property_by_toy() {
    this.properties = this.property_rows.reduce((data, row) => {
      if (row.detail && typeof this.toy.detail[row.id] == "object" && this.toy.detail[row.id].length === undefined) {
        data[row.id] = {};
        for (let key of Object.keys(this.toy.detail[row.id])) {
          data[row.id][key] = this.toy.detail[row.id][key];
        }
      } else {
        data[row.id] = row.detail ? this.toy.detail[row.id] : this.toy[row.id];
      }
      if (row.type == "age_range" && !data[row.id]) {
        data[row.id] = {};
      }
      return data;
    }, {});
  }
  
  edit_start() {
    this.editing = true;
  }
  
  edit_cancel() {
    this.copy_property_by_toy();
    this.editing = false;
  }
  
  edit_save() {
    this.property_rows.forEach((row) => {
      if (row.detail && typeof this.properties[row.id] == "object" && this.properties[row.id].length === undefined) {
        this.toy.detail[row.id] = {};
        for (let key of Object.keys(this.properties[row.id])) {
          this.toy.detail[row.id][key] = this.properties[row.id][key];
        }
      } else {
        if (row.detail) {
          this.toy.detail[row.id] = this.properties[row.id];
        } else {
          this.toy[row.id] = this.properties[row.id];
        }
      }
    });
    console.log({toy:this.toy});

    Swal.fire("おもちゃ情報保存中");
    Swal.showLoading();
    this.api.toy.update(this.toy).toPromise().then((data) => {
      this.toy = data;
//      this.reviews = this.toy.reviews;
      this.changes = this.toy.changes;
      this.copy_property_by_toy();
      this.editing = false;
      Swal.fire("おもちゃ情報を保存しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "おもちゃ情報の保存に失敗しました", "error")
    });
  }
  
  getChangedValue(change, value) {
    if (!value) {
      return "";
    }
    let field = enum_data.toyChangeFieldByValue(change.field);
    if (!field.type || field.type == "text") {
      return value;
    }
    if (field.type == "select") {
      let data = enum_data[field.data_name];
      let item = data.find(item => item.id == value);
      return item ? item.name || "" : "";
    }
    if (field.type == "multiselect") {
      let data = enum_data[field.data_name];
      value.map((val) => {
        let item = data.find(item => item.id == val);
        return item ? item.name || "" : "";
      }).join("、");
    }
    if (field.type == "memos") {
      let memos;
      try {
        memos = JSON.parse(value);
      } catch (e) {
        memos = [];
      }
      if (!memos || !memos.length) {
        return "";
      }
      return memos.map((memo) => {
        let text = memo.text;
        let date = memo.date;
        let type = memo.type;
        let date_string = moment(date).format("YYYY年M月D日");
        let type_name = enum_data.memoTypeByValue(type).name;
        return `[${date_string}][${type_name}]「${text}」`;
      }).join("\n");
    }
  }
  
  toggleReviewVisible (review : ToyReview, visible : boolean) {
    Swal.fire("レビュー設定を保存中");
    Swal.showLoading();
    this.api.toy.updateReview(review.id, { visible, }).toPromise().then((data) => {
      let review = this.reviews.filter(review => review.id == data.id).shift();
      review.visible = data.visible;
      Swal.fire("レビューの設定を保存しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "レビュー設定の保存に失敗しました", "error")
    });
  }

}
