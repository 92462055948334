import { Component, Input, Output, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, } from '@angular/forms';
import { Toy } from '../types/toy.type';
import * as enum_data from '../../../../api/lib/enum_data';

@Component({
  selector: 'toy-card',
  templateUrl: './toy-card.component.html',
  styleUrls: ['./toy-card.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToyCardComponent),
    },
  ]
})
export class ToyCardComponent implements ControlValueAccessor {
  
  @Input() toy : Toy;
  
  @Input() smart : boolean;
  @Input() simple : boolean;
  @Input() selected : boolean;
  @Input() selectable : boolean;
  @Input() label : string;
  @Input() buttons : string[];

  @Output() buttonClick: EventEmitter<{event:any,index:number}> = new EventEmitter();
  @Output() select : EventEmitter<string> = new EventEmitter();

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  writeValue(text: string): void {
    if (text !== this.value) {
      this.value = text;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
  
  _value; // value を保存しておく変数を定義する。名前はなんでも良い

  get value() {
    return this._value;
  }
  @Input('value')
  set value(_value) {
    this._value = _value;
    this.onChangeCallback(_value);
  }
  
  enum_data = enum_data;

  constructor() { }

  ngOnInit(): void {
  }
  
  public onSelect(event) {
    this.value = !this.value;
    this.select.emit();
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  public onButtonClick(event, index) {
    this.buttonClick.emit({event, index});
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  public price(price) {
    let str = String(price);
    if (str.length <= 3) {
      return str;
    }
    return str.slice(0, -3) + "," + str.slice(-3);
  }

}
