<ng-container>
  <div class="row back-top">
    <div class="col-sm-6">
      <div class="table-responsive">
        <table class="table">
          <tr property-row *ngFor="let row of customer_property_rows_1"
            [id]="row.id"
            [name]="row.name"
            [(ngModel)]="properties[row.id]"
            [type]="row.type"
            [options]="row.options"
            [placeholder]="row.placeholder"
            [suffix]="row.suffix"
            [prefix]="row.prefix"
            [hidden]="!properties[row.id]"
          ></tr>
        </table>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="edit-buttons">
        <button type="button" class="btn btn-primary" [hidden]="editing" (click)="edit_start()">編集</button>
        <button type="button" class="btn btn-secondary" [hidden]="!editing" (click)="edit_cancel()">キャンセル</button>
        <button type="button" class="btn btn-primary" [hidden]="!editing" (click)="edit_save()">保存</button>
      </div>
      <div class="table-responsive">
        <table class="table">
          <tr property-row *ngFor="let row of customer_property_rows_2"
            [id]="row.id"
            [name]="row.name"
            [(ngModel)]="properties[row.id]"
            [type]="row.type"
            [options]="row.options"
            [placeholder]="row.placeholder"
            [suffix]="row.suffix"
            [prefix]="row.prefix"
          ></tr>
          <tr property-row *ngFor="let row of customer_property_rows_3"
            [id]="row.id"
            [name]="row.name"
            [(ngModel)]="properties[row.id]"
            [type]="row.type"
            [editing]="editing"
            [options]="row.options"
            [placeholder]="row.placeholder"
            [suffix]="row.suffix"
            [prefix]="row.prefix"
          ></tr>
        </table>
      </div>
    </div>
  </div>

  <div class="buttons">
    <button class="btn btn-primary" type="button" (click)="submit_back()">返却確認作業完了</button>
    <button class="btn btn-success" type="button" (click)="submit_lostparts()">パーツ不足の確定</button>
  </div>
  
  <data-table 
    id="backs.view.toys"
    title="返却おもちゃ一覧"
    [rows]="plan?.toys"
    [columns]="{
      'identifier' : { name : 'ID' },
      'main_image' : { name : '画像', customCell : imageCell },
      'detail.genre' : { name : 'おもちゃ', customCell : toyNameCell },
      'state' : { name : '状態', type : 'select', data : enum_data.plan_toy_status },
      'action' : { name : 'アクション', customCell : actionCell },
      'lost_parts' : { name : 'パーツ不足入力', customCell : partsCell }
    }">
  
  </data-table>

</ng-container>

<ng-template #stateCell let-row="row" let-value="value">
  <p class="state">{{enum_data.planStateByValue(row.state).name}}</p>
  <p class="request-date">{{row.request_date | date : "y年M月d日" }}</p>
</ng-template>
<ng-template #imageCell let-row="row" let-value="value">
  <a [href]="value.url">
    <img [src]="value.url" class="main_image">
  </a>
</ng-template>
<ng-template #toyNameCell let-row="row" let-value="value">
  <div class="name-cell">
    <p class="name" [hidden]="!row.name">{{row.name}}</p>
    <p class="name noname" [hidden]="row.name">名前無し</p>
    <p class="parts">{{row.detail.parts}}</p>
  </div>
</ng-template>
<ng-template #toysCell let-row="row" let-value="value">
  <div class="toy" *ngFor="let toy of value">
    <span class="name">{{toy.name}}</span>
    <span class="name">（{{enum_data.planToyStateByValue(toy.state).name}}）</span>
  </div>
</ng-template>
<ng-template #nameCell let-row="row" let-value="value">
  <div class="name-cell">
    <p class="name" [hidden]="!value.name">{{value.name}}</p>
    <p class="name noname" [hidden]="value.name">名前無し</p>
    <div class="tags">
      <p class="genre"[hidden]="!value.detail.genre">{{value.detail.genre}}</p>
      <div *ngFor="let tag of value.detail.tags">
        <p class="tag">{{tag}}</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #actionCell let-row="row" let-value="value">
  <button type="button" class="btn btn-primary" (click)="back(row)">返却完了</button>
</ng-template>

<ng-template #partsCell let-row="row" let-value="value">
  <div class="parts">
    <div class="part" *ngFor="let part of value; index as i">
      <div class="form-row">
        <input class="form-control name" type="text" [(ngModel)]="part.name" placeholder="パーツの名称を記入">
        <input class="form-control number" type="number" [(ngModel)]="part.number" placeholder="個数">
        <button type="button" class="form-control delete fa" (click)="remove_part(value, i)"></button>
      </div>
    </div>
    <button class="btn btn-primary add-button" (click)="add_part(value)">+ 追加</button>
    <button class="btn btn-success save-button" (click)="save_parts(row)">保存</button>
  </div>
</ng-template>
