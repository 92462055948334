import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Setting } from '../../types/setting.type';
import Swal from 'sweetalert2';
import _ from 'lodash'
import * as moment from 'moment';

@Component({
  selector: 'app-settings.self',
  templateUrl: './settings.self.component.html',
  styleUrls: ['./settings.self.component.scss']
})
export class SettingsSelfComponent implements OnInit {

  show : boolean = false;
  enum_data = enum_data;
  settings : Setting[];
  counts : any;
  properties : any = {};
  editing : boolean = false;
  plans = [];
  changes = [];
  staffs = [];

  property_rows = [
    { id : 'self_months_upper', name : "月齢範囲上限（月数）", type : "string", placeholder : "月数を入力" },
    { id : 'self_months_lower', name : "月齢範囲下限（月数）", type : "string", placeholder : "月数を入力" },
    { id : 'self_stock_limit', name : "おもちゃ選択表示在庫下限（在庫数）", type : "string", placeholder : "在庫数を入力" },
    { id : 'self_append_limit', name : "おもちゃ範囲拡張リミット（種類数）", type : "string", placeholder : "種類数を入力" },
    { id : 'self_append_upper', name : "範囲拡張時月齢上限（月数）", type : "string", placeholder : "月数を入力" },
    { id : 'self_append_lower', name : "範囲拡張時月齢下限（月数）", type : "string", placeholder : "月数を入力" },
  ];

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    forkJoin([
      this.api.setting.list(),
      this.api.setting.changes(),
    ]).subscribe((result) => {
      
      let changes = result[1].filter(change => {
        return this.property_rows.find(row => row.id == change.setting_name);
      });
      this.changes = changes;
      
      let settings = result[0];
      this.settings = settings;
      this.copy_property_by_setting();
      
      this.preview();
    });
  }
  
  copy_property_by_setting() {
    this.properties = this.property_rows.reduce((data, row) => {
      let setting = this.settings.find(setting => setting.name == row.id);
      if (setting) {
        if (typeof setting.value == "object" && setting.value.length) {
          data[row.id] = setting.value.concat([]);
        } else if (typeof setting.value == "object") {
          data[row.id] = Object.assign({}, setting.value);
        } else {
          data[row.id] = setting.value;
        }
      } else {
        switch (row.type) {
          case "multistring":
          case "multiselect":
            data[row.id] = [];
            break;
          default:
            data[row.id] = "";
            break;
        }
      }
      return data;
    }, {});
  }
  
  edit_start() {
    this.editing = true;
  }
  
  edit_cancel() {
    this.copy_property_by_setting();
    this.editing = false;
  }
  
  edit_save() {
    let update_settings = [];
    this.property_rows.forEach((row) => {
      let edited_property = this.properties[row.id];
      let prev_setting = this.settings.find(setting => setting.name == row.id);
      // 更新
      if (prev_setting) {
        if (JSON.stringify(edited_property) != JSON.stringify(prev_setting.value)) {
          update_settings.push({
            id : prev_setting.id,
            name : prev_setting.name,
            value : edited_property,
          });
        }
      }
      // 新規
      else {
        if (edited_property) {
          update_settings.push({
            name : row.id,
            value : edited_property,
          })
        }
      }
    });

    Swal.fire("設定情報保存中");
    Swal.showLoading();
    this.api.setting.updates(update_settings).toPromise().then((settings) => {
      this.settings = settings;
      this.copy_property_by_setting();
      this.editing = false;
      this.api.prepare().subscribe(() => {
        Swal.fire("設定情報を保存しました", "", "success").then(() => {
          this.preview();
        })
      });
    }).catch((error) => {
      Swal.fire("エラー", "設定情報の保存に失敗しました", "error")
    });
  }
  
  getChangedValue(change, value) {
    if (!value || value == "null") {
      return "";
    }
    let field = this.property_rows.find(row => row.id == change.setting_name);
    if (!field) {
      return "";
    }
    if (field.id == "default_staff_id") {
      return (this.api.staffs.find(staff => staff.id == value) || { name : "", }).name;
    }
    if (!field.type || field.type == "text") {
      return value;
    }
    if (field.type == "select") {
      let data = enum_data[field["data_name"]];
      let item = data.find(item => item.id == value);
      return item ? item.name || "" : "";
    }
    if (field.type == "multiselect") {
      try {
        if (typeof value == "string") {
          value = JSON.parse(value);
        }
      } catch (e) {}
      let data = enum_data[field["data_name"]];
      return value.map((val) => {
        let item = data.find(item => item.id == val);
        return item ? item.name || "" : "";
      }).join("、");
    }
    if (field.type == "multistring") {
      try {
        if (typeof value == "string") {
          value = JSON.parse(value);
        }
        return value.join("、");
      } catch (e) {}
      return value;
    }
    return value;
  }
  
  preview() {
    Swal.fire("表示おもちゃ数の取得中");
    Swal.showLoading();
    this.api.toy.self_toys(
      this.properties.self_months_upper,
      this.properties.self_months_lower,
      this.properties.self_stock_limit,
      this.properties.self_append_limit,
      this.properties.self_append_upper,
      this.properties.self_append_lower
    ).toPromise().then((counts) => {
      this.counts = counts.map(count => {
        count.months_name = enum_data.getAgeString(count.months);
        return count;
      });
      Swal.close();
    }).catch((error) => {
      Swal.fire("エラー", "表示おもちゃ数の取得に失敗しました", "error")
    });
  }

}
