import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Setting } from '../types/setting.type';
import {Plan} from "../types/plan.type";
import { Card } from "../types/card.type";

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(private http: HttpClient) { }

  list() : Observable<Setting[]> {
    return this.http.get<Setting[]>('/api/cards/list');
  }

  public get(card_id) : Observable<Plan> {
    return this.http.get<Plan>('/api/cards/' + card_id);
  }


  changes() : Observable<Setting[]> {
    return this.http.get<Setting[]>('/api/settings/changes');
  }

  public create(params) : Observable<any> {
    return this.http.post<any>('/api/cards/', params);
  }

  public update(card_id, params) : Observable<any> {
    return this.http.put<any>('/api/cards/' + card_id, params);
  }

}
