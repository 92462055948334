<ng-container *ngIf="card || true">
  <div class="row">
    <div class="col-sm-5">
      <card-type [card]="preview_card || card" [editing]="true"></card-type>
    </div>
    <div class="col-sm-7">
      <div class="edit-buttons">
        <button type="button" class="btn btn-primary" [hidden]="editing" (click)="edit_start()">編集</button>
        <button type="button" class="btn btn-secondary" [hidden]="!editing" (click)="preview()">プレビュー</button>
        <button type="button" class="btn btn-secondary" [hidden]="!editing" (click)="edit_cancel()">キャンセル</button>
        <button type="button" class="btn btn-primary" [hidden]="!editing" (click)="edit_save()">保存</button>
      </div>
      <div class="table-responsive">
        <table class="table">
          <tr property-row *ngFor="let row of property_rows"
              [id]="row.id"
              [name]="row.name"
              [(ngModel)]="properties[row.id]"
              [type]="row.type"
              [editing]="editing"
              [options]="row.options"
              [placeholder]="row.placeholder"
              (onChangeType)="setReadOnly($event)"
              [readonly]="row.readonly"
              [suffix]="row.suffix"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</ng-container>

