import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { User } from '../types/user.type';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  public me : User;
  public gettingMe;

  constructor(private http: HttpClient) { }
  
  login(email: string, password: string, keep : boolean): Observable<User> {
    return from(this.http.post<User>('/api/auth/login', {email: email, password: password, keep : keep,}).toPromise().then((user) => {
      this.me = user;
      
      return user;
    }));
  }
  
  logout(): Observable<string> {
    this.me = null;
    return this.http.put<string>('/api/auth/logout', {});
  }
  
  forget(email, password): Observable<any> {
    return this.http.post<any>('/api/auth/forget', {email, password});
  }

  getMe(force : boolean = false) : Observable<User> {
    if (this.gettingMe) {
      return this.gettingMe;
    }
    if (!force) {
      if (this.me) {
        return from(Promise.resolve(this.me));
      }
    }

    this.gettingMe = from(this.http.get<User>('/api/auth/me').toPromise().then(me => {
      this.me = me;
      this.gettingMe = null;
      return me;
    }).catch((error) => {
      this.me = null;
      this.gettingMe = null;
      throw error;
    }));
    
    return this.gettingMe;
  }
  
  registerMessagingToken(token : string, prev_token : string) : Observable<string>{
    return this.http.post<string>("/api/auth/messaging", { token : token, prev_token : prev_token,});
  }
  
  resetPassword(email : string, password : string) : Observable<any> {
    return this.http.post<any>("/api/auth/forget", { email, password, });
  }

}
