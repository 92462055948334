import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Toy } from '../types/toy.type';
import { ToyImage } from '../types/toy-image.type';
import { ToyReview } from '../types/toy-review.type';
import { Plan } from '../types/plan.type';

interface SelfToyCount {
  months : string;
  months_name : string;
  toy_count : number;
}

@Injectable({
  providedIn: 'root'
})
export class ToyService {

  constructor(private http: HttpClient) { }
  
  public list(conditions = {}): Observable<Toy[]> {
    return this.http.get<Toy[]>('/api/toys/list', { params : conditions, });
  }

  public get(toy_id) : Observable<Toy> {
    return this.http.get<Toy>('/api/toys/' + toy_id);
  }

  public addImage(toy_id, image_data) : Observable<ToyImage> {
    return this.http.post<ToyImage>('/api/toys/' + toy_id + '/images', {
      data : image_data,
    })
  }
  
  public add() : Observable<Toy> {
    return this.http.post<Toy>('/api/toys', {});
  }
  
  public update(toy) : Observable<Toy> {
    return this.http.put<Toy>('/api/toys/' + toy.id, toy)
  }
  
  public changeMainImage(toy_id, toy_image_id) : Observable<ToyImage[]> {
    return this.http.put<ToyImage[]>('/api/toys/' + toy_id + '/main_image', {
      toy_image_id : toy_image_id,
    })
  }
  
  public deleteImage(toy_id, toy_image_id) : Observable<ToyImage[]> {
    return this.http.delete<ToyImage[]>('/api/toys/' + toy_id + '/images/' + toy_image_id);
  }
  
  public plans(toy_id, status = []): Observable<Plan[]> {
    return this.http.get<Plan[]>('/api/toys/' + toy_id + '/plans/list?status=' + status.join(","));
  }
  
  public self_toys(month_upper, month_lower, stock_limit, append_limit, append_upper, append_lower) : Observable<SelfToyCount[]> {
    return this.http.get<SelfToyCount[]>('/api/toys/self_toys?' +
      "&month_upper=" + month_upper +
      "&month_lower=" + month_lower +
      "&stock_limit=" + stock_limit +
      "&append_limit=" + append_limit +
      "&append_upper=" + append_upper +
      "&append_lower=" + append_lower +
      "");
  }

  public updateReview(toy_review_id, params) : Observable<ToyReview> {
    return this.http.put<ToyReview>('/api/toys/reviews/' + toy_review_id, params)
  }

}
