import {Component, Input, Output, OnInit, forwardRef, SimpleChanges, ElementRef, ViewChild, EventEmitter,} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, } from '@angular/forms';
import { ApiService } from '../services/api.service';
import * as enum_data from '../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'tr[property-row]',
  templateUrl: './property-row.component.html',
  styleUrls: ['./property-row.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PropertyRowComponent),
    },
  ],
})
export class PropertyRowComponent implements ControlValueAccessor {

  @Input() id;
  @Input() editing;
  @Input() type;
  @Input() name;
  @Input() options;
  @Input() suffix = "";
  @Input() prefix = "";
  @Input() true_value = "";
  @Input() false_value = "";
  @Input() placeholder = "";
  @Input() readonly = false;

  @Output() onChangeType: EventEmitter<any> = new EventEmitter();

  password_confirm = null;
  enum_data = enum_data;

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};
  select_options;
  checkbox_value;
  image_preview;
  @ViewChild('image_file') image_file_input : ElementRef;

  writeValue(text: string): void {
    if (text !== this.value) {
      this.value = text;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {}
  constructor(private element : ElementRef, private api : ApiService) { }

  _value; // value を保存しておく変数を定義する。名前はなんでも良い

  get value() {
    return this._value;
  }
  @Input('value')
  set value(text) {
    if (this._value !== text) {
      this._value = text;
      this.onChangeCallback(text);

      if (this.type == "tags") {
        this.checkbox_value = (this._value || []).reduce((hash, key) => { hash[key] = true; return hash}, {});
      }
      if (this.type == "age_range") {
        if (!this._value) {
          this._value = {};
        }
      }
      if (this.type == "select") {
        this._value = String(this._value || "");
      }
    }
  }

  ngOnInit(): void {
    this.select_options = (this.options || []).map((option) => {
      return {
        label : option.name,
        value : option.value,
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.select_options = (changes.options.currentValue || []).map((option) => {
        return {
          label : option.name,
          value : option.value,
        }
      })
    }
  }

  trackBy(index: number, obj: any): any {
    return index;
  }

  input(v) {
    this.value = v;
  }

  getSelectName(value) {
    return ((this.options || []).find(option => option.value == value) || {}).name;
  }

  checkboxChange(event) {
    this.value = Object.keys(this.checkbox_value).filter(key => this.checkbox_value[key]);
  }

  getTagIcon(value) {
    return ((this.options || []).find(option => option.value == value) || {}).icon;
  }

  getTagName(value) {
    return ((this.options || []).find(option => option.value == value) || {}).name;
  }

  toWideCharacterNumber(string) {
    return string
      .replace(/0/g, "０")
      .replace(/1/g, "１")
      .replace(/2/g, "２")
      .replace(/3/g, "３")
      .replace(/4/g, "４")
      .replace(/5/g, "５")
      .replace(/6/g, "６")
      .replace(/7/g, "７")
      .replace(/8/g, "８")
      .replace(/9/g, "９")
  }

  getAgeString(months) {
    if (!months && months !== 0) {
      return "";
    }
    let age = Math.floor(Number(months) / 12);
    let month = months - age * 12;
    if (age > 0) {
      return this.toWideCharacterNumber(`${age}歳${month}ヶ月`);
    } else {
      return this.toWideCharacterNumber(`${month}ヶ月`);
    }
  }

  getAge(value) {
    let months = moment().diff(value, 'months');
    return this.getAgeString(months);
  }

  getZipcodeString(zipcode) {
    if (!zipcode) {
      return zipcode;
    }
    return zipcode.slice(0, 3) + "-" + zipcode.slice(3);
  }

  getTelString(tel) {
    if (tel.length == 11) {
      return tel.slice(0, 3) + "-" + tel.slice(3, 7) + "-" + tel.slice(7, 11);
    } else if (tel.length == 10) {
      return tel.slice(0, 2) + "-" + tel.slice(2, 6) + "-" + tel.slice(6, 10);
    } else {
      return tel;
    }
  }

  add_memo() {
    this.value = (this.value || []).concat([{
      date : moment().format("YYYY-MM-DD"),
      type : 'normal',
      text : "",
    }]);
  }

  add_string() {
    this.value = (this.value || []).concat([""]);
  }

  delete_memo(i) {
    this.value = this.value.slice(0, i).concat(this.value.slice(i + 1));
  }

  add_child() {
    this.value = {
      name : "",
      gender : "",
      birth : moment().format("YYYY-MM-DD"),
    };
  }

  change() {
    if (!this.value && !this.password_confirm) {
      this.element.nativeElement.classList.remove("is-invalid");
      return;
    }
    if (this.value != this.password_confirm) {
      this.element.nativeElement.classList.add("is-invalid");
      return;
    } else {
      this.element.nativeElement.classList.remove("is-invalid");
      return;
    }
  }

  copy() {
    Swal.fire("コピーしました");
  }

  changeBtnType(event) {
    this.onChangeType.emit({ id : this.id, value : event.target.value, });
  }

  image_changed(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.image_preview = reader.result;
      };
    }
  }
  
  upload(file) {
    if (!this.image_preview) {
      Swal.fire("エラー", "画像を選択してください", "error")
      return;
    }
    Swal.fire("アップロード中");
    Swal.showLoading();
    this.api.image.addImage(this.image_preview).toPromise().then((image) => {
      this.value = image.url;
      this.clear();
      Swal.fire("画像アップロード成功", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "画像のアップロードに失敗しました", "error")
    });
  }

  clear() {
    this.image_preview = null;
    this.image_file_input.nativeElement.value = "";
  }
  
}
