<ng-container *ngIf="shown">
  
  <data-table 
    id="coupons.list.coupons"
    title="コース一覧"
    [rows]="rows"
    [button]="'追加'"
    (onClickButton)="add_coupon_modal.show()"
    [columns]="{
      'order' : { name : '順番',  order : 'asc' },
      'name' : { name : '割引コード名' },
      'course_name' : { name : 'コース名' },
      'type' : { name : '種別', type : 'select', data : enum_data.coupon_types, search : { type : 'select', options : enum_data.coupon_types } },
      'enable' : { name : '有効/無効', type : 'select', data : enum_data.coupon_enables, search : { type : 'select', options : enum_data.coupon_enables } },
      'code' : { name : '割引コード' },
      'product_id' : { name : 'RP商品コード' },
      'months' : { name : '決済間隔（月数）' },
      'price' : { name : '基本月額料金' },
      'total_amount' : { name : 'コース料金' },
      'action' : { name : 'アクション', customCell : actionCell }
    }"
  >
  </data-table>
  
  <data-table 
    id="coupons.list.changes"
    title="変更履歴"
    [rows]="changes"
    [columns]="{
      'staff.name' : { name : '担当者' },
      'coupon.name' : { name : '割引コード名' },
      'coupon.course_name' : { name : 'コース名' },
      'field' : { name : '項目', type : 'select', data : enum_data.coupon_change_fields },
      'before_value' : { name : '変更前', customCell : changeValueCell },
      'after_value' : { name : '変更後', customCell : changeValueCell },
      'createdAt' : { name : '変更日時', type : 'datetimesec', order : 'desc' }
    }">
  </data-table>

</ng-container>

<ng-template #actionCell let-row="row" let-value="value">
  <button class="btn btn-primary" (click)="edit_modal_open(row)">変更</button>
</ng-template>
<ng-template #changeValueCell let-row="row" let-value="value">
  <span class="changed-value">{{getChangedValue(row, value)}}</span>
</ng-template>

<app-ui-modal #add_coupon_modal>
  <div class="app-modal-header">
    <h5 class="modal-title">コースを追加する</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="add_coupon_modal.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="table-responsive">
      <table class="table">
        <tr property-row *ngFor="let row of property_rows"
          [id]="row.id"
          [name]="row.name"
          [(ngModel)]="new_coupon[row.id]"
          [type]="row.type"
          [editing]="true"
          [options]="row.options"
          [placeholder]="row.placeholder"
          [suffix]="row.suffix"
          [prefix]="row.prefix"
        ></tr>
      </table>
    </div>
    <div class="row">
      <div class="col-sm-3"></div>
      <button class="col-sm-6 btn btn-primary btn-lg" type="button" (click)="add()">追加</button>
    </div>
  </div>
</app-ui-modal>

<app-ui-modal #edit_coupon_modal>
  <div class="app-modal-header">
    <h5 class="modal-title">コースを変更する</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="edit_coupon_modal.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <div class="table-responsive">
      <table class="table">
        <tr property-row *ngFor="let row of property_rows"
          [id]="row.id"
          [name]="row.name"
          [(ngModel)]="edit_coupon[row.id]"
          [type]="row.type"
          [editing]="true"
          [options]="row.options"
          [placeholder]="row.placeholder"
          [suffix]="row.suffix"
          [prefix]="row.prefix"
        ></tr>
      </table>
    </div>
    <div class="row">
      <div class="col-sm-3"></div>
      <button class="col-sm-6 btn btn-primary btn-lg" type="button" (click)="edit()">保存</button>
    </div>
  </div>
</app-ui-modal>
