
<button type="button" class="btn btn-primary csv-button" (click)="downloadCsv()" [hidden]="hide_csv_button">CSVダウンロード</button>

<data-table
  id="customers.list.customers"
  title="ユーザー一覧"
  [rows]="rows"
  [custom_search]="true"
  (onSearch)="search($event)"
  [columns]="{
    'freeword' : { name : 'フリーワード', search : { type : 'string' }, hidden : true },
    'name' : { name : '名前', search : { type : 'string' } },
    'detail.*' : { name : '子供の月齢', customCell : childCell, search : { type : 'child' } },
    'detail.registeredAt' : { name : '登録日時', type : 'datetime' },
    'staff.id' : { name : '担当プランナー', type : 'select', search : is_planner ? undefined : { type : 'select', options : staffs, initial : staff_id }, data : staffs, hidden : is_planner },
    'detail.tags' : { name : 'タグ', type : 'multiselect', search : { type : 'multiselect', options : enum_data.customer_tags }, data : enum_data.customer_tags },
    'next_order_at' : { name : '次回交換申請可能日', type : 'date' },
    'line_id' : { name : 'LINE連携', customCell : linkCell, search : { type : 'boolean', options : enum_data.links } },
    'action' : { name : 'アクション', customCell : actionCell },
    'customer_id' : { name : 'ユーザーID', search : { type : 'string' }, hidden : true }
  }"
  #customersDataTable
>
</data-table>
<ng-template #childCell let-row="row" let-value="value">
  <div class="child" *ngIf="value.child_1">
    <span class="name">{{value.child_1.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_1.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_1.birth)}}</span>
    ）
  </div>
  <div class="child" *ngIf="value.child_2">
    <span class="name">{{value.child_2.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_2.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_2.birth)}}</span>
    ）
  </div>
  <div class="child" *ngIf="value.child_3">
    <span class="name">{{value.child_3.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_3.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_3.birth)}}</span>
    ）
  </div>
  <div class="child" *ngIf="value.child_4">
    <span class="name">{{value.child_4.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_4.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_4.birth)}}</span>
    ）
  </div>
  <div class="child" *ngIf="value.child_5">
    <span class="name">{{value.child_5.name}}</span>
    （
    <span class="gender" [ngClass]="'gender_' + value.child_5.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
    ・
    <span class="age">{{enum_data.getAge(value.child_5.birth)}}</span>
    ）
  </div>
</ng-template>
<ng-template #linkCell let-row="row" let-value="value">
  <span [hidden]="value">未連携</span>
  <span [hidden]="!value">連携済み</span>
</ng-template>
<ng-template #actionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/customers/'+row.id" [queryParams]="{ edit: '1'}">編集</a>
</ng-template>


