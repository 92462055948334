import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { UiModalComponent } from '../../theme/shared/components/modal/ui-modal/ui-modal.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-coupons.list',
  templateUrl: './coupons.list.component.html',
  styleUrls: ['./coupons.list.component.scss']
})
export class CouponsListComponent implements OnInit {
  
  @ViewChild("add_coupon_modal") add_coupon_modal : UiModalComponent;
  @ViewChild("edit_coupon_modal") edit_coupon_modal : UiModalComponent;


  enum_data = enum_data;
  shown = true;
  
  public rows = [];
  public changes = [];
  public new_coupon = {
    order : "",
    name : "",
    course_name : "",
    type : "",
    enable : "1",
    code : this.generate_random_code(),
    months : "",
    price : "",
    total_amount : "",
    product_id : "",
  };
  public edit_coupon = {
    order : "",
    id : 0,
    name : "",
    course_name : "",
    type : "",
    enable : "1",
    code : "",
    months : "",
    price : "",
    total_amount : "",
    product_id : "",
  };

  property_rows = [
    { id : 'order', name :"順番", type : "string", placeholder : "連番でなくても構いません", },
    { id : 'name', name :"割引コード名", type : "string", placeholder : "割引コード名を入力", },
    { id : 'course_name', name :"コース名", type : "string", placeholder : "コース名を入力", },
    { id : 'enable', name :"有効/無効", type : "select", placeholder : "選択してください", options : enum_data.coupon_enables, },
    { id : 'code', name :"割引コード", type : "string", placeholder : "自動入力されます", },
    { id : 'type', name :"種別", type : "select", placeholder : "種別を選択", options : enum_data.coupon_types, },
    { id : 'months', name :"決済間隔（月数）", type : "string", placeholder : "決済が行われる間隔を半角数字で入力", },
    { id : 'price', name :"月額料金", type : "string", placeholder : "月額基本料金（割引なし）を半角数字入力", },
    { id : 'total_amount', name :"コース料金", type : "string", placeholder : "コース料金（税込）を半角数字入力", },
    { id : 'product_id', name :"RP商品コード", type : "string", placeholder : "RP商品情報画面から取得してください", },
  ];

  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
    forkJoin([
      this.api.coupon.list(),
      this.api.coupon.changes(),
    ]).subscribe((results) => {
      this.rows = results[0];
      this.changes = results[1];
      this.shown = true;
      // while (this.rows.length) {
      //   this.rows.pop();
      // }
      // while (this.changes.length) {
      //   this.changes.pop();
      // }
      // for (let coupon of results[0]) {
      //   this.rows.push(coupon);
      // }
      // for (let change of results[1]) {
      //   this.changes.push(change);
      // }
    });
  }
  
  edit_modal_open(coupon) {
    this.edit_coupon.order = coupon.order;
    this.edit_coupon.id = coupon.id;
    this.edit_coupon.name = coupon.name;
    this.edit_coupon.course_name = coupon.course_name;
    this.edit_coupon.enable = String(coupon.enable);
    this.edit_coupon.type = coupon.type;
    this.edit_coupon.code = coupon.code;
    this.edit_coupon.months = coupon.months;
    this.edit_coupon.price = coupon.price;
    this.edit_coupon.total_amount = coupon.total_amount;
    this.edit_coupon.product_id = coupon.product_id;
    
    this.edit_coupon_modal.show();
  }
  
  add() {
    if (!this.new_coupon.name) {
      return Swal.fire("エラー", "名前を入力してください", "error");
    }
    if (!this.new_coupon.product_id) {
      return Swal.fire("エラー", "ロボットペイメントの商品コードを入力してください", "error");
    }

    Swal.fire("コース追加中");
    Swal.showLoading();
    this.api.coupon.add(this.new_coupon).toPromise().then((coupon) => {
      // this.rows.push(coupon);
      this.shown = false;
      // this.rows = null;
      // this.changes = null;
      setTimeout(() => {
        this.shown = true;
        this.ngOnInit();
      }, 100);
      this.new_coupon = {
        order : "",
        name : "",
        course_name : "",
        enable : "1",
        type : "",
        code : this.generate_random_code(),
        months : "",
        price : "",
        total_amount : "",
        product_id : "",
      };
      this.add_coupon_modal.hide();
      Swal.fire("コースを追加しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "コースの追加に失敗しました", "error")
    });
  }

  edit() {
    if (!this.edit_coupon.name) {
      return Swal.fire("エラー", "名前を入力してください", "error");
    }
    if (!this.edit_coupon.product_id) {
      return Swal.fire("エラー", "ロボットペイメントの商品コードを入力してください", "error");
    }

    Swal.fire("コース変更中");
    Swal.showLoading();
    this.api.coupon.update(this.edit_coupon).toPromise().then((coupon) => {
      // let index = this.rows.findIndex(search => coupon.id == search.id);
      // if (index == -1) { index = this.rows.length; }
      // this.rows = this.rows.slice(0, index).concat([coupon]).concat(this.rows.slice(index + 1));
      this.shown = false;
      // this.rows = null;
      // this.changes = null;
      setTimeout(() => {
        this.shown = true;
        this.ngOnInit();
      }, 100);
      this.edit_coupon_modal.hide();
      Swal.fire("コースを変更しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "コースの変更に失敗しました", "error")
    });
  }
  
  generate_random_code() {
    var S = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
    var N = 6;
    return Array.from(Array(N)).map(()=>S[Math.floor(Math.random()*S.length)]).join('');
  }

  getChangedValue(change, value) {
    //クーポンを無効にした変更履歴が取れないよー
    if (value === undefined || value == "null") {
      return "";
    }
    let field = enum_data.couponChangeFieldByValue(change.field);
    if (field.id == "staff_id") {
      return (this.api.staffs.find(staff => staff.id == value) || { name : "", }).name;
    }
    if (!field.type || field.type == "text") {
      return value;
    }
    if (field.type == "select") {
      let data = enum_data[field.data_name];
      let item = data.find(item => item.id == value);
      return item ? item.name || "" : "";
    }
    if (field.type == "multiselect") {
      try {
        if (typeof value == "string") {
          value = JSON.parse(value);
        }
      } catch (e) {}
      let data = enum_data[field.data_name];
      return value.map((val) => {
        let item = data.find(item => item.id == val);
        return item ? item.name || "" : "";
      }).join("、");
    }
    if (field.type == "child") {
      let child;
      try {
        child = JSON.parse(value);
      } catch (e) {
        child = [];
      }
      if (!child || typeof child != "object") {
        return "";
      }
      let name = child.name;
      let birth = child.birth;
      let gender = child.gender;
      let birth_string = moment(birth).format("YYYY年M月D日");
      let gender_name = enum_data.genderByValue(gender).name;
      return `${name}（${gender_name}・${birth_string}生まれ）`;
    }
    if (field.type == "memos") {
      let memos;
      try {
        memos = JSON.parse(value);
      } catch (e) {
        memos = [];
      }
      if (!memos || !memos.length) {
        return "";
      }
      return memos.map((memo) => {
        let text = memo.text;
        let date = memo.date;
        let type = memo.type;
        let date_string = moment(date).format("YYYY年M月D日");
        let type_name = enum_data.memoTypeByValue(type).name;
        return `[${date_string}][${type_name}]「${text}」`;
      }).join("\n");
    }
  }

}
