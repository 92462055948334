import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';

@Component({
  selector: 'app-purchases.list',
  templateUrl: './purchases.list.component.html',
  styleUrls: ['./purchases.list.component.scss']
})
export class PurchasesListComponent implements OnInit {

  enum_data = enum_data;

  constructor(private api : ApiService) { }

  ngOnInit(): void {
  }

}
