import { Component, Input, Output, ViewChild, OnInit, OnDestroy, SimpleChanges, SimpleChange, EventEmitter } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { DataTableDirective } from 'angular-datatables';
import { CookieStoreService } from '../services/cookie-store.service';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import * as enum_data from '../../../../api/lib/enum_data';
import * as moment from 'moment-timezone';
moment.tz.setDefault("Asia/Tokyo");

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class AppDataTableComponent implements OnDestroy, OnInit {
  
  @ViewChild(DataTableDirective, {static: false}) datatableElement: DataTableDirective;
  @ViewChild(NgForm, {static: false}) formData: NgForm;
  dtTrigger: Subject<any> = new Subject<any>();

  @Input() id : string;
  @Input() title : string;
  @Input() grid_view : boolean = false;
  @Input() list_view : boolean = true;
  @Input() rows : any[];
  @Input() columns : any[];
  @Input() customColumns : any;
  @Input() sorts : any;
  @Input() button : string = "";
  @Input() gridCell : any;
  @Input() tableExtendView : any;
  @Input() rowSelectable : boolean = false;
  @Input() grid_pagesize_default : number = 9;
  @Input() list_pagesize_default : number = 10;
  @Input() ordering : boolean = true;
  @Input() custom_search : boolean = false;
  
  @Input() default_age_min : Number = null;
  @Input() default_age_max : Number = null;
  target_age_min : Number = null;
  target_age_max : Number = null;

  @Output() onClickButton: EventEmitter<string> = new EventEmitter();
  @Output() selectRow: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  quick_search;
  quick_search_editting;
  search_conditions;
  grid_items;
  grid_page = 0;
  sort_keys = [];
  initialized = false;
  

  @Input('view_type') set view_type(value) { this.cookie.set_property(this.id, "view_type", value); }
  get view_type() { return this.cookie.get_property(this.id, "view_type", "list") }

  @Input('sort_order') set sort_order(value) { this.cookie.set_property(this.id, "sort_order", value); }
  get sort_order() { return this.cookie.get_property(this.id, "sort_order", "") }

  @Input('grid_page_size') set grid_page_size(value) { this.cookie.set_property(this.id, "grid_page_size", value); }
  get grid_page_size() { return this.cookie.get_property(this.id, "grid_page_size", this.grid_pagesize_default) }

  @Input('table_length') set table_length(value) { this.cookie.set_property(this.id, "table_length", value); }
  get table_length() { return this.cookie.get_property(this.id, "table_length", this.list_pagesize_default) }

  enum_data = enum_data;

  public keys = [];
  public searchs = [];
  public table_options = {
    //searching : false,
    order : [],
    ordering : true,
    columns : [],
    language : {
      "emptyTable": "テーブルにデータがありません",
      "info": " _TOTAL_ 件中 _START_ から _END_ まで表示",
      "infoEmpty": " 0 件中 0 から 0 まで表示",
      "infoFiltered": "（全 _MAX_ 件より抽出）",
      "infoThousands": ",",
      "lengthMenu": "_MENU_ 件表示",
      "loadingRecords": "読み込み中...",
      "processing": "処理中...",
      "search": "クイック検索:",
      "zeroRecords": "一致するレコードがありません",
      "paginate": {
          "first": "先頭",
          "last": "最終",
          "next": "次",
          "previous": "前"
      },
      "aria": {
          "sortAscending": ": 列を昇順に並べ替えるにはアクティブにする",
          "sortDescending": ": 列を降順に並べ替えるにはアクティブにする"
      }
    },
  };
  
  constructor(private cookie : CookieStoreService) { }

  ngOnInit(): void {
    this.sort_keys = Object.keys(this.sorts || {});

    setTimeout(() => {
      if (this.formData) {
        this.default_age_min = this.default_age_min ? Number(this.default_age_min) : null;
        this.formData.value.target_age_min = this.target_age_min = this.default_age_min;
        this.default_age_max = this.default_age_max ? Number(this.default_age_max) : null;
        this.formData.value.target_age_max = this.target_age_max = this.default_age_max;
      }
    }, 1000);
  }

  init() {
    this.dtTrigger.next();
    this.initialized = true;
    
    if (this.formData && !this.custom_search) {
      this.default_age_min = this.default_age_min ? Number(this.default_age_min) : null;
      this.formData.value.target_age_min = this.target_age_min = this.default_age_min;
      this.default_age_max = this.default_age_max ? Number(this.default_age_max) : null;
      this.formData.value.target_age_max = this.target_age_max = this.default_age_max;
      this.search(this.formData);
    }

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.page.len(this.table_length);
      dtInstance.on('length.dt', (e, settings, len) => {
        this.table_length = len;
      });
    });
  }
  
  ngAfterViewInit() {
    if (this.formData) {
      setTimeout(() => {
        for (let key of this.searchs) {
          if (this.columns[key].search.initial) {
            if (this.columns[key].search.type == "date") {
              if (this.columns[key].search.initial == "last_30_days") {
                this.formData.form.patchValue({[key + "_min"] : moment().add(-30, 'days').format("YYYY-MM-DD")});
              }
            }
            else {
              this.formData.form.patchValue({[key] : this.columns[key].search.initial});
            }
          }
        }
      }, 100);
    }
  }
  
  reload() {
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows && this.datatableElement) {
      this.grid_items = this.getGridItems();
      if (!this.initialized) {
        this.init();
      }
    }
    if (changes.columns) {
      this.columns = this.keys.reduce((columns, key) => {
        if (typeof columns[key] != "object") {
          columns[key] = { name : columns[key], };
        }
        return columns;
      }, this.columns)
      this.keys = Object.keys(this.columns);
      this.searchs = this.keys.filter((key) => {
        return this.columns[key].search;
      })
      if (this.formData) {
        for (let key of this.searchs) {
          if (this.columns[key].search.initial) {
            this.formData.value[key] = this.columns[key].search.initial;
          }
        }
      }
      if (!this.initialized && this.datatableElement) {
        this.init();
      }
      let sort_options = [];
      for (let i=0; i<this.keys.length; i++) {
        let order = this.columns[this.keys[i]].order;
        if (order) {
          sort_options.push([i, order]);
        }
      }
      this.table_options.order = sort_options;
      let columns = [];
      for (let i=0; i<this.keys.length*2+1; i++) {
        columns.push({orderable : this.ordering, type : this.columns[this.keys[i%this.keys.length]].datatype || "natural", });
      }
      this.table_options.columns = columns;
      if (this.keys) {
        this.keys.forEach((column) => {
        })
      }
    }
    if (changes.sorts) {
      this.sort_keys = Object.keys(changes.sorts);
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  
  getValue(value, key, default_value = "") {
    let raw_value = this.getValueRaw(value, key, default_value);
    
    if (this.columns[key].type == "select") {
      return (this.columns[key].data.find(item => item.id == raw_value) || {}).name;
    }
    if (this.columns[key].type == "boolean") {
      return raw_value ? "有効" : "無効";
    }
    if (this.columns[key].type == "multiselect") {
      let raw_values;
      if (typeof raw_value == "string") {
        raw_values = raw_value.split(",");
      }
      else if (typeof raw_value == "object" && raw_value.length !== undefined) {
        raw_values = raw_value;
      }
      else {
        raw_values = [];
      }
      return raw_values.map((value) => {
        return (this.columns[key].data.find(item => item.id == value) || {}).name;
      }).join("、");
    }
    if (this.columns[key].type == "date") {
      if (moment(raw_value).isValid()) {
        return moment(raw_value).format("Y年M月D日")
      } else {
        return "";
      }
    }
    if (this.columns[key].type == "datetime") {
      if (moment(raw_value).isValid()) {
        return moment(raw_value).format("Y年MM月DD日 HH時mm分")
      } else {
        return "";
      }
    }
    if (this.columns[key].type == "datetimesec") {
      if (moment(raw_value).isValid()) {
        return moment(raw_value).format("Y年MM月DD日 HH時mm分ss秒")
      } else {
        return "";
      }
    }
    if (this.columns[key].type == "currency") {
      let str = String(raw_value);
      if (str.length > 3) {
        str = str.slice(0, -3) + "," + str.slice(-3);
      }
      return str + "円";
    }
    if (typeof raw_value == "object") {
      raw_value = JSON.stringify(raw_value);
    }
    return raw_value;
  }

  getValueRaw(value, key, default_value = "") {
    let value_string = key.split(".").reduce((value, key) => {
      if (key == "*") {
        return value;
      }
      if (typeof value == "object") {
        if (value[key] === undefined) {
          return default_value;
        } else {
          return value[key];
        }
      } else {
        return default_value;
      }
    }, value);
    if (value_string && (typeof value_string) == "object" && value_string.length) {
      if (typeof value_string[0] == "string") {
        value_string = value_string.join(",");
      }
    }
    return value_string;
  }
  
  search(formData) {
    this.search_conditions = formData.value;
    if (this.custom_search) {
      this.onSearch.emit(this.search_conditions);
      return;
    }
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
        if (this.datatableElement["el"].nativeElement.id != settings.sInstance) {
          return true;
        }
        if (this.search_conditions) {
          for (let key of Object.keys(this.search_conditions)) {
            if (!this.search_conditions[key]) {
              continue;
            }
            if (key == 'child_name') {
              let json = data[data.length - 1];
              let value;
              try {
                if (typeof json == "string") {
                  value = JSON.parse(json);
                } else {
                  value = json;
                }
              } catch (e) {
                value = { detail : {} };
              }
              if ((value.detail.child_1.name||"").indexOf(this.search_conditions[key]) == -1
               && (value.detail.child_2.name||"").indexOf(this.search_conditions[key]) == -1
               && (value.detail.child_3.name||"").indexOf(this.search_conditions[key]) == -1
               && (value.detail.child_4.name||"").indexOf(this.search_conditions[key]) == -1
               && (value.detail.child_5.name||"").indexOf(this.search_conditions[key]) == -1
              ) {
                 return false;
              }
            }
            else if (key == 'child_gender') {
              let json = data[data.length - 1];
              let value;
              try {
                if (typeof json == "string") {
                  value = JSON.parse(json);
                } else {
                  value = json;
                }
              } catch (e) {
                value = { detail : {} };
              }
              if (value.detail.child_1.gender != this.search_conditions[key]
               && value.detail.child_2.gender != this.search_conditions[key]
               && value.detail.child_3.gender != this.search_conditions[key]
               && value.detail.child_4.gender != this.search_conditions[key]
               && value.detail.child_5.gender != this.search_conditions[key]
              ) {
                 return false;
              }
            }
            else if (key == 'child_age_min' || key == 'child_age_max') {
              let json = data[data.length - 1];
              let value;
              try {
                if (typeof json == "string") {
                  value = JSON.parse(json);
                } else {
                  value = json;
                }
              } catch (e) {
                value = { detail : {} };
              }
              let child_age_min = this.search_conditions["child_age_min"] || 0;
              let child_age_max = this.search_conditions["child_age_max"] || 2000;
              if ((!value.detail.child_1.birth || enum_data.getAgeRaw(value.detail.child_1.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_1.birth))
               && (!value.detail.child_2.birth || enum_data.getAgeRaw(value.detail.child_2.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_2.birth))
               && (!value.detail.child_3.birth || enum_data.getAgeRaw(value.detail.child_3.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_3.birth))
               && (!value.detail.child_4.birth || enum_data.getAgeRaw(value.detail.child_4.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_4.birth))
               && (!value.detail.child_5.birth || enum_data.getAgeRaw(value.detail.child_5.birth) < child_age_min || child_age_max < enum_data.getAgeRaw(value.detail.child_5.birth))
              ) {
                 return false;
              }
            }
            else if (key.indexOf("_min") != -1) {
              let data_key = key.replace("_min", "");
              if (data_key == "target_age") {
                data_key = "detail.target_age";
              }
              let index = Object.keys(this.columns).indexOf(data_key) + Object.keys(this.columns).length;
              let value = this.getValueRaw(this.rows[dataIndex], data_key);
              let column = this.columns[data_key];
              if (column.search.type == "date") {
                value = moment(value);
                let min = moment(this.search_conditions[key]);
                if (min.isAfter(value)) {
                  return false;
                }
              } else {
                value = (typeof value == "object") ? value.max : value;
                if (Number(this.search_conditions[key]) > Number(value)) {
                  return false;
                }
              }
            }
            else if (key.indexOf("_max") != -1) {
              let data_key = key.replace("_max", "");
              if (data_key == "target_age") {
                data_key = "detail.target_age";
              }
              let index = Object.keys(this.columns).indexOf(data_key) + Object.keys(this.columns).length;
              let value = this.getValueRaw(this.rows[dataIndex], data_key);
              let column = this.columns[data_key];
              if (column.search.type == "date") {
                value = moment(value);
                let max = moment(this.search_conditions[key]).endOf("day");
                if (value.isAfter(max)) {
                  return false;
                }
              } else {
                value = (typeof value == "object") ? value.min : value;
                if (Number(this.search_conditions[key]) < Number(value)) {
                  return false;
                }
              }
            }
            else {
              let value = this.getValueRaw(this.rows[dataIndex], key);
              let index = Object.keys(this.columns).indexOf(key) + Object.keys(this.columns).length;
              let column = this.columns[key];
              if (column.search.type == "multiselect") {
                if (data[index].split(",").indexOf(this.search_conditions[key]) == -1) {
                  return false;
                }
              }
              else if (column.search.type == "boolean") {
                let search_value = this.search_conditions[key] == "true";
                if (!value != !search_value) {
                  return false;
                }
              }
              else if (String(data[index]).indexOf(this.search_conditions[key]) == -1) {
                return false;
              }
            }
          }
        }
        return true;
      });
      dtInstance.draw();
      //$.fn['dataTable'].ext.search.pop();
    });

  }
  
  getGridItems() {
    return this.rows;
  }
  
  gridPageChanged(event : PageEvent) {
    let items = this.rows.concat([]);
    this.grid_page = event.pageIndex;
    this.grid_page_size = event.pageSize;
  }
  
  onSelectRow (row) {
    this.selectRow.emit(row);
  }

}
