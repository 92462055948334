import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Staff } from '../../types/staff.type';
import { StaffChange } from '../../types/staff-change.type';
import { Customer } from '../../types/customer.type';
import { Plan } from '../../types/plan.type';
import Swal from 'sweetalert2';
import _ from 'lodash'
import * as moment from 'moment';

@Component({
  selector: 'app-staffs.view',
  templateUrl: './staffs.view.component.html',
  styleUrls: ['./staffs.view.component.scss']
})
export class StaffsViewComponent implements OnInit {

  show : boolean = false;
  enum_data = enum_data;
  staff : Staff;
  changes : StaffChange[];
  properties : any = {};
  editing : boolean = false;
  plans : Plan[] = [];
  customers : Customer[] = [];

  property_rows = [
    { id : 'name', name :"名前", type : "string", placeholder : "名前を入力", },
    { id : 'email', name :"メールアドレス", type : "email", placeholder : "メールアドレスを入力", },
    { id : 'password', name :"パスワード", type : "password", placeholder : "パスワード", skip : true, },
    { id : 'password_confirm', name :"パスワード再入力", type : "password", placeholder : "パスワード再入力", skip : true, },
    { id : "grant", name : "権限", type : "select", placeholder : "選択", options : enum_data.staff_grants, },
  ];

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    forkJoin([
      this.api.staff.get(id),
      this.api.staff.customers(id),
      this.api.staff.plans(id),
    ]).subscribe((result) => {
      let customers = result[1];
      this.customers = customers;

      let staff = result[0];
      this.staff = staff;
      this.changes = staff.changes;
      this.copy_property_by_staff();

      let plans = result[2];
      this.plans = plans;
    });
  }
  
  copy_property_by_staff() {
    this.properties = this.property_rows.reduce((data, row) => {
      data[row.id] = this.staff[row.id];
      return data;
    }, {});
  }
  
  edit_start() {
    this.editing = true;
  }
  
  edit_cancel() {
    this.copy_property_by_staff();
    this.editing = false;
  }
  
  edit_save() {
    if (this.properties["password"] || this.properties["password_confirm"]) {
      if (this.properties["password"] == this.properties["password_confirm"]) {
        this.staff["password"] = this.properties["password"];
        delete this.properties["password_confirm"];
      } else {
        Swal.fire("エラー", "入力されたパスワードが一致しません", "error")
        return;
      }
    } else {
      delete this.properties["password_confirm"];
      delete this.properties["password"];
    }
    this.property_rows.forEach((row) => {
      if (row.skip) {
        return;
      }
      this.staff[row.id] = this.properties[row.id];
    });

    Swal.fire("スタッフ情報保存中");
    Swal.showLoading();
    this.api.staff.update(this.staff).toPromise().then((data) => {
      this.staff = data;
      this.changes = this.staff.changes;
      this.copy_property_by_staff();
      this.editing = false;
      Swal.fire("スタッフ情報を保存しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "スタッフ情報の保存に失敗しました", "error")
    });
  }

  getChangedValue(change, value) {
    if (!value || value == "null") {
      return "";
    }
    let field = enum_data.staffChangeFieldByValue(change.field);
    if (field.id == "staff_id") {
      return (this.api.staffs.find(staff => staff.id == value) || { name : "", }).name;
    }
    if (!field.type || field.type == "text") {
      return value;
    }
    if (field.type == "select") {
      let data = enum_data[field.data_name];
      let item = data.find(item => item.id == value);
      return item ? item.name || "" : "";
    }
    if (field.type == "multiselect") {
      try {
        if (typeof value == "string") {
          value = JSON.parse(value);
        }
      } catch (e) {}
      let data = enum_data[field.data_name];
      return value.map((val) => {
        let item = data.find(item => item.id == val);
        return item ? item.name || "" : "";
      }).join("、");
    }
    if (field.type == "child") {
      let child;
      try {
        child = JSON.parse(value);
      } catch (e) {
        child = [];
      }
      if (!child || typeof child != "object") {
        return "";
      }
      let name = child.name;
      let birth = child.birth;
      let gender = child.gender;
      let birth_string = moment(birth).format("YYYY年M月D日");
      let gender_name = enum_data.genderByValue(gender).name;
      return `${name}（${gender_name}・${birth_string}生まれ）`;
    }
    if (field.type == "memos") {
      let memos;
      try {
        memos = JSON.parse(value);
      } catch (e) {
        memos = [];
      }
      if (!memos || !memos.length) {
        return "";
      }
      return memos.map((memo) => {
        let text = memo.text;
        let date = memo.date;
        let type = memo.type;
        let date_string = moment(date).format("YYYY年M月D日");
        let type_name = enum_data.memoTypeByValue(type).name;
        return `[${date_string}][${type_name}]「${text}」`;
      }).join("\n");
    }
  }

}
