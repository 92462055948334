<ng-container *ngIf="shown">
  
  <data-table 
    id="purchase-histories.list.purchase-histories"
    title="支払履歴一覧"
    [rows]="rows"
    [custom_search]="true"
    (onSearch)="search($event)"
    [columns]="{
      'id' : { order : 'desc', hidden : true },
      'product_id' : { name : 'コース',  hidden : true, search : { type : 'select', options : enum_data.course_products } },
      'coupon_id' : { name : '使用した割引', hidden : true, search : { type : 'select', options : enum_data.coupons } },
      'customer.name' : { name : 'ユーザー名' },
      'state' : { name : '成功/失敗', type : 'select', data : enum_data.purchase_history_status, search : { type : 'select', options : enum_data.purchase_history_status } },
      'course' : { name : 'コース名', customCell : courseCell },
      'amount' : { name : '料金（税別）', type : 'currency' },
      'tax' : { name : '消費税', type : 'currency' },
      'createdAt' : { name : '課金通知日時', type : 'datetime', search : { type : 'date', initial : 'last_30_days' } },
      'action' : { name : 'アクション', customCell : actionCell }
    }"
  >
  </data-table>
  
</ng-container>

<ng-template #actionCell let-row="row" let-value="value">
  <button class="btn btn-primary" [routerLink]="['/customers', row.customer.id]">開く</button>
</ng-template>
<ng-template #courseCell let-row="row" let-value="value">
  <span class="course-name">{{row.product_name || row.coupon?.name}}</span>
</ng-template>
