<data-table
  id="plans.list.plans"
  title="プラン一覧"
  [rows]="rows"
  [custom_search]="true"
  (onSearch)="search($event)"
  [columns]="{
    'customer.name' : { name : '名前' },
    'staff.id' : { name : '担当プランナー', type : 'select', search : is_planner ? undefined : { type : 'select', options : staffs, initial : staff_id }, data : staffs, hidden : is_planner },
    'is_first' : { name : '種別', type : 'select', search : { type : 'select', options : enum_data.plan_is_firsts }, data : enum_data.plan_is_firsts, customCell : firstCell },
    'state' : { name : '最終ステータス', type : 'select', data : enum_data.plan_status, search : { type : 'select', options : enum_data.plan_status } },
    'request_date' : { name : '申請日', type : 'date', search : { type : 'date', initial : 'last_30_days' } },
    'start_date' : { name : 'レンタル開始日', type : 'date', search : { type : 'date'} },
    'exclusive' : { name : '専有タスク', type : 'boolean' },
    'action' : { name : 'アクション', customCell : actionCell },
    'customer_id' : { name : 'ユーザーID', search : { type : 'string' }, hidden : true }
  }"
>
</data-table>

<ng-template #firstCell let-row="row" let-value="value">
  <p [class]="{short:row.short_request}">{{getFirstValue(row)}}</p>
</ng-template>

<ng-template #actionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/plans/'+row.id">開く</a>
</ng-template>
