import { Component, OnInit, ViewChild, } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AppDataTableComponent } from '../../data-table/data-table.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tasks.list',
  templateUrl: './tasks.list.component.html',
  styleUrls: ['./tasks.list.component.scss']
})
export class TasksListComponent implements OnInit {

  @ViewChild(AppDataTableComponent, {static: false}) tasksDataTable: AppDataTableComponent;

  enum_data = enum_data;
  shown = true;

  public rows = [];
  public staffs = [];

  constructor(private api : ApiService) { }

  ngOnInit(): void {
    this.staffs = this.api.staffs;
    this.shown = true;
  }

  search(search_conditions) {
    Swal.fire("読込中");
    Swal.showLoading();
    let conditions = {};
    for (let key of Object.keys(search_conditions)) {
      console.log({[key]:search_conditions[key]});
      let param_name = key.replace(".", "_");
      conditions[param_name] = search_conditions[key];
    }
    this.api.task.list(conditions).toPromise().then((tasks) => {
      this.rows = tasks;
      this.tasksDataTable.reload();
      Swal.close();
    });
  }

  finishTask(task) {
    Swal.fire(`タスク完了中`);
    Swal.showLoading();

    this.api.task.finish(task.id).toPromise().then((new_task) => {
      task.state = "finish";
      this.api.tasksChanged.emit();
      Swal.fire("タスクを完了させました", ``, "success")
    }).catch((error) => {
      Swal.fire("エラー", `タスクの完了に失敗しました`, "error")
    });
  }

  deleteTask(task) {
    Swal.fire(`タスク削除中`);
    Swal.showLoading();

    this.api.task.delete(task.id).toPromise().then(() => {
      let rows = this.rows;
      const removeIndex = rows.findIndex(item => item.id === task.id);
      rows.splice(removeIndex, 1);
      this.rows = rows;
      this.tasksDataTable.reload();
      this.api.tasksChanged.emit();
      Swal.fire("タスクを削除させました", ``, "success")
    }).catch((error) => {
      console.log(error)
      Swal.fire("エラー", `タスクの削除に失敗しました`, "error")
    });
  }

}
