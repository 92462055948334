import { Injectable, EventEmitter, } from '@angular/core';
import firebase from 'firebase/app';
import "firebase/messaging";
import { CookieStoreService } from '../services/cookie-store.service';
import { ApiService } from './api.service';

var firebaseConfig = {
  apiKey: "AIzaSyBLE6LJ3gIMqRkpRwZp8y6J9Qjw_0rVs2k",
  authDomain: "toybox-60157.firebaseapp.com",
  projectId: "toybox-60157",
  storageBucket: "toybox-60157.appspot.com",
  messagingSenderId: "71134025398",
  appId: "1:71134025398:web:2fd9d146e942896d277817",
  measurementId: "G-TXVET9YHF8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  public receive_message_event : EventEmitter<any> = new EventEmitter<any>();

  messaging;
  constructor(private api : ApiService, private cookie : CookieStoreService) {
    if (firebase.messaging.isSupported()) {
    	this.messaging = firebase.messaging();
    }
  }

  public getPermission() {
    return navigator.serviceWorker.register('assets/firebase-messaging-sw.js')
      .then((registration) => {
        this.messaging.useServiceWorker(registration);
        registration.onupdatefound = function() {
          console.log('アップデートがあります！');
          registration.update();
        }
      })
      .then(() => {
        this.messaging.requestPermission();
      })
      .then(() => {
        console.log('Notification permision granted.');
        return this.messaging.getToken();
      })
      .then(token => {
        let prev_token = this.cookie.get_property(this.constructor.name, "messaging_token", "");
        return this.api.auth.registerMessagingToken(token, prev_token).toPromise().then(() => {
          this.cookie.set_property(this.constructor.name, "messaging_token", token);
        });
      })
      .catch(err => {
        console.log('Unable to get permission to notify.', err);
      });
  }
  
  public resetToken() {
    let token = this.cookie.get_property(this.constructor.name, "messaging_token", "");
    if (token) {
      this.api.auth.registerMessagingToken(token, "").toPromise().then(() => {
      });
    }
  }

  public receiveMessage() {
    this.messaging.onMessage(payload => {
      console.log('Message received. ', payload);
      navigator.serviceWorker.getRegistration('assets/firebase-messaging-sw.js')
        .then((registration) => {
          if (!payload.data) { return; }
          let { title, body, click_action, data, } = payload.data;
          
          try {
            data = JSON.parse(data);
          } catch (e) {}
          this.receive_message_event.emit(data);
          
          const notificationTitle = title;
          var notificationOptions = {
            body,
            icon : "/assets/images/icon.png",
            data: { url:click_action },
          };
          // クライアントアプリがアクティブな状態のときは、こっち側でPush通知を表示する。
          registration.showNotification(notificationTitle, notificationOptions);
          
          this.api.tasksChanged.emit();
        });
    });
  }
}
