import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { PurchaseHistory } from '../types/purchase-history.type';
import { Refund } from '../types/refund.type';
import { CustomerCharge } from '../types/customer-charge.type';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(private http: HttpClient) { }
  
  public histories(conditions = {}) : Observable<PurchaseHistory[]> {
    return this.http.get<PurchaseHistory[]>('/api/purchases/histories', { params : conditions, });
  }
  
  public refunds() : Observable<Refund[]> {
    return this.http.get<Refund[]>('/api/purchases/refunds');
  }
  
  public charges() : Observable<CustomerCharge[]> {
    return this.http.get<CustomerCharge[]>('/api/purchases/charges');
  }

}
