<div class="card toy" [ngClass]="{smart : smart, simple : simple}" *ngIf="toy">
  <div class="image">
    <img class="card-img-top" [src]="toy.main_image?.url || toy.image_url || '/assets/images/noimage.png'">
    <p class="label" [ngClass]="toy.label" *ngIf="toy.label">
      {{enum_data.toyLabelByValue(toy.label).name}}
    </p>
    <p class="label" [ngClass]="toy.state" *ngIf="toy.state">
      {{enum_data.planToyStateByValue(toy.state).name}}
    </p>
    <div class="selection custom-checkbox custom-control" *ngIf="selectable">
      <input type="checkbox" [(ngModel)]="value" class="custom-control-input" value="1">
      <label class="custom-control-label" (click)="onSelect($event)"></label>
    </div>
    <div class="hover_buttons">
      <ng-container *ngFor="let button of buttons; index as i">
        <div class="btn btn-primary" (click)="onButtonClick($event, i)">{{button}}</div>
      </ng-container>
    </div>
  </div>
  <div class="card-body">
    <h6 class="card-title maker">
      {{toy.detail.maker}}
    </h6>
    <h5 class="card-title name">
      <a [routerLink]="['/toys', toy.id]">
        {{toy.name}}
      </a>
    </h5>
    <p class="right-top-corner">
      <span class="price">
        {{toy.detail.price | currency:'JPY'}}
      </span>
      <span class="stock">
        在庫 {{toy.real_stock}}/{{toy.virtual_stock}}
      </span>
    </p>
    <div class="tags">
      <p class="genre">{{toy.detail.genre}}</p>
      <ng-container *ngFor="let tag of toy.detail.tags">
        <p class="tag">{{tag}}</p>
      </ng-container>
    </div>
    <p class="review-average">
      評価 {{toy.review_average || "なし"}}
    </p>
    <p class="partless-parts" *ngIf="toy.lost_parts && toy.lost_parts.length">
      不足パーツ
      <ng-container *ngFor="let part of toy.lost_parts">
        <p class="part">{{part.name}} : {{part.number}}個</p>
      </ng-container>
    </p>
    <p class="target-age">
      {{enum_data.ageByValue(toy.detail.target_age?.min)?.name}} - {{enum_data.ageByValue(toy.detail.target_age?.max)?.name}}
    </p>
  </div>
  <div class="overlay" *ngIf="toy.label == 'history'"></div>
  <span class="review" *ngIf="toy.review">{{toy.review.star}}</span>
</div>
