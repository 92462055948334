import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Setting } from '../types/setting.type';
import { SettingChange } from '../types/setting-change.type';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private http: HttpClient) { }

  list() : Observable<Setting[]> {
    return this.http.get<Setting[]>('/api/settings/list');
  }

  changes() : Observable<SettingChange[]> {
    return this.http.get<SettingChange[]>('/api/settings/changes');
  }

  public updates(settings) : Observable<Setting[]> {
    return this.http.put<Setting[]>('/api/settings', settings)
  }

}
