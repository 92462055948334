<!-- <div class="panel-heading">User information</div>-->

<app-card [cardTitle]="title" [hidHeader]="!title" cardClass="card-searchbox" [options]="false" *ngIf="searchs.length">
  <form #formData="ngForm">
    <div class="form-group row">
      <ng-container *ngFor="let key of searchs">
        <label class="col-sm-1 col-form-label">{{columns[key].name}}</label>
        <div class="col-sm-5" *ngIf="columns[key].search.type == 'string'">
          <input type="text" class="form-control" [name]="key" ngModel [placeholder]="columns[key].placeholder||(columns[key].name + 'を入力')">
        </div>
        <div class="col-sm-5" *ngIf="columns[key].search.type == 'select'">
          <select class="form-control" [name]="key" ngModel>
            <option value="">選択してください</option>
            <option *ngFor="let option of columns[key].search.options" [value]="option.id">
              {{option.display || option.name}}
            </option>
          </select>
        </div>
        <div class="col-sm-5" *ngIf="columns[key].search.type == 'boolean'">
          <select class="form-control" [name]="key" ngModel>
            <option value="">選択してください</option>
            <option *ngFor="let option of columns[key].search.options" [value]="option.id">
              {{option.display || option.name}}
            </option>
          </select>
        </div>
        <div class="col-sm-5" *ngIf="columns[key].search.type == 'multiselect'">
          <select class="form-control" [name]="key" ngModel>
            <option value="">選択してください</option>
            <option *ngFor="let option of columns[key].search.options" [value]="option.id">
              {{option.display || option.name}}
            </option>
          </select>
        </div>
        <ng-container *ngIf="columns[key].search.type == 'child'">
          <!--
          <div class="col-sm-5 genders">
            <label>
              <div class="custom-control custom-radio custom-control-inline gender">
                <input type="radio" id="child_gender_0" name="child_gender" value="" class="custom-control-input" ngModel>
                <label class="custom-control-label" for="child_gender_0">両方</label>
              </div>
            </label>
            <label>
              <div class="custom-control custom-radio custom-control-inline gender">
                <input type="radio" id="child_gender_1" name="child_gender" value="1" class="custom-control-input" ngModel>
                <label class="custom-control-label" for="child_gender_1">男子</label>
              </div>
            </label>
            <label>
              <div class="custom-control custom-radio custom-control-inline gender">
                <input type="radio" id="child_gender_2" name="child_gender" value="2" class="custom-control-input" ngModel>
                <label class="custom-control-label" for="child_gender_2">女子</label>
              </div>
            </label>
          </div>
          <label class="col-sm-1 col-form-label">子供の月齢</label>
          -->
          <div class="col-sm-2">
            <select class="form-control" [name]="'child_age_min'" ngModel>
              <option value="">選択</option>
              <option *ngFor="let option of enum_data.ages" [value]="option.id">
                {{option.display}}
              </option>
            </select>
          </div>
          <div class="col-sm-1 text-center" style="line-height: 45px;">
            〜
          </div>
          <div class="col-sm-2">
            <select class="form-control" [name]="'child_age_max'" ngModel>
              <option value="">選択</option>
              <option *ngFor="let option of enum_data.ages" [value]="option.id">
                {{option.display}}
              </option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngIf="columns[key].search.type == 'age'">
          <div class="col-sm-2">
            <select class="form-control" name="target_age_min" [(ngModel)]="target_age_min">
              <option value="0">選択</option>
              <option *ngFor="let option of columns[key].search.options" [value]="option.id">
                {{option.display}}
              </option>
            </select>
          </div>
          <div class="col-sm-1 text-center" style="line-height: 45px;">
            〜
          </div>
          <div class="col-sm-2">
            <select class="form-control" [name]="'target_age_max'" [(ngModel)]="target_age_max">
              <option value="0">選択</option>
              <option *ngFor="let option of columns[key].search.options" [value]="option.id">
                {{option.display}}
              </option>
            </select>
          </div>
        </ng-container>
        <ng-container *ngIf="columns[key].search.type == 'date'">
          <div class="col-sm-2">
            <input class="form-control" [name]="key + '_min'" type="date" ngModel>
          </div>
          <div class="col-sm-1 text-center" style="line-height: 45px;">
            〜
          </div>
          <div class="col-sm-2">
            <input class="form-control" [name]="key + '_max'" type="date" ngModel>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="form-group row">
      <div class="col-sm-3"></div>
      <button type="submit" class="btn btn-primary waves-effect waves-light f-center d-inline-block md-trigger col-sm-6" (click)="search(formData)"> <i class="icofont icofont-ui-search m-r-5"></i>検索
      </button>
    </div>
  </form>
</app-card>

<div class="table-title">
  <h2>{{title}}</h2>
  <ng-template [ngTemplateOutlet]="tableExtendView"></ng-template>
  <div class="btn-group btn-group-toggle list-types" ngbRadioGroup [(ngModel)]="view_type" [hidden]="!grid_view || !list_view">
    <label class="btn btn-outline-secondary list-type" ngbButtonLabel><input type="radio" ngbButton name="options" id="option2" value="grid"><i class="fa"></i></label>
    <label class="btn btn-outline-secondary list-type" ngbButtonLabel><input type="radio" ngbButton name="options" id="option3" value="list"><i class="fa"></i></label>
  </div>
  <button type="button" class="btn btn-primary mb-2" (click)="onClickButton.emit($event)" [hidden]="!button">{{button}}</button>
  <div class="clearfix"></div>
</div>

<app-card [hidHeader]="true" cardClass="card-datatable" [options]="false" [hidden]="view_type != 'grid'">
  <div class="sort_orders">
      <select class="sort_order" name="sort_order" [(ngModel)]="sort_order">
        <ng-container *ngFor="let key of sort_keys">
          <option class="sort_order_option" [value]="key + '_asc'">{{sorts[key]}}順（昇順）</option>
          <option class="sort_order_option" [value]="key + '_desc'">{{sorts[key]}}順（降順）</option>
        </ng-container>
      </select>
  </div>
  <div class="quick_search">
    <span class="label">クイック検索:</span>
    <input type="text" name="quick_search_editting" [(ngModel)]="quick_search_editting" placeholder="検索ワードを入力">
    <button type="button" class="btn btn-primary mb-2" (click)="quick_search = quick_search_editting">検索</button>
  </div>
  <div class="row">
    <div class="col-6 col-sm-6 col-md-4 col-lg-3" *ngFor="let item of grid_items | quick_search : quick_search | custom_search : search_conditions | property_sort : sort_order | slice : grid_page * grid_page_size : grid_page * grid_page_size + grid_page_size">
      <ng-template [ngTemplateOutlet]="gridCell" [ngTemplateOutletContext]="{'row' : item}"></ng-template>
    </div>
  </div>
  <mat-paginator
    [length]="(grid_items | quick_search : quick_search | custom_search : search_conditions)?.length"
    [pageSize]="grid_page_size"
    [pageIndex]="grid_page"
    [pageSizeOptions]="[3, 9, 30, 90, 300]"
    (page)="gridPageChanged($event)"
  ></mat-paginator>
</app-card>

<app-card [hidHeader]="true" cardClass="card-datatable" [options]="false" [hidden]="view_type != 'list'">
<div class="table-responsive">
  <table datatable="ng" class="table table-bordered full-data-table dataTable" [dtOptions]="table_options" [dtTrigger]="dtTrigger" [class]="{rowSelectable:rowSelectable}">
    <thead>
    <tr>
      <th *ngFor="let column of keys" [hidden]="columns[column].hidden">
        <mfDefaultSorter by="{{column}}">{{columns[column].name}}</mfDefaultSorter>
      </th>
      <th *ngFor="let column of keys" hidden=1>
        <mfDefaultSorter by="{{column}}">{{columns[column].name}}</mfDefaultSorter>
      </th>
      <th hidden=1>
        json
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of rows">
      <td *ngFor="let column of keys" [class]="columns[column].class" [hidden]="columns[column].hidden" (click)="onSelectRow(item)">
        <div [hidden]="columns[column].customCell">{{getValue(item, column)}}</div>
        <ng-template *ngIf="columns[column].customCell" [ngTemplateOutlet]="columns[column].customCell" [ngTemplateOutletContext]="{'row' : item, 'value' : getValueRaw(item, column)}"></ng-template>
      </td>
      <td *ngFor="let column of keys" hidden=1>
        {{getValueRaw(item, column)}}
      </td>
      <td hidden=1>
        {{item | json}}
      </td>
    </tr>
    </tbody>
  </table>
</div>
</app-card>

