import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Coupon } from '../types/coupon.type';
import { CouponChange } from '../types/coupon-change.type';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private http: HttpClient) { }

  list() : Observable<Coupon[]> {
    return this.http.get<Coupon[]>('/api/coupons/list');
  }

  changes() : Observable<CouponChange[]> {
    return this.http.get<CouponChange[]>('/api/coupons/changes');
  }

  public get(coupon_id) : Observable<Coupon> {
    return this.http.get<Coupon>('/api/coupons/' + coupon_id);
  }

  public add(coupon) : Observable<Coupon> {
    return this.http.post<Coupon>('/api/coupons/', coupon)
  }

  public update(coupon) : Observable<Coupon> {
    return this.http.put<Coupon>('/api/coupons/' + coupon.id, coupon)
  }

}
