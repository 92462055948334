import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Plan } from '../../types/plan.type';
import Swal from 'sweetalert2';
import _ from 'lodash'
import * as moment from 'moment';

@Component({
  selector: 'app-ships.sheet',
  templateUrl: './ships.sheet.component.html',
  styleUrls: ['./ships.sheet.component.scss']
})
export class ShipsSheetComponent implements OnInit {

  enum_data = enum_data;
  plan : Plan;
  toys = [];

  toys_histories = [];

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    forkJoin([
      this.api.plan.get(id),
      this.api.plan.print(id),
    ]).toPromise().then((result) => {
      let plan = result[0];
      this.plan = plan;
      this.plan.toys = this.plan.toys.filter(toy => ["fix", "prepare", "extend",].indexOf(toy.state) != -1)
    });
  }

}
