import { Component, OnInit, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AppDataTableComponent } from '../../data-table/data-table.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-toys.list',
  templateUrl: './toys.list.component.html',
  styleUrls: ['./toys.list.component.scss']
})
export class ToysListComponent implements OnInit {

  @ViewChild(AppDataTableComponent, {static: false}) toysDataTable: AppDataTableComponent;
  enum_data = enum_data;
  
  public rows = [];

  constructor(private router : Router, public api : ApiService) { }

  ngOnInit(): void {
  }

  search(search_conditions) {
    Swal.fire("読込中");
    Swal.showLoading();
    let conditions = {};
    for (let key of Object.keys(search_conditions)) {
      console.log({[key]:search_conditions[key]});
      let param_name = key.replace(".", "_");
      conditions[param_name] = search_conditions[key];
    }
    this.api.toy.list(conditions).toPromise().then((toy) => {
      this.rows = toy;
      this.toysDataTable.reload();
      Swal.close();
    });
  }
  
  openToy(event, toy) {
    if (event.ctrlKey || event.metaKey || event.shiftKey) {
      window.open(`/toys/${toy.id}`, '_blank');
    } else {
      this.router.navigate(["/", "toys", toy.id]);
    }
  }
  
  clickAdd(event) {
    this.api.toy.add().toPromise().then((data) => {
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        window.open(`/toys/${data.id}`, '_blank');
      } else {
        this.router.navigate(["/", "toys", data.id]);
      }
    })
  }

}
