import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Staff } from '../types/staff.type';
import { Customer } from '../types/customer.type';
import { Plan } from '../types/plan.type';
import { Toy } from '../types/toy.type';
import { Talk } from '../types/talk.type';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private http: HttpClient) { }

  list() : Observable<Staff[]> {
    return this.http.get<Staff[]>('/api/staffs/list');
  }

  public get(staff_id) : Observable<Staff> {
    return this.http.get<Staff>('/api/staffs/' + staff_id);
  }

  public add(staff) : Observable<Staff> {
    return this.http.post<Staff>('/api/staffs/', staff)
  }

  public update(staff) : Observable<Staff> {
    return this.http.put<Staff>('/api/staffs/' + staff.id, staff)
  }

  public plans(staff_id, status = []) : Observable<Plan[]> {
    return this.http.get<Plan[]>('/api/staffs/' + staff_id + '/plans/list?status=' + status.join(","));
  }
  
  public customers(staff_id, status = []) : Observable<Customer[]> {
    return this.http.get<Customer[]>('/api/staffs/' + staff_id + '/customers/list?status=' + status.join(","));
  }

}
