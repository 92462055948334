import { Component, OnInit, OnDestroy, Input, SimpleChanges, SimpleChange, ElementRef, ViewChild, } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UiModalComponent } from '../theme/shared/components/modal/ui-modal/ui-modal.component';
import { MessagingService } from '../services/messaging.service';
import { ApiService } from '../services/api.service';
import { CookieStoreService } from '../services/cookie-store.service';
import { Customer } from '../types/customer.type';
import { Plan } from '../types/plan.type';
import { Talk } from '../types/talk.type';
import { Coupon } from '../types/coupon.type';
import * as enum_data from '../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  
  @ViewChild("chats") chats : ElementRef;
  @ViewChild("dragDropArea") dragDropArea : ElementRef;
  @ViewChild("fileInput") fileInput : ElementRef;
  @ViewChild("instant_purchase_modal") instant_purchase_modal : UiModalComponent;

  @Input() customer : Customer;
  @Input() plan : Plan;
  course : Coupon;
  talks : Talk[] = [];
  text_message = "";
  messaging_subscription : Subscription;
  enum_data = enum_data;
  instant_purchase_amount;
  instant_purchase_message;
  image_file;
  
  preview_image = "";
  
  @Input('send_time_span') set send_time_span(value) { this.ngOnChanges(this.cookie.set_property(this.constructor.name, "send_time_span", value)); }
  get send_time_span() { return this.cookie.get_property(this.constructor.name, "send_time_span", "") }
  
  constructor(private router : Router, private cookie : CookieStoreService, private messaging : MessagingService, private api : ApiService) { }

  ngOnInit(): void {
    this.requestTalks();
    
    this.instant_purchase_amount = "";
    this.instant_purchase_message = "ご請求"
    
    this.messaging_subscription = this.messaging.receive_message_event.subscribe((data: any) => {
      console.log({data});
      if (data.type == "talk" && data.talk.customer_id == this.customer.id) {
        this.talks.push(data.talk);
        setTimeout(() => {
          this.chats.nativeElement.scrollTop = this.chats.nativeElement.scrollHeight;
        }, 100);
      }
    })
    
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.customer && changes.customer.currentValue) {
      this.requestTalks();
      
      this.api.coupon.list().toPromise().then((coupons) => {
        this.course = coupons.find(coupon => coupon.product_id == this.customer.course_product_id);
      })
    }
  }
  
  ngOnDestroy() {
    if (this.messaging_subscription) {
      this.messaging_subscription.unsubscribe();
    }
  }
  
  requestTalks() {
    if (!this.customer) {
      return;
    }
    this.api.customer.talks(this.customer.id).toPromise().then((talks) => {
      this.talks = talks;
      setTimeout(() => {
        this.chats.nativeElement.scrollTop = this.chats.nativeElement.scrollHeight;
      }, 100);
      return this.api.task.check_customer_chat(this.customer.id).toPromise();
    }).then((tasks) => {
      this.api.tasksChanged.emit();
    });
  }
  
  getSendTimeString() {
    let time_span = this.send_time_span || { start : 8, end : 20, };
    
    let time_span_string = `${time_span.start}時〜${time_span.end}時`;
    time_span_string = time_span_string.replace(/[A-Za-z0-9]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
    });
    return time_span_string;
  }

  photoPreview(event, f = null) {
    var file = f;
    if(file === null){
        file = event.target.files[0];
    }
    var reader = new FileReader();
    var preview = document.getElementById("previewArea");
    var previewImage = document.getElementById("previewImage");
  
    if(previewImage != null) {
      preview.removeChild(previewImage);
    }
    reader.onload = (event) => {
      this.preview_image = String(reader.result);
    };
  
    reader.readAsDataURL(file);
  }
  
  send_text() {
    let sendAt = null;
    if (moment().hours() >= this.send_time_span.end) {
      sendAt = moment().add(1, 'days').hours(this.send_time_span.start).startOf('hour').toISOString();
    } else if (moment().hours() < this.send_time_span.start) {
      sendAt = moment().hours(this.send_time_span.start).startOf('hour').toISOString();
    }
    let method_name = sendAt ? "送信予約" : "送信";

    Swal.fire(`メッセージ${method_name}中`);
    Swal.showLoading();
    this.api.customer.sendText(this.customer.id, this.text_message, sendAt).toPromise().then((talk) => {
      this.talks.push(talk);
      this.text_message = "";
      setTimeout(() => {
        this.chats.nativeElement.scrollTop = this.chats.nativeElement.scrollHeight;
      }, 100);
      Swal.fire(`メッセージをユーザーに${method_name}しました`, "", "success")
      this.api.tasksChanged.emit();
    }).catch((error) => {
      Swal.fire("エラー", `メッセージの${method_name}に失敗しました`, "error")
    });
  }
  
  send_image(dialog) {
    let sendAt = null;
    if (moment().hours() >= this.send_time_span.end) {
      sendAt = moment().add(1, 'days').hours(this.send_time_span.start).startOf('hour').toISOString();
    } else if (moment().hours() < this.send_time_span.start) {
      sendAt = moment().hours(this.send_time_span.start).startOf('hour').toISOString();
    }
    let method_name = sendAt ? "送信予約" : "送信";
    

    Swal.fire(`画像${method_name}中`);
    Swal.showLoading();
    this.api.customer.sendImage(this.customer.id, this.preview_image, sendAt).toPromise().then((talk) => {
      this.talks.push(talk);
      setTimeout(() => {
        this.chats.nativeElement.scrollTop = this.chats.nativeElement.scrollHeight;
      }, 100);
      dialog.hide();
      Swal.fire(`画像をユーザーに${method_name}しました`, "", "success")
      this.api.tasksChanged.emit();
    }).catch((error) => {
      Swal.fire("エラー", `画像の${method_name}に失敗しました`, "error")
    });
  }
  
  send_unfix_plan() {
    let sendAt = null;
    if (moment().hours() >= this.send_time_span.end) {
      sendAt = moment().add(1, 'days').hours(this.send_time_span.start).startOf('hour').toISOString();
    } else if (moment().hours() < this.send_time_span.start) {
      sendAt = moment().hours(this.send_time_span.start).startOf('hour').toISOString();
    }
    let method_name = sendAt ? "送信予約" : "送信";
    
    let getting_plan = this.plan
                        ? this.api.plan.get(this.plan.id).toPromise()
                        : this.api.customer.plans(this.customer.id, ["request","planning"]).toPromise().then((plans) => {
                          return plans.shift();
                        });

    Swal.fire(`確認メッセージ${method_name}中`);
    Swal.showLoading();
    getting_plan.then((plan) => {
      if (!plan) {
        return Swal.fire("エラー", "現在提案中のプランがありませんでした", "error");
      }
      if (!plan.toys.find(toy => toy.state == 'unfix' || toy.state == 'extending')) {
        return Swal.fire("エラー", "現在未確定のおもちゃがありませんでした", "error");
      }
      
      Swal.fire({
        icon : "warning",
        title : `未確定おもちゃの確認メッセージを${method_name}する`,
        text : plan.toys.filter(toy => toy.state == 'unfix' || toy.state == 'extending').map(toy => `「${toy.name}」`).join('') + `の確認メッセージを${method_name}しますか？`,
        confirmButtonText : `${method_name}する`,
        cancelButtonText : "キャンセル",
        showCancelButton: true,
      }).then(result => {
        if (!result.isConfirmed) {
          return;
        }
        Swal.fire(`確認メッセージを${method_name}中`);
        Swal.showLoading();
        
        this.api.customer.sendPlan(this.customer.id, plan.id, "unfix", sendAt).toPromise().then(() => {
          Swal.fire(`確認メッセージを${method_name}しました`, "", "success").then(() => {
            this.ngOnInit();
            this.api.tasksChanged.emit();
          })
        }, (error) => {
          Swal.fire("エラー", `確認メッセージの${method_name}に失敗しました`, "error")
        })
      });
    })
  }
  
  send_fix_plan() {
    let sendAt = null;
    if (moment().hours() >= this.send_time_span.end) {
      sendAt = moment().add(1, 'days').hours(this.send_time_span.start).startOf('hour').toISOString();
    } else if (moment().hours() < this.send_time_span.start) {
      sendAt = moment().hours(this.send_time_span.start).startOf('hour').toISOString();
    }
    let method_name = sendAt ? "送信予約" : "送信";

    Swal.fire(`確定済みおもちゃ${method_name}中`);
    Swal.showLoading();
    this.api.plan.get(this.plan.id).toPromise().then((plan) => {
      if (!plan) {
        return Swal.fire("エラー", "プランがありませんでした", "error");
      }
      
      let toys = plan.toys.filter(toy => ['fix','prepare','rental'].indexOf(toy.state) != -1);
      if (!toys.length) {
        return Swal.fire("エラー", "現在確定済み・発送準備中・レンタル中のおもちゃがありませんでした", "error");
      }
      
      Swal.fire({
        icon : "warning",
        title : "確定済みおもちゃのメッセージを送信する",
        text : toys.map(toy => `「${toy.name}」`).join('') + `のメッセージを${method_name}しますか？`,
        confirmButtonText : `${method_name}する`,
        cancelButtonText : "キャンセル",
        showCancelButton: true,
      }).then(result => {
        if (!result.isConfirmed) {
          return;
        }
        Swal.fire(`おもちゃメッセージを${method_name}中`);
        Swal.showLoading();
        
        this.api.customer.sendPlan(this.customer.id, plan.id, "fix", sendAt).toPromise().then(() => {
          Swal.fire(`おもちゃメッセージを${method_name}しました`, "", "success").then(() => {
            this.ngOnInit();
            this.api.tasksChanged.emit();
          })
        }, (error) => {
          Swal.fire("エラー", `おもちゃメッセージの${method_name}に失敗しました`, "error")
        })
      });
    })
  }

  changeSendTimeSpan() {
    let time_span = this.send_time_span || { start : 8, end : 20, };
    Swal.fire({
      title: '送信時間',
      html:
        `<p>メッセージはこの送信時間内のみ送られます。時間外に送信しようとしたメッセージは、次の日まで送信待ちになります。</p>` +
        `<p>この設定を変更しても、すでに送信待ちになっているメッセージの送信時間は変更されません。</p>` +
        '<select id="swal-input1" class="swal2-input" style="width : 40%">' +
        `  <option value="0" ${time_span.start == 0 ? "selected" : ""}>０時</option>` + 
        `  <option value="1" ${time_span.start == 1 ? "selected" : ""}>１時</option>` + 
        `  <option value="2" ${time_span.start == 2 ? "selected" : ""}>２時</option>` + 
        `  <option value="3" ${time_span.start == 3 ? "selected" : ""}>３時</option>` + 
        `  <option value="4" ${time_span.start == 4 ? "selected" : ""}>４時</option>` + 
        `  <option value="5" ${time_span.start == 5 ? "selected" : ""}>５時</option>` + 
        `  <option value="6" ${time_span.start == 6 ? "selected" : ""}>６時</option>` + 
        `  <option value="7" ${time_span.start == 7 ? "selected" : ""}>７時</option>` + 
        `  <option value="8" ${time_span.start == 8 ? "selected" : ""}>８時</option>` + 
        `  <option value="9" ${time_span.start == 9 ? "selected" : ""}>９時</option>` + 
        `  <option value="10" ${time_span.start == 10 ? "selected" : ""}>１０時</option>` + 
        `  <option value="11" ${time_span.start == 11 ? "selected" : ""}>１１時</option>` + 
        `  <option value="12" ${time_span.start == 12 ? "selected" : ""}>１２時</option>` + 
        `  <option value="13" ${time_span.start == 13 ? "selected" : ""}>１３時</option>` + 
        `  <option value="14" ${time_span.start == 14 ? "selected" : ""}>１４時</option>` + 
        `  <option value="15" ${time_span.start == 15 ? "selected" : ""}>１５時</option>` + 
        `  <option value="16" ${time_span.start == 16 ? "selected" : ""}>１６時</option>` + 
        `  <option value="17" ${time_span.start == 17 ? "selected" : ""}>１７時</option>` + 
        `  <option value="18" ${time_span.start == 18 ? "selected" : ""}>１８時</option>` + 
        `  <option value="19" ${time_span.start == 19 ? "selected" : ""}>１９時</option>` + 
        `  <option value="20" ${time_span.start == 20 ? "selected" : ""}>２０時</option>` + 
        `  <option value="21" ${time_span.start == 21 ? "selected" : ""}>２１時</option>` + 
        `  <option value="22" ${time_span.start == 22 ? "selected" : ""}>２２時</option>` + 
        `  <option value="23" ${time_span.start == 23 ? "selected" : ""}>２３時</option>` + 
        '</select>' +
        '<span style="width : 20%; text-align : center; margin: 10px">〜</span>' +
        '<select id="swal-input2" class="swal2-input" style="width : 40%">' +
        `  <option value="0" ${time_span.end == 0 ? "selected" : ""}>０時</option>` + 
        `  <option value="1" ${time_span.end == 1 ? "selected" : ""}>１時</option>` + 
        `  <option value="2" ${time_span.end == 2 ? "selected" : ""}>２時</option>` + 
        `  <option value="3" ${time_span.end == 3 ? "selected" : ""}>３時</option>` + 
        `  <option value="4" ${time_span.end == 4 ? "selected" : ""}>４時</option>` + 
        `  <option value="5" ${time_span.end == 5 ? "selected" : ""}>５時</option>` + 
        `  <option value="6" ${time_span.end == 6 ? "selected" : ""}>６時</option>` + 
        `  <option value="7" ${time_span.end == 7 ? "selected" : ""}>７時</option>` + 
        `  <option value="8" ${time_span.end == 8 ? "selected" : ""}>８時</option>` + 
        `  <option value="9" ${time_span.end == 9 ? "selected" : ""}>９時</option>` + 
        `  <option value="10" ${time_span.end == 10 ? "selected" : ""}>１０時</option>` + 
        `  <option value="11" ${time_span.end == 11 ? "selected" : ""}>１１時</option>` + 
        `  <option value="12" ${time_span.end == 12 ? "selected" : ""}>１２時</option>` + 
        `  <option value="13" ${time_span.end == 13 ? "selected" : ""}>１３時</option>` + 
        `  <option value="14" ${time_span.end == 14 ? "selected" : ""}>１４時</option>` + 
        `  <option value="15" ${time_span.end == 15 ? "selected" : ""}>１５時</option>` + 
        `  <option value="16" ${time_span.end == 16 ? "selected" : ""}>１６時</option>` + 
        `  <option value="17" ${time_span.end == 17 ? "selected" : ""}>１７時</option>` + 
        `  <option value="18" ${time_span.end == 18 ? "selected" : ""}>１８時</option>` + 
        `  <option value="19" ${time_span.end == 19 ? "selected" : ""}>１９時</option>` + 
        `  <option value="20" ${time_span.end == 20 ? "selected" : ""}>２０時</option>` + 
        `  <option value="21" ${time_span.end == 21 ? "selected" : ""}>２１時</option>` + 
        `  <option value="22" ${time_span.end == 22 ? "selected" : ""}>２２時</option>` + 
        `  <option value="23" ${time_span.end == 23 ? "selected" : ""}>２３時</option>` + 
        '</select>',
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById('swal-input1')["value"],
          document.getElementById('swal-input2')["value"],
        ]
      }
    }).then((formValues) => {
      if (formValues.value) {
        this.send_time_span = { start : formValues.value[0], end : formValues.value[1], };
      }
    })
  }
  
  sendInstantPurchase() {
    let sendAt = null;
    if (moment().hours() >= this.send_time_span.end) {
      sendAt = moment().add(1, 'days').hours(this.send_time_span.start).startOf('hour').toISOString();
    } else if (moment().hours() < this.send_time_span.start) {
      sendAt = moment().hours(this.send_time_span.start).startOf('hour').toISOString();
    }
    let method_name = sendAt ? "送信予約" : "送信";

    Swal.fire(`決済フォーム${method_name}中`);
    Swal.showLoading();
    
    if (isNaN(this.instant_purchase_amount)) {
      return Swal.fire("エラー", "金額を正しく入力してください", "error");
    }
    if (!this.instant_purchase_message) {
      return Swal.fire("エラー", "メッセージを正しく入力してください", "error");
    }
    
    this.api.customer.sendInstantPurchase(this.customer.id, this.instant_purchase_amount, this.instant_purchase_message, sendAt).toPromise().then(() => {
      this.instant_purchase_modal.hide();
      Swal.fire(`決済フォームを${method_name}しました`, "", "success").then(() => {
        this.ngOnInit();
        this.api.tasksChanged.emit();
      })
    }, (error) => {
      Swal.fire("エラー", `決済フォームの${method_name}に失敗しました`, "error")
    })
  }
  
  cancelTalk(event, talk) {
    Swal.fire({
      icon : "warning",
      title : "予約メッセージの中止",
      text : `送信予定のメッセージを中止しますか？`,
      confirmButtonText : "中止する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("予約メッセージの中止中");
      Swal.showLoading();
      
      this.api.customer.cancelTalk(this.customer.id, talk.id).toPromise().then(() => {
        Swal.fire("予約メッセージを中止しました", "", "success").then(() => {
          this.ngOnInit();
        })
      }, (error) => {
        Swal.fire("エラー", "予約メッセージの中止に失敗しました", "error")
      })
    });
    event.preventDefault();
    event.stopPropagation();

    return false;
  }
  
    checkUnread() {
    Swal.fire("通信中");
    Swal.showLoading();
    this.api.customer.checkUnreadChat(this.customer.id).toPromise().then((string) => {
      this.customer.unchecks.unread = 0;
      this.customer.unchecks.is_unread = 0;
      Swal.fire("既読への設定が完了しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "既読への設定に失敗しました", "error")
    })
  }

  checkUnreply() {
    Swal.fire("通信中");
    Swal.showLoading();
    this.api.customer.checkUnreplyChat(this.customer.id).toPromise().then((string) => {
      this.customer.unchecks.unreply = 0;
      this.customer.unchecks.is_unreply = 0;
      Swal.fire("返信済みへの設定が完了しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "返信済みへの設定に失敗しました", "error")
    })
  }

  
}
