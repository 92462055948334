import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Customer } from '../../types/customer.type';
import { Plan } from '../../types/plan.type';
import Swal from 'sweetalert2';
import _ from 'lodash'
import * as moment from 'moment';

@Component({
  selector: 'app-backs.view',
  templateUrl: './backs.view.component.html',
  styleUrls: ['./backs.view.component.scss']
})
export class BacksViewComponent implements OnInit {

  show : boolean = false;
  enum_data = enum_data;
  plan : Plan;
  properties : any = {};
  editing : boolean = false;

  customer_property_rows_1 : any[] = [
    { id : 'id', name :"ID", type : "string", placeholder : "IDを入力", },
    { id : 'staff_id', name :"担当プランナー", type : "select", placeholder : "プランナーを選択", options : [], },
    { id : 'name', name :"名前", type : "string", placeholder : "名前を入力", },
    { id : "shipping_name", name : "郵送先お名前", type : "string", placeholder : "例）山田花子", detail : true, },
    { id : "shipping_tel", name : "郵送先電話番号", type : "tel", placeholder : "例）031234-5678（ハイフン無し半角数字）", detail : true, },
    { id : 'shipping_zipcode', name :"郵送先郵便番号", type : "zipcode", placeholder : "名前を入力", detail : true, prefix : "〒" },
    { id : 'shipping_address1', name :"郵送先住所（番地まで）", type : "string", placeholder : "名前を入力", detail : true, },
    { id : 'shipping_address2', name :"郵送先住所（マンション名・部屋番号等）", type : "string", placeholder : "名前を入力", detail : true, },
  ];
  customer_property_rows_2 : any[] = [
    { id : 'last_start_date', name :"交換日", type : "date", },
    { id : 'next_purchase_date', name :"次回決済日", type : "date", },
  ];
  customer_property_rows_3 : any[] = [
    { id : 'tags', name :"タグ", type : "tags", placeholder : "タグを選択", detail : true, options : enum_data.back_tags, },
    { id : 'memos', name :"メモ", type : "memos", detail : true, },
  ];

  constructor(private api : ApiService, private router : Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    this.customer_property_rows_1.find(row => row.id == "staff_id").options = this.api.staffs.map((staff) => { return { name : staff.name, value : String(staff.id), }; });
    forkJoin([
      this.api.plan.get_back(id),
      this.api.task.check_plan(id),
    ]).subscribe((result) => {
      this.api.tasksChanged.emit();

      let plan = result[0];
      this.plan = plan;
      for (let toy of this.plan.toys) {
        if (!toy.lost_parts || !toy.lost_parts.length) {
          toy.lost_parts = [{}];
        }
      }

      this.copy_property_by_customer();
    });
  }

  copy_property_by_customer() {
    this.properties = this.customer_property_rows_1.concat(this.customer_property_rows_2).concat(this.customer_property_rows_3).reduce((data, row) => {
      if (row.detail && typeof this.plan.customer.detail[row.id] == "object" && this.plan.customer.detail[row.id].length === undefined) {
        data[row.id] = {};
        for (let key of Object.keys(this.plan.customer.detail[row.id])) {
          data[row.id][key] = this.plan.customer.detail[row.id][key];
        }
      } else {
        data[row.id] = row.detail ? this.plan.customer.detail[row.id] : this.plan.customer[row.id];
      }
      return data;
    }, {});
  }

  edit_start() {
    this.editing = true;
  }

  edit_cancel() {
    this.copy_property_by_customer();
    this.editing = false;
  }

  edit_save() {
    this.customer_property_rows_1.concat(this.customer_property_rows_2).concat(this.customer_property_rows_3).forEach((row) => {
      if (row.detail && typeof this.properties[row.id] == "object" && this.properties[row.id].length === undefined) {
        this.plan.customer.detail[row.id] = {};
        for (let key of Object.keys(this.properties[row.id])) {
          this.plan.customer.detail[row.id][key] = this.properties[row.id][key];
        }
      } else {
        if (row.detail) {
          this.plan.customer.detail[row.id] = this.properties[row.id];
        } else {
          this.plan.customer[row.id] = this.properties[row.id];
        }
      }
    });
    console.log({customer:this.plan.customer});

    Swal.fire("ユーザー情報保存中");
    Swal.showLoading();
    this.api.customer.update(this.plan.customer).toPromise().then((data) => {
      this.plan.customer = data;
      this.copy_property_by_customer();
      this.editing = false;
      Swal.fire("ユーザー情報を保存しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "ユーザー情報の保存に失敗しました", "error")
    });
  }

  back(toy) {
    Swal.fire({
      icon : "warning",
      title : "返却完了しますか",
      text : `パーツ不足はありませんか。返却完了にすると実在庫数カウントが回復します。`,
      confirmButtonText : "返却完了する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("返却完了中");
      Swal.showLoading();

      this.api.plan.update_toy(this.plan.id, toy.plan_toy_id, { state : "backed", }).subscribe((result) => {
        toy.state = result.state;
        toy.real_stock = result.real_stock;
        Swal.fire("返却完了しました", "", "success")
      }, (error) => {
        Swal.fire("エラー", "返却の完了に失敗しました", "error")
      })
    });
  }

  add_part(parts) {
    if (!parts) {
      parts = [];
    }
    parts.push({name : "", number : ""});
  }

  remove_part(parts, index) {
    parts.splice(index, 1);
  }

  save_parts(toy) {
    Swal.fire("パーツ不足の保存中");
    Swal.showLoading();
    this.api.plan.update_toy(this.plan.id, toy.plan_toy_id, { lost_parts : toy.lost_parts }).subscribe((result) => {
      Swal.close();
    }, (error) => {
      Swal.fire("エラー", "パーツ不足の保存に失敗しました", "error")
    })
  }

  submit_back() {
    Swal.fire({
      icon : "warning",
      title : "返却確認作業完了しますか",
      text : `パーツ不足はありませんか。返却完了していないおもちゃも返却済みになり、このタスクは終了します。タスクが終了するとパーツ不足の連絡ができなくなります。`,
      confirmButtonText : "返却確認作業完了する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("返却確認作業完了中");
      Swal.showLoading();

      this.api.plan.back(this.plan.id).subscribe((result) => {
        Swal.fire("返却確認作業完了しました", "", "success").then(() => {
          this.router.navigate(["/backs"]);
        });
      }, (error) => {
        Swal.fire("エラー", "返却確認作業の完了に失敗しました", "error")
      })
    });
  }

  submit_lostparts() {
    Swal.fire({
      icon : "warning",
      title : "パーツ不足の確定",
      text : `入力されている以外のパーツ不足はありませんか。パーツ不足の確定を行うと、このタスクは終了し、プランナーからパーツ不足の連絡が行われます。タスクが終了すると追加のパーツ不足の連絡はできなくなり、パーツ不足のないおもちゃは自動的に返却完了状態になります。`,
      confirmButtonText : "パーツ不足を確定する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("パーツ不足確定中");
      Swal.showLoading();

      this.api.plan.lost(this.plan.id).subscribe((result) => {
        Swal.fire("パーツ不足の確定が完了しました", "", "success").then(() => {
          this.router.navigate(["/backs"]);
        });
      }, (error) => {
        Swal.fire("エラー", "パーツ不足の確定に失敗しました", "error")
      })
    });
  }

}
