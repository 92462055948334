<div class="chat-card card">
  <div class="card-header" [class]="{'header-with-image':customer?.icon_url}">
    <div class="header-left">
      <h4><a [routerLink]="['/customers', customer?.id]">{{customer?.name}}</a></h4>
      <ng-container *ngIf="customer?.new_customer">
        <h6><a [routerLink]="['/customers', customer?.new_customer.id]">再入会：{{customer?.new_customer.name || "未登録ユーザー"}}</a></h6>
      </ng-container>
      <ng-container *ngFor="let old_customer of customer?.old_customers">
        <h6><a [routerLink]="['/customers', old_customer?.id]">前ユーザー：{{old_customer?.name}}</a></h6>
      </ng-container>
      <span>{{course?.name}}</span>
      
      <ng-container *ngIf="customer">
        <div class="child" *ngIf="customer.detail.child_1">
          <span class="name">{{customer.detail.child_1.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + customer.detail.child_1.gender">{{enum_data.genderByValue(customer.detail.child_1.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(customer.detail.child_1.birth)}}</span>
          ）
        </div>
        <div class="child" *ngIf="customer.detail.child_2">
          <span class="name">{{customer.detail.child_2.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + customer.detail.child_2.gender">{{enum_data.genderByValue(customer.detail.child_2.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(customer.detail.child_2.birth)}}</span>
          ）
        </div>
        <div class="child" *ngIf="customer.detail.child_3">
          <span class="name">{{customer.detail.child_3.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + customer.detail.child_3.gender">{{enum_data.genderByValue(customer.detail.child_3.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(customer.detail.child_3.birth)}}</span>
          ）
        </div>
        <div class="child" *ngIf="customer.detail.child_4">
          <span class="name">{{customer.detail.child_4.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + customer.detail.child_4.gender">{{enum_data.genderByValue(customer.detail.child_4.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(customer.detail.child_4.birth)}}</span>
          ）
        </div>
        <div class="child" *ngIf="customer.detail.child_5">
          <span class="name">{{customer.detail.child_5.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + customer.detail.child_5.gender">{{enum_data.genderByValue(customer.detail.child_5.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(customer.detail.child_5.birth)}}</span>
          ）
        </div>
      </ng-container>
    </div>
    <div class="profile-image" *ngIf="customer && customer.icon_url">
      <img [src]="customer.icon_url" alt="user image" class="img-radius wid-100">
    </div>
  </div>
  <div class="card-body" #chats>
    
    <ng-container *ngFor="let talk of talks" [ngSwitch]="talk.sender">
      <!-- システムのメッセージ -->
      <div *ngSwitchCase="'system'" class="row system-chat">
        <div class="col" [ngSwitch]="talk.message.type">
          <!-- 通常のテキストメッセージ -->
          <div class="msg" *ngSwitchCase="'text'">
            <p class="m-b-0">{{talk.message.text}}</p>
          </div>
          <div class="msg" *ngSwitchCase="'image'">
            <a [href]="talk.message.originalContentUrl" target="_blank" rel="noopener noreferrer">
              <img [src]="talk.message.previewImageUrl">
            </a>
          </div>
          <div class="msg" *ngSwitchCase="'profile_change'">
            <p class="m-b-0">
              プロフィール画像の変更
              <br>
              <a [href]="talk.message.prev_image_url"><img class="profile_image img-radius" [src]="talk.message.prev_image_url"/></a>
              →
              <a [href]="talk.message.next_image_url"><img class="profile_image img-radius" [src]="talk.message.next_image_url"/></a>
            </p>
          </div>
          <div class="mg" *ngSwitchDefault>
            <p class="m-b-0">{{talk.message | json}}</p>
          </div>
          <p class="text-muted m-b-0"><i class="fa fa-clock-o m-r-10"></i>{{talk.createdAt | date : "yyyy/M/d H:mm"}}</p>
        </div>
      </div>

      <!-- ユーザーのメッセージ -->
      <div *ngSwitchCase="'customer'" class="row received-chat">
        <div class="col-auto p-r-0">
          <img [src]="customer.icon_url" alt="user image" class="img-radius wid-40">
        </div>
        <div class="col" [ngSwitch]="talk.message.type">
          <!-- 通常のテキストメッセージ -->
          <div class="msg" *ngSwitchCase="'text'">
            <p class="m-b-0">{{talk.message.text}}</p>
          </div>
          <!-- セルフコースの備考 -->
          <div class="msg" *ngSwitchCase="'self_other'">
            <p class="m-b-0">{{talk.message.text}}</p>
          </div>
          <div class="msg" *ngSwitchCase="'image'">
            <a [href]="talk.message.originalContentUrl" target="_blank" rel="noopener noreferrer">
              <img class="preview_image" [src]="talk.message.previewImageUrl">
            </a>
          </div>
          <div class="mg" *ngSwitchDefault>
            <p class="m-b-0">{{talk.message | json}}</p>
          </div>
          <p class="text-muted m-b-0"><i class="fa fa-clock-o m-r-10"></i>{{talk.createdAt | date : "yyyy/M/d H:mm"}}</p>
        </div>
      </div>

      <!-- スタッフのメッセージ -->
      <div *ngSwitchCase="'staff'" class="row send-chat">
        <div class="col" [ngSwitch]="talk.message.type">
          <!-- 通常のテキストメッセージ -->
          <div class="msg" *ngSwitchCase="'text'">
            <p class="m-b-0">{{talk.message.text}}</p>
          </div>
          <div class="msg" *ngSwitchCase="'image'">
            <a [href]="talk.message.originalContentUrl" target="_blank" rel="noopener noreferrer">
              <img class="preview_image" [src]="talk.message.previewImageUrl">
            </a>
          </div>
          <div class="msg" *ngSwitchCase="'toys_fix'">
            <p>おもちゃの送信</p>
            <div class="toys_fix row">
              <ng-container *ngFor="let toy of talk.message.toys">
                <toy-card class="col-sm-3" [toy]="toy" [smart]="true"></toy-card>
              </ng-container>
            </div>
          </div>
          <div class="msg" *ngSwitchCase="'toys_unfix'">
            <p>おもちゃの選択</p>
            <div class="toys_unfix row">
              <ng-container *ngFor="let toy of talk.message.toys">
                <toy-card class="col-sm-3" [toy]="toy" [smart]="true"></toy-card>
              </ng-container>
            </div>
          </div>
          <div class="msg" *ngSwitchCase="'charge'">
            <p class="m-b-0">スポット課金の請求<br>「{{talk.message.message}}」</p>
          </div>
          <div class="mg" *ngSwitchDefault>
            <p class="m-b-0">{{talk.message | json}}</p>
          </div>
          <p class="text-muted m-b-0">
            <i class="fa fa-clock-o m-r-10"></i>{{talk.sendAt | date : "yyyy/M/d H:mm" : "Asia/Tokyo"}} {{enum_data.talkStateById(talk.state).name}}
            <a [hidden]="talk.state != 'schedule'" href="#" (click)="cancelTalk($event, talk);">中止</a>
          </p>
        </div>
        <div class="col-auto p-l-0">
          <img src="assets/images/icon.png" alt="user image" class="img-radius wid-40">
        </div>
      </div>
      
    </ng-container>
    
  </div>
  <div class="card-footer">
    <div class="form-group">
      <div class="input-group">
        <textarea type="text" name="task-insert" class="form-control" id="send_text" [(ngModel)]="text_message" placeholder="送信するメッセージを入力"></textarea>
        <div class="input-group-append">
          <button class="btn btn-primary" (click)="send_text()">
            <i class="fas"></i>送信
          </button>
        </div>
      </div>
      <div>
        <button class="btn btn-outline-primary btn-sm custom-msg-button" (click)="preview_image=''; image_file=''; image_select_modal.show()"><i class="fas"></i><br>画像</button>
        <button class="btn btn-outline-primary btn-sm custom-msg-button" (click)="send_unfix_plan()"><i class="fas"></i><br>提案候補</button>
        <button class="btn btn-outline-primary btn-sm custom-msg-button" (click)="send_fix_plan()" [hidden]="!plan"><i class="fas"></i><br>確定済み</button>
        <button class="btn btn-outline-primary btn-sm custom-msg-button" (click)="instant_purchase_modal.show()"><i class="fas"></i><br>スポット課金</button>
        <button class="btn btn-outline-primary btn-sm custom-msg-button" [disabled]="!customer?.unchecks?.is_unread" (click)="checkUnread()"><i class="fas"></i><br>既読にする</button>
        <button class="btn btn-outline-primary btn-sm custom-msg-button" [disabled]="!customer?.unchecks?.is_unreply" (click)="checkUnreply()"><i class="fas"></i><br>返信済みにする</button>
        <button class="btn btn-outline-secondary btn-sm send-time-span-button" (click)="changeSendTimeSpan()"><i class="fas"></i>送信時間　{{getSendTimeString()}}</button>
      </div>
    </div>
  </div>
</div>

<app-ui-modal #image_select_modal>
  <div class="app-modal-header">
    <h5 class="modal-title">ユーザーに画像を送信する</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="image_select_modal.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <form method="post" enctype="multipart/form-data" class="image_select">
      <div id="dragDropArea">
        <div class="drag-drop-inside">
          <p class="drag-drop-info">ここにファイルをドロップ</p>
          <p>または</p>
          <p class="drag-drop-buttons">
            <input id="fileInput" type="file" accept="image/*" value="ファイルを選択" name="photo" (change)="photoPreview($event)" [(ngModel)]="image_file">
          </p>
          <div id="previewArea">
            <img class="preview_image" [src]="preview_image" *ngIf="preview_image">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
        </div>
        <button class="btn btn-primary col-sm-6" type="button" name="submit" value="送信" (click)="send_image(image_select_modal)">送信</button>
      </div>
    </form>
  </div>
</app-ui-modal>

<app-ui-modal #instant_purchase_modal>
  <div class="app-modal-header">
    <h5 class="modal-title">ユーザーにスポット課金を送信する</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="instant_purchase_modal.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <p>
      いくら請求しますか。ユーザーに請求が行われ、ユーザーがクレジッドカードで決済を行うと通知されます。
    </p>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">課金額（税込）</label>
      <div class="col-sm-8">
        <input class="form-control" type="number" name="instant_purchase_amount" [(ngModel)]="instant_purchase_amount">
      </div>
      <label class="col-sm-4 col-form-label">請求メッセージ</label>
      <div class="col-sm-8">
        <input class="form-control" type="text" name="instant_purchase_message" [(ngModel)]="instant_purchase_message">
      </div>
    </div>    
    <div class="row">
      <div class="col-sm-3"></div>
      <button class="col-sm-6 btn btn-primary btn-lg" type="button" (click)="sendInstantPurchase()">送信する</button>
    </div>
  </div>
</app-ui-modal>
