import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Plan } from '../../types/plan.type';
import Swal from 'sweetalert2';
import _ from 'lodash'
import * as moment from 'moment';
import * as JsBarcode from 'jsbarcode';

@Component({
  selector: 'app-ships.invoice',
  templateUrl: './ships.invoice.component.html',
  styleUrls: ['./ships.invoice.component.scss']
})
export class ShipsInvoiceComponent implements OnInit {

  enum_data = enum_data;
  plan : Plan;
  invoices : any[];

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    forkJoin([
      this.api.plan.get(id),
    ]).toPromise().then((result) => {
      let plan = result[0];
      this.plan = plan;
      this.plan.toys = this.plan.toys.filter(toy => ["fix", "prepare", "extend",].indexOf(toy.state) != -1)
      this.invoices = [
        {
          plan : this.plan,
          customer : this.plan.customer,
          visible : true,
        },
        {
          plan : this.plan,
          customer : this.plan.customer,
          visible : true,
        },
        {
          plan : this.plan,
          customer : this.plan.customer,
          visible : true,
        },
      ];
      return new Promise((resolve, reject) => {
        setTimeout(resolve, 100);
      });
    }).then(() => {
      let trackingcode = (this.plan.trackingcode || "000000").replace(/-/g, "");
      let trackingnumber = (this.plan.trackingnumber || "000000000000").replace(/-/g, "");
      JsBarcode(".barcode-code", trackingcode, { format : "CODE128", height : 60, background : "#00000000", });
      JsBarcode(".barcode-number", trackingnumber, { format: "codabar", height : 60, background : "#00000000", });
    });
  }
  
  zipcode(zipcode) {
    if (zipcode && zipcode.length >= 7) {
      return zipcode.substr(0, 3) + "-" + zipcode.substr(3);
    }
    return zipcode;
  }
  
  switchVisiblity(invoice) {
    invoice.visible = !invoice.visible;
  }

}
