import {Pipe, PipeTransform} from '@angular/core';
import * as enum_data from '../../../../api/lib/enum_data';

@Pipe({
  name: 'property_sort'
})
export class PropertySort implements PipeTransform {
  
  getValueRaw(value, key, default_value = "") {
    let value_string = key.split(".").reduce((value, key) => {
      if (key == "*") {
        return value;
      }
      if (typeof value == "object") {
        return value[key] || default_value;
      } else {
        return default_value;
      }
    }, value);
    return value_string;
  }

  transform(array: any[], sort_order: string): any {
    if (!sort_order || !array || typeof array != "object") {
      return array;
    }
    let is_asc = sort_order.indexOf("_asc") != -1;
    let key = sort_order.replace("_asc", "").replace("_desc", "");
    if (sort_order) {
      array.sort((a, b) => {
        let a_value = this.getValueRaw(a, key);
        let b_value = this.getValueRaw(b, key);
        if (a_value == b_value) {
          return 0;
        } else if (a_value < b_value) {
          return is_asc ? -1 : 1;
        } else {
          return is_asc ? 1 : -1;
        }
      })
    }
    return array;
  }
}
