<ng-container *ngIf="shown">
  
  <data-table 
    id="charges.list.charges"
    title="スポット課金一覧"
    [rows]="rows"
    [columns]="{
      'id' : { order : 'desc', hidden : true },
      'customer.name' : { name : 'ユーザー名' },
      'type' : { name : '種類', type : 'select', data : enum_data.charge_types },
      'state' : { name : '最終ステータス', type : 'select', data : enum_data.charge_status },
      'amount' : { name : '請求額', customCell : amountCell },
      'message' : { name : 'メッセージ' },
      'createdAt' : { name : '請求日', type : 'datetime' },
      'billedAt' : { name : '支払日', type : 'datetime' }
    }"
  >
  </data-table>
  
</ng-container>

<ng-template #amountCell let-row="row" let-value="value">
  <p class="amount">{{(row.amount + row.tax) | currency : 'JPY'}}</p>
</ng-template>
