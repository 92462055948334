import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Plan } from '../types/plan.type';
import { Toy } from '../types/toy.type';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(private http: HttpClient) { }

  list(query_params = null) : Observable<Plan[]> {
    const params = new HttpParams({fromObject: query_params || {}});
    return this.http.get<Plan[]>('/api/plans/list', { params, });
  }

  public get(plan_id) : Observable<Plan> {
    return this.http.get<Plan>('/api/plans/' + plan_id);
  }

  public get_back(plan_id) : Observable<Plan> {
    return this.http.get<Plan>('/api/plans/' + plan_id + '/back');
  }

  public update(plan) : Observable<Plan> {
    return this.http.put<Plan>('/api/plans/' + plan.id, plan)
  }

  public toys(plan_id, status = []) : Observable<Toy[]> {
    return this.http.get<Toy[]>('/api/plans/' + plan_id + '/toys/list?status=' + status.join(","));
  }

  public add_toys(plan_id, toy_ids) : Observable<Toy[]> {
    return this.http.post<Toy[]>('/api/plans/' + plan_id + '/toys', { toy_ids, });
  }

  public delete_toy(plan_id, toy_id) : Observable<string> {
    return this.http.delete<string>('/api/plans/' + plan_id + '/toys/' + toy_id);
  }

  public update_toy(plan_id, plan_toy_id, params) : Observable<any> {
    return this.http.put<any>('/api/plans/' + plan_id + '/toys/' + plan_toy_id, params);
  }

  public submit(plan_id, priority) : Observable<string> {
    return this.http.post<string>('/api/plans/' + plan_id + '/submit', { priority, });
  }

  public ship(plan_id, ship_number, start_date, line_flg) : Observable<string> {
    return this.http.post<string>('/api/plans/' + plan_id + '/ship', { ship_number, start_date, line_flg});
  }

  public back(plan_id) : Observable<string> {
    return this.http.post<string>('/api/plans/' + plan_id + '/back', {});
  }

  public lost(plan_id) : Observable<string> {
    return this.http.post<string>('/api/plans/' + plan_id + '/lost', {});
  }

  public print(plan_id) : Observable<Plan> {
    return this.http.put<Plan>('/api/plans/' + plan_id + '/print', {});
  }

}
