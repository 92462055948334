import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { UiModalComponent } from '../../theme/shared/components/modal/ui-modal/ui-modal.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-charges.list',
  templateUrl: './charges.list.component.html',
  styleUrls: ['./charges.list.component.scss']
})
export class ChargesListComponent implements OnInit {
  
  enum_data = enum_data;
  shown = true;
  
  public rows = [];
  public changes = [];
  
  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
    forkJoin([
      this.api.purchase.charges(),
    ]).subscribe((results) => {
      this.rows = results[0];
      this.shown = true;
    });
  }

  
}
