<!-- Set "A5", "A4" or "A3" for class name -->
<!-- Set also "landscape" if you need -->
<body class="A3">

  <!-- Each sheet element should have the class "sheet" -->
  <!-- "padding-**mm" is optional: you can set 10, 15, 20 or 25 -->
  <section class="sheet">
    
    <img class="back" src="/assets/images/ships/sheet/back.png" [hidden]="true">

    <div class="text-base" *ngIf="plan">
      <div class="name-row">
        <p class="identifier">{{plan.customer.id}}</p>
        <p class="name">{{plan.customer.name}}</p>
      </div>
      <div class="battery-row">
        <p class="battery">{{enum_data.planBatteryTypeById(plan.battery_type).name}} {{(plan.battery_num || "") + (plan.battery_num ? "個" : "")}}</p>
      </div>
      
      <div class="toys">
        <div class="toy" *ngFor="let toy of plan.toys">
          <img class="image" [src]="toy.main_image.url">
          <p class="name">
            <span class="name">{{toy.name}}</span>
            <span class="state" [hidden]="toy.state != 'extend'">（延長）</span>
            <span class="height-dummy"></span>
          </p>
          <p class="maker"><span class="maker">{{toy.detail.maker}}</span><span class="height-dummy"></span></p>
          <p class="identifier"><span class="identifier">{{toy.identifier}}</span><span class="height-dummy"></span></p>
          <p class="country"><span class="country">{{toy.detail.country}}</span><span class="height-dummy"></span></p>
          <p class="description">{{toy.detail.description}}</p>
          <p class="parts">{{toy.detail.parts}}</p>
        </div>
      </div>
    </div>
  </section>

</body>
