<button type="button" class="btn btn-primary csv-button" (click)="downloadCsv()" [hidden]="hide_csv_button">CSVダウンロード</button>

<data-table
  id="ships.list.plans"
  title="発送一覧"
  [ordering]="false"
  [rows]="rows"
  [custom_search]="true"
  (onSearch)="search($event)"
  [columns]="{
    'id' : { name : 'ID', customCell : idCell },
    'customer.name' : { name : '名前', customCell : nameCell },
    'is_first' : { name : '新規/交換', customCell : firstCell },
    'request_date' : { name : '申請日', customCell : requestDateCell },
    'submitedAt' : { name : 'プラン確定日', customCell : submitedCell },
    'action' : { name : 'アクション', customCell : actionCell },
    'customer_id' : { name : 'ユーザーID', search : { type : 'string' }, hidden : true }
  }"
>
</data-table>

<ng-template #actionCell let-row="row" let-value="value">
  <div class="actions">
    <button class="btn btn-success btn-sm" (click)="print_shipping_seal(row)">発送伝票印刷</button>
    <button class="btn btn-success btn-sm" (click)="open_plan_sheet(row)" [hidden]="row.printed">プランシート</button>
    <button class="btn btn-secondary btn-sm" (click)="open_plan_sheet(row)" [hidden]="!row.printed">プランシート(印刷済)</button>
    <button class="btn btn-primary btn-sm" (click)="submit(row)">発送完了</button>
  </div>
</ng-template>

<ng-template #idCell let-row="row" let-value="value">
  <div class="first-cell">
    <span class="submit-date">{{row.customer_id}}</span>
  </div>
</ng-template>

<ng-template #nameCell let-row="row" let-value="value">
  <div class="name-cell">
    <a [routerLink]="['/customers', row.customer.id]">
      <span class="name">{{row.customer.name}}</span>
    </a>
    <span class="priority" [hidden]="!row.priority">優先</span>
  </div>
</ng-template>

<ng-template #firstCell let-row="row" let-value="value">
  <div class="first-cell">
    <span class="submit-date" [class]="{short:row.short_request}">{{value ? "初回" : (row.short_request ? "前倒し交換" : "交換")}}</span>
  </div>
</ng-template>

<ng-template #submitedCell let-row="row" let-value="value">
  <div class="submited-cell">
    <span class="submit-date">{{value | date : "y年M月d日"}}</span>
    <span class="submit-elapsed">（{{getSubmitElapsed(row)}}）</span>
  </div>
</ng-template>

<ng-template #requestDateCell let-row="row" let-value="value">
  <div class="requestDate-cell">
    <span class="request-date">{{value | date : "y年M月d日"}}</span>
    <span class="request-elapsed">（{{getRequestElapsed(row)}}）</span>
  </div>
</ng-template>
