import { Component, Input, OnInit, SimpleChanges, ViewChild, ElementRef, } from '@angular/core';
import { ApiService } from '../services/api.service';
import Swal from 'sweetalert2';

import { Toy } from '../types/toy.type';

@Component({
  selector: 'toy-image',
  templateUrl: './toy-image.component.html',
  styleUrls: ['./toy-image.component.scss']
})
export class ToyImageComponent implements OnInit {
  
  @Input() toy : Toy;
  @Input() editing : boolean = true;
  current_image = null;
  image_preview = null;
  
  @ViewChild('image_file')
  image_file_input : ElementRef;

  constructor(private api : ApiService) { }

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.toy && changes.toy.currentValue) {
      this.current_image = changes.toy.currentValue.main_image;
    }
  }
  
  image_changed(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
        this.image_preview = reader.result;
      };
    }
  }
  
  upload(file) {
    if (!this.image_preview) {
      Swal.fire("エラー", "画像を選択してください", "error")
      return;
    }
    Swal.fire("アップロード中");
    Swal.showLoading();
    this.api.toy.addImage(this.toy.id, this.image_preview).toPromise().then((data) => {
      this.toy.images.push(data);
      this.current_image = data;
      this.clear();
      Swal.fire("画像アップロード成功", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "画像のアップロードに失敗しました", "error")
    });
  }
  
  change_main(image) {
    Swal.fire("メイン画像変更中");
    Swal.showLoading();
    this.api.toy.changeMainImage(this.toy.id, this.current_image.id).toPromise().then((data) => {
      this.toy.images = data;
      this.toy.main_image = this.toy.images.find(image => image.type == "main");
      this.current_image = this.toy.main_image;
      Swal.fire("メイン画像の変更に成功しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "メイン画像の変更に失敗しました", "error")
    });
  }
  
  clear() {
    this.image_preview = null;
    this.image_file_input.nativeElement.value = "";
  }
  
  delete() {
    Swal.fire({
      icon : "warning",
      title : "画像を削除しますか？",
      text : "この処理は取り消せません。画像を削除した場合、同じ画像を使用するには再度画像をアップロードする必要があります",
      confirmButtonText : "削除する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("画像削除中");
      Swal.showLoading();
      this.api.toy.deleteImage(this.toy.id, this.current_image.id).toPromise().then((data) => {
        this.toy.images = data;
        this.current_image = this.toy.main_image;
        Swal.fire("画像の削除に成功しました", "", "success")
      }).catch((error) => {
        Swal.fire("エラー", "画像の削除に失敗しました", "error")
      });
    });
  }

}
