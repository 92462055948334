import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UiModalComponent } from '../../theme/shared/components/modal/ui-modal/ui-modal.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-cards.list',
  templateUrl: './cards.list.component.html',
  styleUrls: ['./cards.list.component.scss']
})
export class CardsListComponent implements OnInit {

  @ViewChild("add_staff_modal") add_staff_modal : UiModalComponent;


  enum_data = enum_data;
  card_status = [true, true, true];

  public rows = [];

  property_rows = [
    { id : 'name', name :"名前", type : "string", placeholder : "名前を入力", },
    { id : 'email', name :"メールアドレス", type : "email", placeholder : "メールアドレスを入力", },
    { id : 'password', name :"パスワード", type : "password", placeholder : "パスワード", skip : true, },
    { id : 'password_confirm', name :"パスワード再入力", type : "password", placeholder : "パスワード再入力", skip : true, },
    { id : "grant", name : "権限", type : "select", placeholder : "選択", options : enum_data.staff_grants, },
  ];

  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
    this.api.card.list().toPromise().then((data) => {
      console.log(data);
      this.rows = data;
      this.rows.map(item => {
        if (item.card_type === 1) {
          this.card_status[0] = false;
        } else if (item.card_type === 2) {
          this.card_status[1] = false;
        } else if (item.card_type === 3) {
          this.card_status[2] = false;
        }
      })
    });
  }

  addCard(type) {
    Swal.fire("メルマガ情報保存中");
    Swal.showLoading();
    this.api.card.create({card_type: type}).toPromise().then((data) => {
      Swal.fire("メルマガ情報を保存しました", "", "success");
      this.api.card.list().toPromise().then((data) => {
        this.rows = data;
        this.rows.map(item => {
          if (item.card_type === 1) {
            this.card_status[0] = false;
          } else if (item.card_type === 2) {
            this.card_status[1] = false;
          } else if (item.card_type === 3) {
            this.card_status[2] = false;
          }
        })
      });
    }).catch((error) => {
      Swal.fire("エラー", "メルマガ情報の保存に失敗しました", "error")
    });
  }

}
