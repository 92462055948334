import { Component, OnInit, ViewChild, } from '@angular/core';
import { AppDataTableComponent } from '../../data-table/data-table.component';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-plans.list',
  templateUrl: './plans.list.component.html',
  styleUrls: ['./plans.list.component.scss']
})
export class PlansListComponent implements OnInit {

  @ViewChild(AppDataTableComponent, {static: false}) plansDataTable: AppDataTableComponent;

  enum_data = enum_data;
  
  public rows = [];
  public staffs = [];
  staff_id;
  is_planner;

  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
    this.staffs = this.api.staffs;
    this.staff_id = this.api.me.id;
    this.is_planner = this.api.me.grant == "plan";
  }

  search(search_conditions) {
    Swal.fire("読込中");
    Swal.showLoading();
    let conditions = {
      self : 0,
    };
    for (let key of Object.keys(search_conditions)) {
      console.log({[key]:search_conditions[key]});
      let param_name = key.replace(".", "_");
      conditions[param_name] = search_conditions[key];
    }
    this.api.plan.list(conditions).toPromise().then((plans) => {
      this.rows = plans;
      this.plansDataTable.reload();
      Swal.close();
    });
  }

  getFirstValue(plan) {
    if (plan.is_first == "1" || !!plan.is_first) {
      return "初回申請";
    }
    else {
      if (plan.short_request == "0" || !plan.short_request) {
        return "交換申請";
      } else {
        return "前倒し交換申請";
      }
    }
  }
}
