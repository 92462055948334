<data-table
  id="backs.list.plans"
  title="返却一覧"
  [rows]="rows"
  [columns]="{
    'customer.id' : { name : 'ID', search : { type : 'string' } },
    'customer.name' : { name : '名前', hidden : true, search : { type : 'string' } },
    'customer' : { name : '名前', customCell : nameCell },
    'customer.detail.leavedAt' : { name : '返却理由', customCell : reasonCell, order : 'desc' },
    'action' : { name : 'アクション', customCell : actionCell }
  }"
>
</data-table>

<ng-template #nameCell let-row="row" let-value="value">
  <div class="name-cell">
    <p class="name">
      {{value.name}}
      <span class="lost" [hidden]="row.state != 'lost'">紛失</span>
    </p>
    <p class="date" [hidden]="value.detail.leavedAt">交換日：{{value.last_start_date | date : "y年M月d日"}}（{{value.last_start_date | elapsed_days }}日経過）</p>
    <p class="date" [hidden]="!value.detail.leavedAt">退会日：{{value.detail.leavedAt | date : "y年M月d日"}}（{{value.detail.leavedAt | elapsed_days }}日経過）</p>
  </div>
</ng-template>

<ng-template #reasonCell let-row="row" let-value="value">
  <div class="reason-cell">
    <p class="reason" [hidden]="value">交換</p>
    <p class="reason" [hidden]="!value">退会</p>
  </div>
</ng-template>

<ng-template #actionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/backs/'+row.id">開く</a>
</ng-template>

