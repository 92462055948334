import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Customer } from '../../types/customer.type';
import { ToyReview } from '../../types/toy-review.type';
import Swal from 'sweetalert2';
import _ from 'lodash'
import * as moment from 'moment';

@Component({
  selector: 'app-customers.view',
  templateUrl: './customers.view.component.html',
  styleUrls: ['./customers.view.component.scss']
})
export class CustomersViewComponent implements OnInit {

  show : boolean = false;
  enum_data = enum_data;
  customer : Customer;
  properties : any = {};
  editing : boolean = false;
  plans = [];
  changes = [];
  reviews = [];
  charges = [];
  staffs = [];

  property_rows = [
    { id : 'id', name :"ID", type : "string", placeholder : "IDを入力", readonly : true, },
    { id : "state", name : "登録状況", type : "select", placeholder : "選択", options : enum_data.customer_status, readonly : true, },
    { id : 'staff_id', name :"担当プランナー", type : "select", placeholder : "プランナーを選択", options : [], },
    { id : 'name', name :"名前", type : "string", placeholder : "名前を入力", },
    { id : 'tel', name :"電話番号", type : "tel", placeholder : "電話番号を入力", detail : true, },
    { id : 'email', name :"メールアドレス", type : "email", placeholder : "メールアドレスを入力", detail : false, },
    { id : 'child_1', name :"子供１", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : 'child_2', name :"子供２", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : 'child_3', name :"子供３", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : 'child_4', name :"子供４", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : 'child_5', name :"子供５", type : "child", placeholder : ['名前を入力', '性別'], detail : true, },
    { id : "genres", name : "今興味があるもの", type : "multiselect", placeholder : "選択", options : enum_data.toy_genres, detail : true, },
    { id : "shipping_name", name : "郵送先お名前", type : "string", placeholder : "例）山田花子", detail : true, },
    { id : "shipping_tel", name : "郵送先電話番号", type : "tel", placeholder : "例）031234-5678（ハイフン無し半角数字）", detail : true, },
    { id : 'shipping_zipcode', name :"郵送先郵便番号", type : "zipcode", placeholder : "名前を入力", detail : true, prefix : "〒" },
    { id : 'shipping_address1', name :"郵送先住所（番地まで）", type : "string", placeholder : "名前を入力", detail : true, },
    { id : 'shipping_address2', name :"郵送先住所（マンション名・部屋番号等）", type : "string", placeholder : "名前を入力", detail : true, },
    { id : 'registeredAt', name :"登録日時", type : "datetime", placeholder : "", detail : true, readonly : true, },
    { id : 'leavedAt', name :"退会日時", type : "datetime", placeholder : "", detail : true, readonly : true, },
    //{ id : 'owneds', name :"手持ちのおもちゃ", type : "owneds", detail : true, },
    { id : 'score', name :"スコア合計", type : "number", placeholder : "", readonly : true, },
    { id : 'skill', name :"巧緻性", type : "number", placeholder : "", readonly : true, },
    { id : 'logic', name :"論理性", type : "number", placeholder : "", readonly : true, },
    { id : 'creativity', name :"創造性", type : "number", placeholder : "", readonly : true, },
    { id : 'sociality', name :"社交性", type : "number", placeholder : "", readonly : true, },
    { id : 'music', name :"音感性", type : "number", placeholder : "", readonly : true, },
    { id : 'tags', name :"タグ", type : "tags", placeholder : "タグを選択", detail : true, options : enum_data.customer_tags, },
    { id : 'announce_title', name :"アナウンスタイトル", type : "string", detail : true, placeholder : "入力するとユーザーのマイページに表示されます" },
    { id : 'announce_text', name :"アナウンス本文", type : "text", detail : true, placeholder : "10行以降はスクロールで表示されます", },
    { id : 'memos', name :"メモ", type : "memos", detail : true, },
    { id : 'payed_link_url', name :"ユーザー情報登録URL", type : "link_url", },
    { id : 'link_url', name :"LINE連携URL", type : "link_url", },
    { id : 'line_id', name :"LINE連携", type : "boolean", true_value : "連携済み", false_value : "未連携", readonly : true, },
    { id : 'bt_user_id', name :"LINE友達登録", type : "boolean", true_value : "登録済み", false_value : "未登録", readonly : true, },
  ];

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap
      .subscribe((params) => {
        this.editing = params["params"]["edit"];
      }
    )
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      let id = paramMap.get('id');
      this.property_rows.find(row => row.id == "staff_id").options = this.api.staffs.map((staff) => { return { name : staff.name, value : String(staff.id), }; });
      Swal.fire("読込中");
      Swal.showLoading();
      forkJoin([
        this.api.customer.get(id),
        this.api.customer.plans(id),
        this.api.task.check_customer(id),
      ]).subscribe((result) => {
        this.api.tasksChanged.emit();
        Swal.close();

        let customer = result[0];
        this.customer = customer;
        this.changes = this.customer.changes;
        this.charges = this.customer.charges;
        this.reviews = this.customer.reviews;
        this.copy_property_by_customer();

        let plans = result[1];
        plans.sort((plan1, plan2) => {
          if (!plan1.start_date) {
            return -1;
          }
          if (!plan2.start_date) {
            return 1;
          }
          if (plan1.start_date < plan2.start_date) {
            return 1;
          }
          if (plan1.start_date > plan2.start_date) {
            return -1;
          }
          return 0;
        });
        this.plans = plans;
        for (let plan of this.plans) {
          plan.toys = plan.toys.filter(toy => ["rejected"].indexOf(toy.state) == -1);
          if (plan.state == 'request' || plan.state == 'planning') {
              if (plan.toys.length == 0) {
                plan.state = 'request';
              } else {
                plan.state = 'planning';
              }
          }
        }
      });
    });
  }

  copy_property_by_customer() {
    this.properties = this.property_rows.reduce((data, row) => {
      if (row.detail && typeof this.customer.detail[row.id] == "object" && this.customer.detail[row.id].length === undefined) {
        data[row.id] = {};
        for (let key of Object.keys(this.customer.detail[row.id])) {
          data[row.id][key] = this.customer.detail[row.id][key];
        }
      } else {
        data[row.id] = row.detail ? this.customer.detail[row.id] : this.customer[row.id];
      }
      return data;
    }, {});
  }

  edit_start() {
    this.editing = true;
  }

  edit_cancel() {
    this.copy_property_by_customer();
    this.editing = false;
  }

  edit_save() {
    this.property_rows.forEach((row) => {
      if (row.detail && typeof this.properties[row.id] == "object" && this.properties[row.id].length === undefined) {
        this.customer.detail[row.id] = {};
        for (let key of Object.keys(this.properties[row.id])) {
          this.customer.detail[row.id][key] = this.properties[row.id][key];
        }
      } else {
        if (row.detail) {
          this.customer.detail[row.id] = this.properties[row.id];
        } else {
          this.customer[row.id] = this.properties[row.id];
        }
      }
    });
    console.log({customer:this.customer});

    Swal.fire("ユーザー情報保存中");
    Swal.showLoading();
    this.api.customer.update(this.customer).toPromise().then((data) => {
      this.customer = data;
      this.reviews = this.customer.reviews;
      this.changes = this.customer.changes;
      this.charges = this.customer.charges;
      this.copy_property_by_customer();
      this.editing = false;
      this.api.tasksChanged.emit();
      Swal.fire("ユーザー情報を保存しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "ユーザー情報の保存に失敗しました", "error")
    });
  }

  getChangedValue(change, value) {
    if (!value || value == "null") {
      return "";
    }
    let field = enum_data.customerChangeFieldByValue(change.field);
    if (field.id == "staff_id") {
      return (this.api.staffs.find(staff => staff.id == value) || { name : "", }).name;
    }
    if (!field.type || field.type == "text") {
      return value;
    }
    if (field.type == "select") {
      let data = enum_data[field.data_name];
      let item = data.find(item => item.id == value);
      return item ? item.name || "" : "";
    }
    if (field.type == "multiselect") {
      try {
        if (typeof value == "string") {
          value = JSON.parse(value);
        }
      } catch (e) {}
      let data = enum_data[field.data_name];
      return value.map((val) => {
        let item = data.find(item => item.id == val);
        return item ? item.name || "" : "";
      }).join("、");
    }
    if (field.type == "child") {
      let child;
      try {
        child = JSON.parse(value);
      } catch (e) {
        child = [];
      }
      if (!child || typeof child != "object") {
        return "";
      }
      let name = child.name;
      let birth = child.birth;
      let gender = child.gender;
      let birth_string = moment(birth).format("YYYY年M月D日");
      let gender_name = enum_data.genderByValue(gender).name;
      return `${name}（${gender_name}・${birth_string}生まれ）`;
    }
    if (field.type == "memos") {
      let memos;
      try {
        memos = JSON.parse(value);
      } catch (e) {
        memos = [];
      }
      if (!memos || !memos.length) {
        return "";
      }
      return memos.map((memo) => {
        let text = memo.text;
        let date = memo.date;
        let type = memo.type;
        let date_string = moment(date).format("YYYY年M月D日");
        let type_name = enum_data.memoTypeByValue(type).name;
        return `[${date_string}][${type_name}]「${text}」`;
      }).join("\n");
    }
  }

  toggleReviewVisible (review : ToyReview, visible : boolean) {
    Swal.fire("レビュー設定を保存中");
    Swal.showLoading();
    this.api.toy.updateReview(review.id, { visible, }).toPromise().then((data) => {
      let review = this.reviews.filter(review => review.id == data.id).shift();
      review.visible = data.visible;
      Swal.fire("レビューの設定を保存しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "レビュー設定の保存に失敗しました", "error")
    });
  }

}
