import { Component, Input, OnInit, SimpleChanges, ViewChild, ElementRef, } from '@angular/core';
import { ApiService } from '../services/api.service';
import * as enum_data from '../../../../api/lib/enum_data';
import Swal from 'sweetalert2';

import {Card} from "../types/card.type";

@Component({
  selector: 'card-type',
  templateUrl: './card-type.component.html',
  styleUrls: ['./card-type.component.scss']
})
export class CardTypeComponent implements OnInit {

  enum_data = enum_data;
  @Input() card : Card;
  @Input() editing : boolean = true;
  btn_type = null;

  @ViewChild('image_file')
  image_file_input : ElementRef;

  constructor(private api : ApiService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {}

  getBtnType(card: Card, btn_type_field_name: string, label_field_name: string) {
    let btn_type = enum_data.cardBtnTypeById(card[btn_type_field_name]);
    if (btn_type) {
      return this.getButtonLabel(btn_type, card, card[label_field_name]);
    }
    return '';
  }

  getButtonLabel(type, card, label) {
    if (Number(type.id) === 0) {
      return "";
    }
    if (Number(type.id) === 1) {
      return label;
    }
    if (Number(type.id) === 2) {
      console.log({card_type : this.card.card_type});
      let card_info = enum_data.cardInfoById(this.card.card_type);
      console.log({card_info});
      return card_info?.name;
    }
    if (Number(type.id) === 3) {
      return 'クーポンコードを発行'
    }

    return type.label;
  }

  getCardType(card) {
    const cards = enum_data.cardById(card.card_type);
    if (cards && cards.length) {
      return cards?.name;
    }
    return ''
  }

  click() {
    // location.href = this.card.action_url;
  }

}
