<ng-container *ngIf="staff || true">
  <div class="row staff-top">
    <div class="col-sm-12">
      <div class="edit-buttons">
        <button type="button" class="btn btn-primary" [hidden]="editing" (click)="edit_start()">編集</button>
        <button type="button" class="btn btn-secondary" [hidden]="!editing" (click)="edit_cancel()">キャンセル</button>
        <button type="button" class="btn btn-primary" [hidden]="!editing" (click)="edit_save()">保存</button>
      </div>
      <div class="table-responsive">
        <table class="table">
          <tr property-row *ngFor="let row of property_rows"
            [id]="row.id"
            [name]="row.name"
            [(ngModel)]="properties[row.id]"
            [type]="row.type"
            [editing]="editing"
            [options]="row.options"
            [placeholder]="row.placeholder"
            [suffix]="row.suffix"
            [prefix]="row.prefix"
            [hidden]="!editing && !properties[row.id]"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  
  <data-table 
    id="staffs.view.plans"
    title="申請・プラン一覧"
    [rows]="plans"
    [columns]="{
      'state' : { name : 'ステータス・申請日', customCell : stateCell },
      'hope' : { name : 'おもちゃの希望', class : 'hope', customCell : wrapCell },
      'situation' : { name : '最近の様子', class : 'situation', customCell : wrapCell },
      'toys' : { name : 'おもちゃ' , customCell : toysCell },
      'action' : { name : 'アクション', customCell : planActionCell }
    }">
  
  </data-table>

  <data-table 
    id="staffs.list.customers"
    title="担当ユーザー一覧"
    [rows]="customers"
    [columns]="{
      'name' : { name : '名前' },
      'detail.*' : { name : '子供', customCell : childCell, search : { type : 'child' } },
      'detail.shipping_address1' : { name : '住所' },
      'detail.shipping_address2' : { name : '住所（番地以降）' },
      'next_order_at' : { name : '次回交換申請可能日' },
      'action' : { name : 'アクション', customCell : customerActionCell }
    }"
  >
  </data-table>
  
  <data-table 
    id="staffs.view.changes"
    title="変更履歴"
    [rows]="changes"
    [columns]="{
      'staff.name' : { name : '担当者' },
      'field' : { name : '項目', type : 'select', data : enum_data.staff_change_fields },
      'before_value' : { name : '変更前', customCell : changeValueCell },
      'after_value' : { name : '変更後', customCell : changeValueCell },
      'createdAt' : { name : '変更日時', type : 'datetime' }
    }">
  </data-table>

  <ng-template #childCell let-row="row" let-value="value">
    <div class="child" *ngIf="value.child_1">
      <span class="name">{{value.child_1.name}}</span>
      （
      <span class="gender" [ngClass]="'gender_' + value.child_1.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
      ・
      <span class="age">{{enum_data.getAge(value.child_1.birth)}}</span>
      ）
    </div>
    <div class="child" *ngIf="value.child_2">
      <span class="name">{{value.child_2.name}}</span>
      （
      <span class="gender" [ngClass]="'gender_' + value.child_2.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
      ・
      <span class="age">{{enum_data.getAge(value.child_2.birth)}}</span>
      ）
    </div>
    <div class="child" *ngIf="value.child_3">
      <span class="name">{{value.child_3.name}}</span>
      （
      <span class="gender" [ngClass]="'gender_' + value.child_3.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
      ・
      <span class="age">{{enum_data.getAge(value.child_3.birth)}}</span>
      ）
    </div>
    <div class="child" *ngIf="value.child_4">
      <span class="name">{{value.child_4.name}}</span>
      （
      <span class="gender" [ngClass]="'gender_' + value.child_4.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
      ・
      <span class="age">{{enum_data.getAge(value.child_4.birth)}}</span>
      ）
    </div>
    <div class="child" *ngIf="value.child_5">
      <span class="name">{{value.child_5.name}}</span>
      （
      <span class="gender" [ngClass]="'gender_' + value.child_5.gender">{{enum_data.genderByValue(value.child_1.gender).name}}</span>
      ・
      <span class="age">{{enum_data.getAge(value.child_5.birth)}}</span>
      ）
    </div>
  </ng-template>
  <ng-template #actionCell let-row="row" let-value="value">
    <a class="btn btn-primary" [routerLink]="'/staff/'+row.id">開く</a>
  </ng-template>
</ng-container>

<ng-template #stateCell let-row="row" let-value="value">
  <p class="state">{{enum_data.planStateByValue(row.state).name}}</p>
  <p class="request-date">{{row.request_date | date : "y年M月d日" }}</p>
</ng-template>
<ng-template #toysCell let-row="row" let-value="value">
  <div class="toy" *ngFor="let toy of value">
    <span class="name">{{toy.name}}</span>
    <span class="name">{{enum_data.planToyStateByValue(toy.state).name}}</span>
  </div>
</ng-template>
<ng-template #nameCell let-row="row" let-value="value">
  <div class="name-cell">
    <p class="name" [hidden]="!value.name">{{value.name}}</p>
    <p class="name noname" [hidden]="value.name">名前無し</p>
    <div class="tags">
      <p class="genre"[hidden]="!value.detail.genre">{{value.detail.genre}}</p>
      <div *ngFor="let tag of value.detail.tags">
        <p class="tag">{{tag}}</p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #planActionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/plans/'+row.id">開く</a>
</ng-template>
<ng-template #customerActionCell let-row="row" let-value="value">
  <a class="btn btn-primary" [routerLink]="'/customers/'+row.id">開く</a>
</ng-template>
<ng-template #changeValueCell let-row="row" let-value="value">
  <span class="changed-value">{{getChangedValue(row, value)}}</span>
</ng-template>
<ng-template #wrapCell let-row="row" let-value="value">
  <span class="wrap">{{value}}</span>
</ng-template>
