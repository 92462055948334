import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Task } from '../types/task.type';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient) { }

  public list(conditions = {}) : Observable<Task[]> {
    return this.http.get<Task[]>('/api/tasks/list', { params : conditions, });
  }

  public mine() : Observable<Task[]> {
    return this.http.get<Task[]>('/api/tasks/mine');
  }

  public check_plan(plan_id) : Observable<Task[]> {
    return this.http.put<Task[]>('/api/tasks/plans/' + plan_id + "/check", {});
  }

  public check_customer(customer_id) : Observable<Task[]> {
    return this.http.put<Task[]>('/api/tasks/customers/' + customer_id + "/check", {});
  }

  public check_customer_chat(customer_id) : Observable<Task[]> {
    return this.http.put<Task[]>('/api/tasks/customers/' + customer_id + "/chat_check", {});
  }

  public check_type(type) : Observable<Task[]> {
    return this.http.put<Task[]>('/api/tasks/types/' + type + "/check", {});
  }

  public finish(task_id) : Observable<Task> {
    return this.http.put<Task>('/api/tasks/' + task_id + "/finish", {});
  }

  public delete(task_id) : Observable<boolean> {
    return this.http.delete<boolean>('/api/tasks/' + task_id , {});
  }



}
