import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'wide_char'
})
export class WideChar implements PipeTransform {
  
  transform(value : any): any {
    if (typeof value == "number") {
      value = String(value);
    }
    if (typeof value != "string") {
      return value;
    }
    let str = value.replace(/[A-Za-z0-9]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
    });
    
    return str;
  }
}
