import { Component, OnInit, ViewChild, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { UiModalComponent } from '../../theme/shared/components/modal/ui-modal/ui-modal.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-staffs.list',
  templateUrl: './staffs.list.component.html',
  styleUrls: ['./staffs.list.component.scss']
})
export class StaffsListComponent implements OnInit {
  
  @ViewChild("add_staff_modal") add_staff_modal : UiModalComponent;


  enum_data = enum_data;
  
  public rows = [];
  public new_staff = {
    name : "",
    email : "",
    password : "",
    grant : "",
  };

  property_rows = [
    { id : 'name', name :"名前", type : "string", placeholder : "名前を入力", },
    { id : 'email', name :"メールアドレス", type : "email", placeholder : "メールアドレスを入力", },
    { id : 'password', name :"パスワード", type : "password", placeholder : "パスワード", skip : true, },
    { id : 'password_confirm', name :"パスワード再入力", type : "password", placeholder : "パスワード再入力", skip : true, },
    { id : "grant", name : "権限", type : "select", placeholder : "選択", options : enum_data.staff_grants, },
  ];

  constructor(private router : Router, private api : ApiService) { }

  ngOnInit(): void {
    this.api.staff.list().toPromise().then((data) => {
      this.rows = data;
    });
  }
  
  add() {
    if (!this.new_staff.name) {
      return Swal.fire("エラー", "名前を入力してください", "error");
    }
    if (!this.new_staff.email) {
      return Swal.fire("エラー", "メールアドレスを入力してください", "error");
    }
    if (this.new_staff["password"] || this.new_staff["password_confirm"]) {
      if (this.new_staff["password"] == this.new_staff["password_confirm"]) {
      } else {
        Swal.fire("エラー", "入力されたパスワードが一致しません", "error")
        return;
      }
    } else {
      return Swal.fire("エラー", "パスワードを入力してください", "error");
    }
    if (!this.new_staff.grant) {
      return Swal.fire("エラー", "権限を入力してください", "error");
    }

    Swal.fire("スタッフ追加中");
    Swal.showLoading();
    this.api.staff.add(this.new_staff).toPromise().then((staff) => {
      this.rows.push(staff);
      this.api.staffs = this.rows.concat([]);
      this.new_staff = {
        name : "",
        email : "",
        password : "",
        grant : "",
      };
      this.add_staff_modal.hide();
      Swal.fire("スタッフを追加しました", "", "success")
    }).catch((error) => {
      Swal.fire("エラー", "スタッフの追加に失敗しました", "error")
    });
  }


}
