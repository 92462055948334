import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as enum_data from '../../../../../api/lib/enum_data';

@Component({
  selector: 'app-toys.add',
  templateUrl: './toys.add.component.html',
  styleUrls: ['./toys.add.component.scss']
})
export class ToysAddComponent implements OnInit {

  enum_data = enum_data;

  constructor(private api : ApiService) { }

  ngOnInit(): void {
  }

}
