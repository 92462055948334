<div class="row">
  <div class="col-md-6 scroll-page">
    <data-table 
      id="chats.list.chats"
      title="ユーザー一覧"
      [rows]="rows"
      [columns]="{
        'name' : { name : '名前', customCell : nameCell },
        'last_talk' : { name : '最終会話', customCell : lastTalkCell },
        'unchecks' : { name : '未読・未返信', customCell : unchecksCell },
        'unchecks.is_unread' : { name : '未読', hidden : true, search : { type : 'select', options : enum_data.chat_unchecks_unreads } },
        'unchecks.is_unreply' : { name : '未返信', hidden : true, search : { type : 'select', options : enum_data.chat_unchecks_unreplys } },
        'staff.id' : { name : '担当プランナー', type : 'select', search : is_planner ? undefined : { type : 'select', options : staffs }, data : staffs, hidden : is_planner }
      }"
      [rowSelectable]="true"
      (selectRow)="selectCustomer($event)"
      [button]="'更新'"
      (onClickButton)="refresh_customers()"
    >
    </data-table>
    <ng-template #nameCell let-row="row" let-value="value">
      <h4 class="customer-name">
        <a [routerLink]="['/customers', row.id]">{{row?.name || "名前未登録"}}</a>
      </h4>
    
      <ng-container *ngIf="row">
        <div class="child" *ngIf="row.detail.child_1">
          <span class="name">{{row.detail.child_1.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + row.detail.child_1.gender">{{enum_data.genderByValue(row.detail.child_1.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(row.detail.child_1.birth)}}</span>
          ）
        </div>
        <div class="child" *ngIf="row.detail.child_2">
          <span class="name">{{row.detail.child_2.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + row.detail.child_2.gender">{{enum_data.genderByValue(row.detail.child_2.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(row.detail.child_2.birth)}}</span>
          ）
        </div>
        <div class="child" *ngIf="row.detail.child_3">
          <span class="name">{{row.detail.child_3.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + row.detail.child_3.gender">{{enum_data.genderByValue(row.detail.child_3.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(row.detail.child_3.birth)}}</span>
          ）
        </div>
        <div class="child" *ngIf="row.detail.child_4">
          <span class="name">{{row.detail.child_4.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + row.detail.child_4.gender">{{enum_data.genderByValue(row.detail.child_4.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(row.detail.child_4.birth)}}</span>
          ）
        </div>
        <div class="child" *ngIf="row.detail.child_5">
          <span class="name">{{row.detail.child_5.name}}</span>
          （
          <span class="gender" [ngClass]="'gender_' + row.detail.child_5.gender">{{enum_data.genderByValue(row.detail.child_5.gender).name}}</span>
          ・
          <span class="age">{{enum_data.getAge(row.detail.child_5.birth)}}</span>
          ）
        </div>
      </ng-container>
    </ng-template>

    <ng-template #lastTalkCell let-row="row" let-value="value">
      <div class="last-talk chat-card">
        <ng-container *ngIf="value" [ngSwitch]="value.sender">
          <!-- システムのメッセージ -->
          <div *ngSwitchCase="'system'" class="row system-chat">
            <div class="col" [ngSwitch]="value.message.type">
              <!-- 通常のテキストメッセージ -->
              <div class="msg" *ngSwitchCase="'text'">
                <p class="m-b-0">{{value.message.text}}</p>
              </div>
              <div class="msg" *ngSwitchCase="'image'">
                画像を送信
              </div>
              <div class="mg" *ngSwitchDefault>
                <p class="m-b-0">{{value.message | json}}</p>
              </div>
              <p class="text-muted m-b-0"><i class="fa fa-clock-o m-r-10"></i>{{value.createdAt | date : "y年M月d日 H:mm"}}</p>
            </div>
          </div>
    
          <!-- ユーザーのメッセージ -->
          <div *ngSwitchCase="'customer'" class="row received-chat">
            <div class="col" [ngSwitch]="value.message.type">
              <!-- 通常のテキストメッセージ -->
              <div class="msg" *ngSwitchCase="'text'">
                <p class="m-b-0">{{value.message.text}}</p>
              </div>
              <!-- セルフコースの備考 -->
              <div class="msg" *ngSwitchCase="'self_other'">
                <p class="m-b-0">{{value.message.text}}</p>
              </div>
              <div class="msg" *ngSwitchCase="'image'">
                画像を送信
              </div>
              <div class="mg" *ngSwitchDefault>
                <p class="m-b-0">{{value.message | json}}</p>
              </div>
              <p class="text-muted m-b-0"><i class="fa fa-clock-o m-r-10"></i>{{value.createdAt | date : "y年M月d日 H:mm"}}</p>
            </div>
          </div>
    
          <!-- スタッフのメッセージ -->
          <div *ngSwitchCase="'staff'" class="row send-chat">
            <div class="col" [ngSwitch]="value.message.type">
              <!-- 通常のテキストメッセージ -->
              <div class="msg" *ngSwitchCase="'text'">
                <p class="m-b-0">{{value.message.text}}</p>
              </div>
              <div class="msg" *ngSwitchCase="'image'">
                画像の送信
              </div>
              <div class="msg" *ngSwitchCase="'toys_fix'">
                <p>おもちゃの送信</p>
              </div>
              <div class="msg" *ngSwitchCase="'toys_unfix'">
                <p>おもちゃの提案</p>
              </div>
              <div class="mg" *ngSwitchDefault>
                <p class="m-b-0">{{value.message | json}}</p>
              </div>
              <p class="text-muted m-b-0"><i class="fa fa-clock-o m-r-10"></i>{{value.createdAt | date : "y年M月d日 H:mm"}}</p>
            </div>
          </div>
          
        </ng-container>
      </div>
    </ng-template>

    <ng-template #unchecksCell let-row="row" let-value="value">
      <p class="unread" [hidden]="!value?.unread">未読{{value?.unread | wide_char}}</p>
      <p class="unreply" [hidden]="!value?.unreply">未返信{{value?.unreply | wide_char}}</p>
      <p class="exclusive" [hidden]="!row?.exclusive">専有中</p>
    </ng-template>

  </div>
  
  <div class="col-sm-6 chat scroll-page" *ngIf="customer">
    <chat [customer]="customer"></chat>
    <div class="table-responsive">
      <table class="table">
        <tr property-row *ngFor="let row of property_rows"
          [id]="row.id"
          [name]="row.name"
          [(ngModel)]="properties[row.id]"
          [type]="row.type"
          [editing]="editing"
          [options]="row.options"
          [placeholder]="row.placeholder"
          [suffix]="row.suffix"
          [prefix]="row.prefix"
          [hidden]="!editing && !properties[row.id]"
        ></tr>
      </table>
    </div>
  </div>

</div>
