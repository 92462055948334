import { Component, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { AppDataTableComponent } from '../../data-table/data-table.component';
import * as enum_data from '../../../../../api/lib/enum_data';
import { Plan } from '../../types/plan.type';
import { Toy } from '../../types/toy.type';
import Swal from 'sweetalert2';
import _ from 'lodash'
import * as moment from 'moment';

@Component({
  selector: 'app-plans.view',
  templateUrl: './plans.view.component.html',
  styleUrls: ['./plans.view.component.scss']
})
export class PlansViewComponent implements OnInit {

  @ViewChild(AppDataTableComponent, {static: false}) toysDataTable: AppDataTableComponent;

  show : boolean = false;
  enum_data = enum_data;
  plan : Plan;
  unfix_toys : Toy[];
  fix_toys : Toy[];
  rejected_toys : Toy[];
  extend_toys : Toy[];
  extending_toys : Toy[];
  back_toys : Toy[];
  offer_toys : Toy[];
  other_toys : Toy[];
  history_plans : Plan[];
  toys = [];
  staffs = [];
  is_more_history = false;
  state_buttons_hash = {
    "rental" : ["購入済みに変更"],
    "partless" : ["返却", "買取",],
    "rejected" : ['候補に戻す', '削除'],
    "extend" : ['候補に戻す', '却下'],
    "unfix" : ['確定', '却下'],
    "fix" : ['候補に戻す', '却下'],
    "extending" : ['確定','却下'],
    "back" : ['延長'],
    "prepare" : ['却下',],
  };
  default_age_min;
  default_age_max;
  memo_editing = false;
  exclusive_prev = false;

  customer_property_rows = [
    { id : 'staff_id', name :"担当プランナー", type : "select", placeholder : "プランナーを選択", options : [], },
    { id : "genres", name : "今興味があるもの", type : "multiselect", placeholder : "選択", options : enum_data.toy_genres, detail : true, },
    { id : 'tags', name :"タグ", type : "tags", placeholder : "タグを選択", options : enum_data.customer_tags, detail : true, },
    { id : 'score', name :"スコア合計", type : "number", placeholder : "", },
    { id : 'skill', name :"巧緻性", type : "number", placeholder : "", },
    { id : 'logic', name :"論理性", type : "number", placeholder : "", },
    { id : 'creativity', name :"創造性", type : "number", placeholder : "", },
    { id : 'sociality', name :"社交性", type : "number", placeholder : "", },
    { id : 'music', name :"音感性", type : "number", placeholder : "", },
  ];

  plan_property_rows = [
    { id : 'request_date', name :"申請日", type : "date", },
    { id : 'hope', name :"おもちゃの希望" },
    { id : 'situation', name :"最近の様子" },
    { id : 'property', name :"手持ちのおもちゃ" },
    { id : 'other', name :"その他伝えたいこと" },
    { id : 'battery_type', name :"電池タイプ", type : "select", options : enum_data.plan_battery_types, },
    { id : 'battery_num', name :"電池個数" },
    { id : 'allocation', name :"兄弟配分" },
    { id : 'exclusive', name :"専有タスク", type : "boolean", edit : true, },
    { id : 'short_request', name :"前倒し申請", type : "boolean", edit : false, },
  ];
  
  memo_property_rows = [
    { id : 'memos', name :"メモ", type : "memos", detail : true, },
  ];
  
  memo_properties = {};
  
  toys_histories = [];

  constructor(private api : ApiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    console.log("ngOnInit");
    this.customer_property_rows.find(row => row.id == "staff_id").options = this.api.staffs.map((staff) => { return { name : staff.name, value : String(staff.id), }; });
    forkJoin([
      this.api.plan.get(id),
      this.api.task.check_plan(id),
    ]).toPromise().then((result) => {
      this.api.tasksChanged.emit();
      
      let plan = result[0];
      plan.toys.forEach((toy) => { toy.selected = false; });
      this.plan = plan;
      this.refresh_plan_toys();
      this.exclusive_prev = plan.exclusive;
      
      let age = enum_data.getAgeRaw(this.plan.customer.detail.child_1.birth);
      let near_age_min = enum_data.ages.reduce((result, search_age) => {
        if (search_age.value <= (age - 2)) {
          result = search_age.value;
        }
        return result;
      }, null);
      this.default_age_min = near_age_min ? near_age_min : 0;
      let near_age_max = enum_data.ages.find((search_age) => {
        return (search_age.value >= age);
      }) || { value : null, };
      this.default_age_max = near_age_max.value ? near_age_max.value : 0;
      
      this.copy_property_by_customer();

      return this.api.customer.plans(this.plan.customer.id).toPromise();
    }).then((plans) => {
      plans = plans.filter(plan => plan.id != this.plan.id);
      plans.forEach(plan => plan.show = false);
      if (plans.length >= 1) {
        plans[0].show = true;
      }
      if (plans.length >= 2) {
        plans[1].show = true;
      }
      if (plans.length >= 3) {
        this.is_more_history = true;
      }
      this.history_plans = plans;
    });
  }

  copy_property_by_customer() {
    this.memo_properties = this.memo_property_rows.reduce((data, row) => {
      if (row.detail && typeof this.plan.customer.detail[row.id] == "object" && this.plan.customer.detail[row.id].length === undefined) {
        data[row.id] = {};
        for (let key of Object.keys(this.plan.customer.detail[row.id])) {
          data[row.id][key] = this.plan.customer.detail[row.id][key];
        }
      } else {
        data[row.id] = row.detail ? this.plan.customer.detail[row.id] : this.plan.customer[row.id];
      }
      if (row.type == "age_range" && !data[row.id]) {
        data[row.id] = {};
      }
      return data;
    }, {});
  }

  searchToys(search_conditions) {
    Swal.fire("読込中");
    Swal.showLoading();
    let conditions = {};
    for (let key of Object.keys(search_conditions)) {
      console.log({[key]:search_conditions[key]});
      let param_name = key.replace(".", "_");
      conditions[param_name] = search_conditions[key];
    }
    this.api.customer.toys(this.plan.customer.id, [], conditions).toPromise().then((toys) => {
      this.toys = toys;
      this.toysDataTable.reload();
      Swal.close();
    });
  }
  
  edit_start() {
    this.memo_editing = true;
  }
  
  edit_cancel() {
    this.copy_property_by_customer();
    this.memo_editing = false;
  }
  
  edit_save() {
    Swal.fire("メモ保存中");
    Swal.showLoading();
    this.api.customer.get(this.plan.customer.id).toPromise().then((customer) => {
      this.plan.customer = customer;
      this.memo_property_rows.forEach((row) => {
        if (row.detail && typeof this.memo_properties[row.id] == "object" && this.memo_properties[row.id].length === undefined) {
          this.plan.customer.detail[row.id] = {};
          for (let key of Object.keys(this.memo_properties[row.id])) {
            this.plan.customer.detail[row.id][key] = this.memo_properties[row.id][key];
          }
        } else {
          if (row.detail) {
            this.plan.customer.detail[row.id] = this.memo_properties[row.id];
          } else {
            this.plan.customer[row.id] = this.memo_properties[row.id];
          }
        }
      });
      console.log({toy:this.plan.customer});
  
      this.api.customer.update(this.plan.customer).toPromise().then((data) => {
        this.plan.customer = data;
        this.copy_property_by_customer();
        this.memo_editing = false;
        Swal.fire("メモを保存しました", "", "success")
      }).catch((error) => {
        Swal.fire("エラー", "メモの保存に失敗しました", "error")
      });
    })
  }
  
  valueChanged(row, event) {
    if (row.id == "exclusive") {
      if (this.exclusive_prev == event) {
        return;
      }
      Swal.fire("専有設定変更中");
      Swal.showLoading();
      this.api.plan.get(this.plan.id).toPromise().then((plan) => {
        console.log({"plan.exclusive":plan.exclusive, event});
        this.exclusive_prev = event;
        plan.exclusive = event;
        this.plan = plan;

        this.api.plan.update(this.plan).toPromise().then((data) => {
          Swal.fire("専有設定を変更しました", "", "success")
        }).catch((error) => {
          Swal.fire("エラー", "専有設定の変更に失敗しました", "error")
        });
      })
    }
  }
  
  getChangedValue(change, value) {
    if (!value || value == "null") {
      return "";
    }
    let field = enum_data.planChangeFieldByValue(change.field);
    if (field.id == "staff_id") {
      return (this.api.staffs.find(staff => staff.id == value) || { name : "", }).name;
    }
    if (!field.type || field.type == "text") {
      return value;
    }
    if (field.type == "select") {
      let data = enum_data[field.data_name];
      let item = data.find(item => item.id == value);
      return item ? item.name || "" : "";
    }
    if (field.type == "multiselect") {
      try {
        if (typeof value == "string") {
          value = JSON.parse(value);
        }
      } catch (e) {}
      let data = enum_data[field.data_name];
      return value.map((val) => {
        let item = data.find(item => item.id == val);
        return item ? item.name || "" : "";
      }).join("、");
    }
    if (field.type == "child") {
      let child;
      try {
        child = JSON.parse(value);
      } catch (e) {
        child = [];
      }
      if (!child || typeof child != "object") {
        return "";
      }
      let name = child.name;
      let birth = child.birth;
      let gender = child.gender;
      let birth_string = moment(birth).format("YYYY年M月D日");
      let gender_name = enum_data.genderByValue(gender).name;
      return `${name}（${gender_name}・${birth_string}生まれ）`;
    }
    if (field.type == "memos") {
      let memos;
      try {
        memos = JSON.parse(value);
      } catch (e) {
        memos = [];
      }
      if (!memos || !memos.length) {
        return "";
      }
      return memos.map((memo) => {
        let text = memo.text;
        let date = memo.date;
        let type = memo.type;
        let date_string = moment(date).format("YYYY年M月D日");
        let type_name = enum_data.memoTypeByValue(type).name;
        return `[${date_string}][${type_name}]「${text}」`;
      }).join("\n");
    }
  }
  
  refresh_plan_toys() {
    this.offer_toys = this.plan.toys.filter(toy => toy.offer);
    this.unfix_toys = this.plan.toys.filter(toy => !toy.offer && toy.state == "unfix");
    this.fix_toys = this.plan.toys.filter(toy => !toy.offer && toy.state == "fix");
    this.rejected_toys = this.plan.toys.filter(toy => !toy.offer && toy.state == "rejected");
    this.extend_toys = this.plan.toys.filter(toy => !toy.offer && toy.state == "extend");
    this.extending_toys = this.plan.toys.filter(toy => !toy.offer && toy.state == "extending");
    this.back_toys = this.plan.toys.filter(toy => !toy.offer && toy.state == "back");
    this.other_toys = this.plan.toys.filter(toy => ["unfix", "fix", "rejected", "extend", "extending", "back"].indexOf(toy.state) == -1);
  }
  
  getPriceString(status) {
    const insert_comma = (str) => { return str.length <= 3 ? str : str.substr(0, str.length - 3) + "," + str.substr(str.length - 3) };
    let price = this.plan.toys.filter(toy => status.indexOf(toy.state) != -1).reduce((total, toy) => { return total + (toy.detail.price || 0); }, 0);
    let price_rate = price * 100 / 15000;
    let price_rate_string = String(Math.floor(price_rate));
    let price_string = insert_comma(String(price));
    let less_string = price < 15000 ? ("・残り" + insert_comma(String(15000 - price)) + "円") : "";
    return `${price_string}円 / 15,000円 (${price_rate_string}%${less_string})`;
  }
  
  getSelectedPriceString() {
    if (!this.plan || !this.toys) {
      return "";
    }
    let status = ["fix", "unfix", "extend", "extending"];
    const insert_comma = (str) => { return str.length <= 3 ? str : str.substr(0, str.length - 3) + "," + str.substr(str.length - 3) };
    let price = this.plan.toys.filter(toy => status.indexOf(toy.state) != -1).reduce((total, toy) => { return total + (toy.detail.price || 0); }, 0);
    price = this.toys.filter(toy => toy.selected).reduce((total, toy) => { return total + (toy.detail.price || 0); }, price);
    let price_rate = price * 100 / 15000;
    let price_rate_string = String(Math.floor(price_rate));
    let price_string = insert_comma(String(price));
    let less_string = price < 15000 ? ("・残り" + insert_comma(String(15000 - price)) + "円") : "";
    return `${price_string}円 / 15,000円 (${price_rate_string}%${less_string})`;
  }
  
  add_more_history() {
    let plan = this.history_plans.find(plan => !plan.show);
    if (plan) {
      plan.show = true;
    }
  }

  delete_toy() {
    let delete_toys = this.plan.toys.filter(toy => toy.selected);
    Swal.fire({
      icon : "warning",
      title : "プランからおもちゃを削除する",
      text : `${delete_toys.map(toy => "「" + toy.name + "」").join("、")}をプランに削除しますか？`,
      confirmButtonText : "削除する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("プランからおもちゃを削除中");
      Swal.showLoading();
      
      let sequences = delete_toys.map((toy) => {
        return this.api.plan.delete_toy(this.plan.id, toy.id);
      });
      forkJoin(sequences).subscribe((results) => {
        this.plan.toys = this.plan.toys.filter(toy => !delete_toys.find(delete_toy => delete_toy.plan_toy_id == toy.plan_toy_id));
        this.refresh_plan_toys();
        Swal.fire("プランからおもちゃを削除しました", "", "success")
      }, (error) => {
        Swal.fire("エラー", "プランからのおもちゃの削除に失敗しました", "error")
      })
    });
  }
  
  fix_toy() {
    let fix_toys = this.plan.toys.filter(toy => toy.selected);
    Swal.fire({
      icon : "warning",
      title : "プランからおもちゃを確定する",
      text : `${fix_toys.map(toy => "「" + toy.name + "」").join("、")}を確定しますか？`,
      confirmButtonText : "確定する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("プランのおもちゃを確定中");
      Swal.showLoading();
      
      let sequences = fix_toys.map((toy) => {
        return this.api.plan.update_toy(this.plan.id, toy.plan_toy_id, { state : 'fix'});
      });
      forkJoin(sequences).subscribe((results) => {
        for (let updated_toy of results) {
          let change_toy = this.plan.toys.find(toy => toy.plan_toy_id == updated_toy.plan_toy_id);
          change_toy.state = updated_toy.state;
          change_toy.real_stock = updated_toy.real_stock;
        }
        this.refresh_plan_toys();
        Swal.fire("プランのおもちゃを確定しました", "", "success")
      }, (error) => {
        Swal.fire("エラー", "プランのおもちゃの確定に失敗しました", "error")
      })
    });
  }

  submit_plan() {
    Swal.fire({
      icon : "warning",
      title : "プランを確定する",
      text : `このプランを確定しますか？プランが確定するとユーザーにプラン内容が送られ、発送チームによる発送作業が始まります`,
      confirmButtonText : "確定する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("プランを確定中");
      Swal.showLoading();
      
      this.api.plan.submit(this.plan.id, false).toPromise().then(() => {
        Swal.fire("プランを確定しました", "", "success").then(() => {
          this.ngOnInit();
        })
      }, (error) => {
        Swal.fire("エラー", "プランの確定に失敗しました", "error")
      })
    });
  }
  
  submit_priority_plan() {
    Swal.fire({
      icon : "warning",
      title : "プランを優先で確定する",
      text : `このプランを優先確定しますか？プランが確定するとユーザーにプラン内容が送られ、優先して発送チームによる発送作業が始まります`,
      confirmButtonText : "優先で確定する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("プランを確定中");
      Swal.showLoading();
      
      this.api.plan.submit(this.plan.id, true).toPromise().then(() => {
        Swal.fire("プランを確定しました", "", "success").then(() => {
          this.ngOnInit();
        })
      }, (error) => {
        Swal.fire("エラー", "プランの確定に失敗しました", "error")
      })
    });
  }

  change_to_backed() {
    Swal.fire({
      icon : "warning",
      title : "プランを返却済みに変更",
      text : `このプランを返却済みに変更しますか？プランが返却済みになると返却確認一覧から消えますので、全てのおもちゃを返却済みか買取済みにしてからプランを返却済みにしてください`,
      confirmButtonText : "変更する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("プランを返却済みに変更中");
      Swal.showLoading();
      
      this.api.plan.back(this.plan.id).toPromise().then(() => {
        Swal.fire("プランを返却済みに変更しました", "", "success").then(() => {
          this.ngOnInit();
        })
      }, (error) => {
        Swal.fire("エラー", "プランの返却済みへの変更に失敗しました", "error")
      })
    });
  }
  
  selectToy (event, toy) {
    toy.selected = !toy.selected;
  }
  
  toyButtonsClick(event, toy) {
    let move_hash = {
      unfix : [
        "fix",
        "rejected",
      ],
      fix : [
        "unfix",
        "rejected",
      ],
      rejected : [
        "unfix",
        "DELETE",
      ],
      extending : [
        "extend",
        "back",
      ],
      extend : [
        "extending",
        "back",
      ],
      back : [
        "extending",
      ],
      rental : [
        "bought",
      ],
      partless : [
        "backed",
        "bought",
      ],
      prepare : [
        "DELETE",
      ],
    }
    let button_index = event.index;
    let state = move_hash[toy.state][button_index];
    if (state == "DELETE") {
      this.plan.toys.forEach(toy => toy.selected = false);
      toy.selected = true;
      this.delete_toy();
      return;
    }
    Swal.fire("移動中");
    Swal.showLoading();
    this.api.plan.update_toy(this.plan.id, toy.plan_toy_id, { state : state, }).subscribe((updated_toy) => {
      let change_toy = this.plan.toys.find(toy => toy.plan_toy_id == updated_toy.plan_toy_id);
      change_toy.state = updated_toy.state;
      change_toy.real_stock = updated_toy.real_stock;
      this.refresh_plan_toys();
      Swal.close();
    }, (error) => {
      Swal.fire("エラー", "おもちゃの移動に失敗しました", "error")
    })
  }
  
  addToy() {
    let add_toys = this.toys.filter(toy => toy.selected);
    let add_toy_ids = add_toys.map(toy => toy.id);

    Swal.fire({
      icon : "warning",
      title : "プランにおもちゃを追加する",
      text : `${add_toys.map(toy => "「" + toy.name + "」").join("、")}をプランに追加しますか？`,
      confirmButtonText : "追加する",
      cancelButtonText : "キャンセル",
      showCancelButton: true,
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      Swal.fire("プランにおもちゃを追加中");
      Swal.showLoading();
      this.api.plan.add_toys(this.plan.id, add_toy_ids).toPromise().then((data) => {
        this.plan.toys = data;
        this.toys.forEach(toy => toy.selected = false);
        this.refresh_plan_toys();
        Swal.fire("プランにおもちゃを追加しました", "", "success")
      }).catch((error) => {
        Swal.fire("エラー", "プランへのおもちゃの追加に失敗しました", "error")
      });
    });
  }
  
  get_is_selected_toys () {
    return this.toys.find(toy => toy.selected);
  }

}
